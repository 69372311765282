<template>
    <div class="overflow-auto h-full py-2">
        <el-checkbox
            v-model="check_all"
            class="py-2 px-3 hover:bg-bg"
            :indeterminate="is_indeterminate"
            @change="handleCheckAllChange"
        >
            <p class="text-sm">
                {{ $t('common.all') }}
            </p>
        </el-checkbox>
        <el-checkbox-group
            v-model="checked_values"
            @change="handleCheckedChange"
        >
            <el-checkbox
                v-for="item in right_children"
                :key="item.value"
                class="py-2 px-3 hover:bg-bg"
                :label="`${item.value}`"
            >
                <p class="text-sm">
                    {{ item.title }}
                </p>
            </el-checkbox>
        </el-checkbox-group>
    </div>
</template>
<script>
export default {
    props: [
        'active_filter',
        'selected_list_values',
        'filtering',
        'num_rendered'
    ],

    data() {
        return {
            check_all: false,
            checked_values: [],
            is_indeterminate: true
        }
    },

    computed: {
        right_children() {
            return this.active_filter ? this.active_filter.right_children : []
        },

        list_value() {
            return this.right_children.map((item) => `${item.value}`)
        },

        selected_list_values_inline: {
            get() {
                return this.selected_list_values
            },
            set(value) {
                this.$emit('update:selected_list_values', value)
            }
        },

        num_rendered_inline: {
            get() {
                return this.num_rendered
            },
            set(value) {
                this.$emit('update:num_rendered', value)
            }
        }
    },

    watch: {
        active_filter() {
            this.checked_values = Array.isArray(
                this.selected_list_values_inline[this.active_filter.value]
            )
                ? this.selected_list_values_inline[this.active_filter.value]
                : []
            this.handleCheckedChange(this.checked_values)
        },

        selected_list_values_inline() {
            if (!this.active_filter) {
                return
            }
            this.checked_values = Array.isArray(
                this.selected_list_values_inline[this.active_filter.value]
            )
                ? this.selected_list_values_inline[this.active_filter.value]
                : []
            this.handleCheckedChange(this.checked_values)
        },

        checked_values() {
            this.selected_list_values_inline[this.active_filter.value] =
                this.checked_values
            this.num_rendered_inline++
        }
    },

    methods: {
        handleCheckAllChange(value) {
            this.checked_values = value ? this.list_value : []
            this.is_indeterminate = false
        },

        handleCheckedChange(value) {
            const checkedCount = value.length
            this.check_all = checkedCount === this.list_value.length
            this.is_indeterminate =
                checkedCount > 0 && checkedCount < this.list_value.length
        }
    }
}
</script>
