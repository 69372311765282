<template>
    <div class="flex space-x-2 items-center">
        <el-switch
            v-model="switch_compare_inline"
            :active-text="$t('common.compare')"
        >
        </el-switch>

        <div class="relative">
            <el-date-picker
                v-model="ranger_date_inline"
                class="!w-[280px]"
                size="small"
                popper-class="custom-el-date"
                type="daterange"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :picker-options="m__picker_options"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                @change="handleChangeDateRange"
            >
            </el-date-picker>

            <div
                v-show="switch_compare_inline"
                class="absolute top-[99%] -left-2 z-[20] px-2 pb-2 pt-0 bg-white"
            >
                <el-date-picker
                    v-model="compare_ranger_date_inline"
                    class="!w-[280px] mt-2"
                    size="small"
                    popper-class="custom-el-date"
                    type="daterange"
                    unlink-panels
                    :start-placeholder="$t('date_picker.placeholder.start')"
                    :end-placeholder="$t('date_picker.placeholder.end')"
                    :picker-options="m__picker_options"
                    :format="$t('common.format_value_date_picker')"
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </div>
        </div>
    </div>
</template>

<script>
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import moment from 'moment'

export default {
    mixins: [rangerDateMixin],

    props: ['ranger_date', 'compare_ranger_date', 'switch_compare'],

    computed: {
        m__selected_tiktok_business() {
            return this.$store.getters.tiktokBusiness
                .s__selected_tiktok_business
        },

        ranger_date_inline: {
            get: function () {
                return this.ranger_date
            },
            set: function (val) {
                this.$emit('update:ranger_date', val)
            }
        },

        compare_ranger_date_inline: {
            get: function () {
                return this.compare_ranger_date
            },
            set: function (val) {
                this.$emit('update:compare_ranger_date', val)
            }
        },

        switch_compare_inline: {
            get: function () {
                return this.switch_compare
            },
            set: function (val) {
                this.$emit('update:switch_compare', val)
            }
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.getFirstDate()
        }
    },

    mounted() {
        this.getFirstDate()
    },

    methods: {
        getFirstDate() {
            if (!this.m__selected_tiktok_business) {
                return
            }
            // const end_date = moment().format('YYYY-MM-DD')
            // const start_date = moment(
            //     this.m__selected_tiktok_business.created_at
            // ).format('YYYY-MM-DD')

            // const arr = [start_date, end_date]
            // this.m__picker_options.shortcuts = [
            //     {
            //         text: this.$t('common.lifetime'),
            //         onClick(picker) {
            //             picker.$emit('pick', arr)
            //         }
            //     },
            //     ...this.m__picker_options.shortcuts
            // ]
            this.m__picker_options.shortcuts = [
                ...this.m__picker_options.shortcuts
            ]
        },

        handleChangeDateRange(value) {
            this.$router.push({
                path: this.$route.fullPath,
                query: {
                    st: value[0],
                    et: value[1]
                }
            })
        }
    }
}
</script>
