<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="300px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
        :show-close="true"
        :destroy-on-close="true"
    >
        <div
            v-loading="m__loading"
            class="w-[300px] h-[500px] mx-[-20px] mb-[-16px] mt-[-46px] bg-black"
        >
            <iframe
                v-if="preview_link"
                class="h-full"
                :src="preview_link"
            ></iframe>
            <div
                v-else-if="!m__loading"
                class="h-full util-flex-center flex-col text-white"
            >
                <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    class="text-5xl"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M11.296 11.29a1 1 0 1 0 1.414 1.415"></path>
                    <path
                        d="M8.473 8.456a5 5 0 1 0 7.076 7.066m1.365 -2.591a5 5 0 0 0 -5.807 -5.851"
                    ></path>
                    <path d="M15.9 20.11v.01"></path>
                    <path d="M19.04 17.61v.01"></path>
                    <path d="M20.77 14v.01"></path>
                    <path d="M20.77 10v.01"></path>
                    <path d="M19.04 6.39v.01"></path>
                    <path d="M15.9 3.89v.01"></path>
                    <path d="M12 3v.01"></path>
                    <path d="M8.1 3.89v.01"></path>
                    <path d="M4.96 6.39v.01"></path>
                    <path d="M3.23 10v.01"></path>
                    <path d="M3.23 14v.01"></path>
                    <path d="M4.96 17.61v.01"></path>
                    <path d="M8.1 20.11v.01"></path>
                    <path d="M12 21v.01"></path>
                    <path d="M3 3l18 18"></path>
                </svg>

                <p class="mt-2 font-semibold">
                    {{ $t('common.ad_cannot_previewed_yet') }}
                </p>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { getPreviewAd } from '@/services/atosa-tiktok-ads/creative-tool'

export default {
    props: ['visible', 'advertiser_id', 'ad_id'],

    data() {
        return {
            preview_link: ''
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        ad_id() {
            this.handleGetPreviewAd()
        },

        visible_in_line() {}
    },

    methods: {
        async handleGetPreviewAd() {
            this.preview_link = ''

            this.m__loading = true
            try {
                const response = await getPreviewAd({
                    advertiser_id: this.advertiser_id,
                    ad_id: this.ad_id,
                    preview_type: 'AD'
                })
                if (response.code === 0 && response.data.preview_link) {
                    this.preview_link = response.data.preview_link
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
