<template>
    <div v-if="selected_item_in_line">
        <div>
            <el-button
                v-if="template_name_list.length === 0"
                icon="el-icon-postcard"
                @click="show_create_dialog = true"
                size="small"
            >
                {{ $t('common.name_template') }}
            </el-button>

            <el-dropdown v-else trigger="click">
                <el-button icon="el-icon-postcard" size="small">
                    {{ $t('common.name_template') }}
                </el-button>
                <el-dropdown-menu
                    slot="dropdown"
                    class="overflow-auto max-h-[280px]"
                >
                    <el-dropdown-item
                        v-for="item in template_name_list"
                        :key="item._id"
                        class="relative"
                    >
                        <div @click="handleSelected(item)" class="py-2 pr-8">
                            <p class="leading-5 text-sm">
                                {{ item.config_name }}
                            </p>
                            <p
                                class="text-desc-text truncate max-w-[200px] leading-5 text-xs"
                            >
                                {{
                                    genDescriptionTemplateName(
                                        item.fields,
                                        item.field_separator
                                    )
                                }}
                            </p>
                        </div>

                        <div
                            class="absolute top-1/2 -translate-y-1/2 right-2 flex space-x-1"
                        >
                            <i
                                class="el-icon-edit-outline text-base"
                                @click="handleEdit(item)"
                            ></i>
                            <i
                                class="el-icon-delete text-base"
                                @click="handleRemove(item._id)"
                            ></i>
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item divided
                        ><div @click="show_create_dialog = true">
                            <i class="el-icon-plus"></i>
                            {{ $t('button.create') }}
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <CreateTemplateNameDialog
            :visible.sync="show_create_dialog"
            :field_options="field_options"
            :genDescriptionTemplateName="genDescriptionTemplateName"
            :getTemplateNames="getTemplateNames"
        ></CreateTemplateNameDialog>

        <EditTemplateNameDialog
            :visible.sync="show_edit_dialog"
            :field_options="field_options"
            :selected_item="selected_edit_item"
            :getTemplateNames="getTemplateNames"
            :genDescriptionTemplateName="genDescriptionTemplateName"
        ></EditTemplateNameDialog>
    </div>
</template>

<script>
import CreateTemplateNameDialog from './create-template-name-dialog'
import EditTemplateNameDialog from './edit-template-name-dialog'
import { deleteTemplateName } from '@/services/atosa-tiktok-ads/tool'
import moment from 'moment'

export default {
    components: {
        CreateTemplateNameDialog,
        EditTemplateNameDialog
    },

    props: [
        'selected_item',
        'objective_type',
        'ads_account_name',
        'template_name_list',
        'getTemplateNames',
        'tiktok_business_user_name'
    ],

    data() {
        return {
            selected_edit_item: null,
            show_create_dialog: false,
            show_edit_dialog: false,
            selected_template_name: null,
            field_options: [
                {
                    label: this.$t('common.objective_type'),
                    key: 'objective_type'
                },

                {
                    label: this.$t('common.advertiser_account'),
                    key: 'advertiser_account'
                },
                {
                    label: this.$t('common.tiktok_business'),
                    key: 'tiktok_business'
                },
                {
                    label: this.$t('common.budget'),
                    key: 'budget'
                },
                {
                    label: this.$t('common.time'),
                    key: 'time'
                },
                {
                    label: this.$t('common.text'),
                    key: 'text'
                }
            ]
        }
    },

    computed: {
        selected_item_in_line: {
            get: function () {
                return this.selected_item
            },
            set: function (val) {
                this.$emit('update:selected_item', val)
            }
        }
    },

    methods: {
        handleEdit(item) {
            this.selected_edit_item = item

            this.show_edit_dialog = true
        },

        async handleRemove(id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            try {
                const response = await deleteTemplateName(id)
                if (response.code === 0) {
                    this.getTemplateNames()
                }
            } catch (error) {
                console.error(error)
            }
        },

        genDescriptionTemplateName(fields, field_separator) {
            const temp = fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    return temp.label
                }

                return item.key
            })
            return temp.join(` ${field_separator} `)
        },

        handleSelected(item) {
            const new_name = this.genPreviewName(
                item.fields,
                item.field_separator
            )
            this.selected_item_in_line.new_name = new_name
        },

        genPreviewName(fields, field_separator) {
            const temp = fields.map((item) => {
                const { key, value } = item

                if (key === 'time') {
                    const date = moment().format(value)
                    return date
                }

                if (key === 'objective_type') {
                    return this.objective_type
                }

                if (key === 'budget') {
                    return this.p__formatBudgetMode(
                        this.selected_item_in_line.budget_mode
                    )
                }

                if (key === 'advertiser_account') {
                    return this.ads_account_name
                }

                if (key === 'tiktok_business') {
                    return this.tiktok_business_user_name
                }

                if (key === 'text') {
                    return value
                }

                return item.key
            })

            return temp.join(`${field_separator}`)
        }
    }
}
</script>
