<template>
    <div>
        <div
            class="pr-3 py-2 cursor-pointer hover:bg-bg flex items-center justify-between"
            :style="nodeMargin"
            :class="{
                'bg-bg': active_filter && node.value === active_filter.value
            }"
            @click="handleChooseActiceMenu(node)"
        >
            <p class="text-xs">
                {{ node.title }}
            </p>
            <i
                v-if="hasChildren"
                :class="
                    show_children ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                "
            ></i>
            <p
                v-else-if="active_length > 0"
                class="text-xs font-semibold"
            >
                {{ active_length }}
            </p>
        </div>
        <div v-if="hasChildren" v-show="show_children">
            <LeftMenuNode
                v-for="child in node.children"
                :key="child.id"
                :node="child"
                :selected_list_values="selected_list_values"
                :visible="visible"
                :num_rendered="num_rendered"
                :active_filter.sync="active_filter_inline"
                :level="level + 1"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeftMenuNode',

    props: [
        'node',
        'level',
        'active_filter',
        'selected_list_values',
        'num_rendered',
        'visible'
    ],

    data() {
        return {
            active_length: 0,
            show_children: true
        }
    },

    computed: {
        nodeMargin() {
            return {
                'padding-left': `${this.level * 12}px`
            }
        },

        hasChildren() {
            const { children } = this.node
            return children && children.length > 0
        },

        toggleChildrenIcon() {
            return this.show_children
                ? 'fas fa-angle-down'
                : 'fas fa-angle-right'
        },

        active_filter_inline: {
            get() {
                return this.active_filter
            },
            set(value) {
                this.$emit('update:active_filter', value)
            }
        }
    },

    watch: {
        visible() {
            this.setActiveLength()
        },

        num_rendered() {
            this.setActiveLength()
        }
    },

    methods: {
        handleChooseActiceMenu(node) {
            if (this.hasChildren) {
                this.show_children = !this.show_children
            } else {
                this.active_filter_inline = node
            }
        },

        setActiveLength() {
            this.active_length =
                this.selected_list_values[this.node.value]?.length || 0
        }
    }
}
</script>
