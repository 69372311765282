<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="920px"
        :wrapper_closable="true"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('common.batch_name_adjustment') }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="
                        m__loading || !adgroup_to_edit_budget_list.length
                    "
                    :loading="m__loading"
                    @click="handleConfirm()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-4">
            <el-table
                ref="multipleTable"
                class="new-campaign-table"
                max-height="560"
                :data="adgroup_to_edit_budget_list"
            >
                <el-table-column
                    prop="adgroup.adgroup_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div class="flex space-x-4 items-center">
                            <div
                                v-if="
                                    scope.row.ad.image_ids.length &&
                                    scope.row.ad.image_ids[0]
                                "
                                class="w-full h-full relative"
                            >
                                <img
                                    v-if="scope.row.ad.video_id"
                                    :src="`https://p21-ad-sg.ibyteimg.com/medium/${scope.row.ad.image_ids[0]}`"
                                    class="w-full h-full object-cover cursor-pointer"
                                    @click="
                                        handleChooseVideo(scope.row.ad.video_id)
                                    "
                                />
                                <img
                                    v-else
                                    :src="`https://p21-ad-sg.ibyteimg.com/medium/${scope.row.ad.image_ids[0]}`"
                                    class="w-full h-full object-cover"
                                />

                                <div
                                    v-if="scope.row.ad.video_id"
                                    class="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 absolute cursor-pointer"
                                    @click="
                                        handleChooseVideo(scope.row.ad.video_id)
                                    "
                                >
                                    <svg
                                        stroke="currentColor"
                                        fill="currentColor"
                                        stroke-width="0"
                                        viewBox="0 0 512 512"
                                        height="1em"
                                        width="1em"
                                        class="text-2xl text-bg"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                                        ></path>
                                    </svg>
                                </div>
                            </div>

                            <div class="group hover:text-brand-atosa--light">
                                <p class="text-desc-text text-xs">
                                    #{{ scope.row.id }}
                                </p>

                                <div class="group flex items-center">
                                    <div class="cursor-pointer">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="scope.row.ad.ad_name"
                                            placement="top"
                                        >
                                            <p class="truncate max-w-[320px]">
                                                {{ scope.row.ad.ad_name }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    prop="ad.create_time"
                    :label="$t('common.created_at')"
                >
                </el-table-column>

                <el-table-column
                    prop="new_name"
                    :label="$t('common.new_name')"
                    width="280px"
                >
                    <template #default="scope">
                        <div>
                            <el-input v-model="scope.row.new_name" size="small">
                            </el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <InfoVideoDialog
            :visible.sync="show_info_video_dialog"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :video_id="select_video_id"
        ></InfoVideoDialog>
    </custom-drawer>
</template>

<script>
import { updateAd } from '@/services/atosa-tiktok-ads/ad'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'

export default {
    props: [
        'visible',
        'multiple_selection',
        'advertiser_id',
        'belong_to_atosa',
        'tiktok_account_id'
    ],

    data() {
        return {
            select_video_id: '',
            show_info_video_dialog: false,
            adgroup_to_edit_budget_list: []
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.adgroup_to_edit_budget_list = this.multiple_selection.map(
                    (item) => ({
                        ...item,
                        new_name: item.ad.ad_name
                    })
                )
            }
        }
    },

    methods: {
        handleChooseVideo(video_id) {
            this.select_video_id = video_id
            this.show_info_video_dialog = true
        },

        async handleConfirm() {
            if (this.adgroup_to_edit_budget_list.length === 0) {
                return
            }

            this.m__loading = true
            try {
                await Promise.all(
                    this.adgroup_to_edit_budget_list.map(async (item) => {
                        const creative = {
                            ad_id: item.ad.ad_id,
                            ad_name: item.new_name,
                            belong_to_atosa: this.belong_to_atosa
                        }

                        removeEmptyParams(creative)

                        await updateAd(this.tiktok_account_id, {
                            advertiser_id: this.advertiser_id,
                            belong_to_atosa: this.belong_to_atosa,
                            adgroup_id: item.ad.adgroup_id,
                            patch_update: true,
                            creatives: [creative]
                        })
                    })
                )
                this.m__loading = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.p__event_bus.$emit('eb_reload_ad')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
                this.m__loading = false
            }
        }
    }
}
</script>
