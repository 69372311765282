<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="1100px"
        :wrapper_closable="true"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('common.batch_name_adjustment') }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="m__loading || !item_to_edit_name_list.length"
                    :loading="m__loading"
                    @click="handleConfirm()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-4">
            <el-table
                ref="multipleTable"
                class="new-campaign-table"
                max-height="560"
                :data="item_to_edit_name_list"
            >
                <el-table-column
                    prop="adgroup.adgroup_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div class="group hover:text-brand-atosa--light">
                            <p class="text-desc-text text-xs">
                                #{{ scope.row.id }}
                            </p>

                            <div class="group flex items-center">
                                <div class="cursor-pointer">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.adgroup.adgroup_name
                                        "
                                        placement="top"
                                    >
                                        <p class="truncate max-w-[320px]">
                                            {{ scope.row.adgroup.adgroup_name }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.ad_scheduling')"
                    width="160px"
                >
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p>
                                {{
                                    p__formatDate(
                                        scope.row.adgroup.schedule_start_time
                                    )
                                }}
                            </p>
                            <p
                                v-if="
                                    scope.row.adgroup.schedule_type ===
                                    'SCHEDULE_START_END'
                                "
                            >
                                {{
                                    p__formatDate(
                                        scope.row.adgroup.schedule_end_time
                                    )
                                }}
                            </p>
                            <p v-else>
                                {{ $t('page.campaign.on_going') }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="BID" width="100px">
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p>
                                {{
                                    renderBid(
                                        scope.row.adgroup,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p class="text-desc-text">
                                {{
                                    p__formatLabelOptimizationGoal(
                                        scope.row.adgroup
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    prop="adgroup.create_time"
                    :label="$t('common.created_at')"
                >
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    :label="$t('common.name_template')"
                >
                    <template #default="scope">
                        <NameRow
                            :selected_item.sync="scope.row.adgroup"
                            :template_name_list="template_name_list"
                            :getTemplateNames="getTemplateNames"
                            :genPreviewName="genPreviewName"
                        ></NameRow>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="new_name"
                    :label="$t('common.new_name')"
                    width="280px"
                >
                    <template #default="scope">
                        <div>
                            <el-input
                                v-model="scope.row.adgroup.new_name"
                                size="small"
                            >
                            </el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="component-box mt-4">
                <div class="flex items-center mb-4">
                    <p class="font-semibold text-sm">
                        {{
                            $t(
                                'page.campaign.adjust_batch_names_according_to_name_template'
                            )
                        }}
                    </p>

                    <el-button
                        size="small"
                        icon="el-icon-plus"
                        class="ml-auto"
                        @click="show_create_dialog = true"
                        >{{ $t('button.create') }}</el-button
                    >
                </div>

                <el-table
                    ref="multipleTable"
                    class="new-campaign-table"
                    max-height="560"
                    :data="template_name_list"
                >
                    <el-table-column :label="$t('common.name')" :fixed="true">
                        <template #default="scope">
                            <p>
                                {{ scope.row.config_name }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.preview')">
                        <template #default="scope">
                            <p>
                                {{
                                    genDescriptionTemplateName(
                                        scope.row.fields,
                                        scope.row.field_separator
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.option')" width="160">
                        <template #default="scope">
                            <el-button
                                size="small"
                                @click="handleChooseItem(scope.row)"
                            >
                                {{ $t('button.choose') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <CreateTemplateNameDialog
            :visible.sync="show_create_dialog"
            :field_options="field_options"
            :genDescriptionTemplateName="genDescriptionTemplateName"
            :getTemplateNames="getTemplateNames"
        ></CreateTemplateNameDialog>
    </custom-drawer>
</template>

<script>
import { updateAdgroup } from '@/services/atosa-tiktok-ads/adgroup'
import { getTemplateNames } from '@/services/atosa-tiktok-ads/tool'
import CreateTemplateNameDialog from './name-row/create-template-name-dialog'
import NameRow from './name-row'
import moment from 'moment'

export default {
    components: {
        NameRow,
        CreateTemplateNameDialog
    },

    props: [
        'visible',
        'multiple_selection',
        'atosa_bc_list',
        'advertiser_id',
        'belong_to_atosa',
        'tiktok_account_id'
    ],

    data() {
        return {
            show_create_dialog: false,
            template_name_list: [],
            item_to_edit_name_list: [],
            field_options: [
                {
                    label: this.$t('common.location'),
                    key: 'location_ids'
                },
                {
                    label: this.$t('common.language'),
                    key: 'languages'
                },
                {
                    label: this.$t('common.gender'),
                    key: 'gender'
                },
                {
                    label: this.$t('common.operating_system'),
                    key: 'operating_systems'
                },
                {
                    label: this.$t('page.campaign.optimization_goal'),
                    key: 'optimization_goal'
                },
                // {
                //     label: this.$t('page.campaign.bid_strategy'),
                //     key: 'bid_strategy'
                // },
                {
                    label: this.$t('common.start_time'),
                    key: 'schedule_start_time'
                },
                {
                    label: this.$t('common.end_time'),
                    key: 'schedule_end_time'
                },
                {
                    label: this.$t('common.age'),
                    key: 'age_groups'
                },
                {
                    label: this.$t('common.time'),
                    key: 'time'
                },
                {
                    label: this.$t('common.text'),
                    key: 'text'
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.item_to_edit_name_list = this.multiple_selection.map(
                    (item) => ({
                        ...item,
                        adgroup: {
                            ...item.adgroup,
                            new_name: item.adgroup.adgroup_name
                        }
                    })
                )
            }
        }
    },

    mounted() {
        this.getTemplateNames()
    },

    methods: {
        renderBid(adgroup, currency) {
            if (
                adgroup.optimization_event === 'SHOPPING' &&
                adgroup.optimization_goal === 'VALUE' &&
                adgroup?.deep_bid_type === 'VO_MIN_ROAS'
            ) {
                return adgroup.roas_bid
            }

            if (adgroup.optimization_goal === 'CLICK') {
                return this.p__renderMoneyByCurrency(
                    adgroup.bid_price,
                    currency
                )
            }

            return '-'
        },

        genPreviewName(fields, field_separator, adgroup) {
            const temp = fields.map((item) => {
                const { key, value } = item
                if (key === 'age_groups') {
                    if (
                        adgroup.age_groups.length === 1 &&
                        adgroup.age_groups[0] === 'ALL'
                    ) {
                        return this.$t('common.all')
                    }
                    return adgroup.age_groups
                        .map((item) => {
                            const temp = this.p__age_options.find(
                                (it) => it.value === item
                            )

                            if (temp) {
                                return temp.label
                            }

                            return item
                        })
                        .join('&')
                }

                if (key === 'gender') {
                    const temp = this.p__gender_options.find(
                        (it) => it.value === adgroup.gender
                    )

                    if (temp) {
                        return temp.label
                    }

                    return adgroup.gender
                }

                if (key === 'schedule_start_time') {
                    return adgroup.schedule_start_time
                }

                if (key === 'schedule_end_time') {
                    return adgroup.schedule_end_time
                }

                if (key === 'operating_systems') {
                    if (adgroup.operating_systems.length === 0) {
                        return this.$t('common.all')
                    }
                    return adgroup.operating_systems[0]
                }

                if (key === 'languages') {
                    if (adgroup.languages.length === 0) {
                        return this.$t('common.all')
                    }

                    return adgroup.languages.join(' & ')
                }

                if (key === 'location_ids') {
                    return adgroup.location_ids.join('&')
                }

                if (key === 'text') {
                    return value
                }

                if (key === 'optimization_goal') {
                    return this.p__formatLabelOptimizationGoal(adgroup)
                }

                if (key === 'time') {
                    const date = moment().format(value)
                    return date
                }

                return item.key
            })

            return temp.join(`${field_separator}`)
        },

        async handleConfirm() {
            if (this.item_to_edit_name_list.length === 0) {
                return
            }

            this.m__loading = true
            try {
                await Promise.all(
                    this.item_to_edit_name_list.map(async (item) => {
                        await updateAdgroup(this.tiktok_account_id, {
                            advertiser_id: this.advertiser_id,
                            adgroup_name: item.new_name,
                            adgroup_id: item.adgroup.adgroup_id,
                            store_authorized_bc_id: item.adgroup.store_authorized_bc_id,
                            belong_to_atosa: this.belong_to_atosa
                        })
                    })
                )
                this.m__loading = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.p__event_bus.$emit('eb_reload_adgroup')

                this.visible_in_line = false
            } catch (error) {
                this.m__loading = false

                console.error(error)
            }
        },

        handleChooseItem(template) {
            this.item_to_edit_name_list = this.item_to_edit_name_list.map(
                (item) => {
                    const new_name = this.genPreviewName(
                        template.fields,
                        template.field_separator,
                        item.adgroup
                    )

                    return {
                        ...item,
                        adgroup: {
                            ...item.adgroup,
                            new_name
                        }
                    }
                }
            )
        },

        genDescriptionTemplateName(fields, field_separator) {
            const temp = fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    return temp.label
                }

                return item.key
            })
            return temp.join(` ${field_separator} `)
        },

        async getTemplateNames() {
            try {
                const response = await getTemplateNames()
                if (response.code === 0) {
                    this.template_name_list = response.data.filter(
                        (item) => item.type === 'adgroup'
                    )
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
