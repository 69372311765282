export const removeEmptyParams = (item) => {
    Object.keys(item).forEach((k) => {
        if (item[k] == null) {
            delete item[k]
        }

        if (item[k] === '') {
            delete item[k]
        }

        if (Array.isArray(item[k]) && !item[k].length) {
            delete item[k]
        }

        if (Array.isArray(item[k]) && item[k][0] === 'ALL') {
            delete item[k]
        }

        if (Array.isArray(item[k]) && item[k][0] === '') {
            delete item[k]
        }
    })
}
