<template>
    <div>
        <el-select v-model="select_metric" class="w-[200px] mb-4" size="small">
            <el-option-group
                v-for="(group, index) in p__view_data_metric_options"
                :key="index"
                :label="group.label"
            >
                <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-option-group>
        </el-select>
        <el-tabs v-model="active_tab" @tab-click="handleChangeTab">
            <el-tab-pane :label="$t('common.age_and_gender')" name="age_gender">
                <el-radio-group
                    v-model="radio_group_age_gender"
                    size="small"
                    @change="handleChangeGroupAgeGender"
                >
                    <el-radio-button label="age">
                        {{ $t('common.age') }}
                    </el-radio-button>
                    <el-radio-button label="gender">
                        {{ $t('common.gender') }}
                    </el-radio-button>
                    <el-radio-button label="age_gender">{{
                        $t('common.age_and_gender')
                    }}</el-radio-button>
                </el-radio-group>

                <div v-loading="m__loading" class="mt-4 relative min-h-[280px]">
                    <Chart
                        v-if="age_gender_chart_options"
                        v-show="radio_group_age_gender === 'age_gender'"
                        :options="age_gender_chart_options"
                    ></Chart>
                    <Chart
                        v-if="default_chart_options"
                        v-show="
                            ['age', 'gender'].some(
                                (item) => radio_group_age_gender === item
                            )
                        "
                        :options="default_chart_options"
                    ></Chart>

                    <div
                        v-if="show_no_data"
                        class="w-full h-full bg-white absolute top-0 left-0"
                    >
                        <div
                            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        >
                            <div class="util-flex-center flex-col">
                                <img
                                    class="w-[140px] h-[140px]"
                                    :src="
                                        require('@/assets/images/no_data.png')
                                    "
                                />
                                <p class="mt-2 text-desc-text text-sm">
                                    {{ $t('common.no_data') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane
                :label="$t('page.campaign.interests_and_behaviours')"
                name="interest_category"
            >
                <div class="flex justify-between items-center mb-2">
                    <p class="text-sm font-semibold">
                        {{ $t('page.campaign.interests') }}
                    </p>

                    <el-select
                        v-model="select_interest_category_level"
                        class="w-[200px]"
                        size="small"
                        @change="
                            fetchOverviewReport(select_interest_category_level)
                        "
                    >
                        <el-option
                            v-for="item in interest_category_level_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>

                <el-table
                    class="campaign-table"
                    height="320"
                    :data="detail_interest_category_data"
                >
                    <el-table-column
                        :label="$t('common.rank')"
                        type="index"
                        width="100"
                    />

                    <el-table-column
                        :show-overflow-tooltip="true"
                        prop="interest_category"
                        :label="$t('page.campaign.interests')"
                    >
                    </el-table-column>

                    <el-table-column
                        :show-overflow-tooltip="true"
                        :prop="select_metric"
                        :label="$t(`page.campaign.column_${select_metric}`)"
                        align="right"
                    >
                        <template v-slot:header>
                            <el-tooltip
                                class="item"
                                :content="
                                    $t(`page.campaign.column_${select_metric}`)
                                "
                                effect="dark"
                                placement="top"
                            >
                                <p>
                                    {{
                                        $t(
                                            `page.campaign.column_${select_metric}`
                                        )
                                    }}
                                </p>
                            </el-tooltip>
                        </template>

                        <template #default="scope">
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row[select_metric],
                                        select_metric,
                                        currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane :label="$t('common.location')" name="country_code">
                <div v-if="select_metric" class="flex space-x-8">
                    <div class="flex-1">
                        <p class="text-sm font-semibold">
                            {{ $t('common.country') }}
                        </p>
                        <el-table
                            class="campaign-table"
                            height="320"
                            :data="detail_country_data"
                        >
                            <el-table-column
                                :label="$t('common.rank')"
                                type="index"
                                width="100"
                            />

                            <el-table-column
                                width="160"
                                :show-overflow-tooltip="true"
                                prop="country_code"
                                :label="$t('common.country')"
                            >
                            </el-table-column>

                            <el-table-column
                                :show-overflow-tooltip="true"
                                :prop="select_metric"
                                :label="
                                    $t(`page.campaign.column_${select_metric}`)
                                "
                                align="right"
                            >
                                <template v-slot:header>
                                    <el-tooltip
                                        class="item"
                                        :content="
                                            $t(
                                                `page.campaign.column_${select_metric}`
                                            )
                                        "
                                        effect="dark"
                                        placement="top"
                                    >
                                        <p>
                                            {{
                                                $t(
                                                    `page.campaign.column_${select_metric}`
                                                )
                                            }}
                                        </p>
                                    </el-tooltip>
                                </template>

                                <template #default="scope">
                                    <p>
                                        {{
                                            m__formatterColumn(
                                                scope.row[select_metric],
                                                select_metric,
                                                currency
                                            )
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex-1">
                        <p class="text-sm font-semibold">
                            {{ $t('common.province') }}
                        </p>
                        <el-table
                            class="campaign-table"
                            height="320"
                            :data="detail_province_data"
                        >
                            <el-table-column
                                :label="$t('common.rank')"
                                type="index"
                                width="100"
                            />

                            <el-table-column
                                width="160"
                                :show-overflow-tooltip="true"
                                prop="province_id"
                                :label="$t('common.province')"
                            >
                            </el-table-column>

                            <el-table-column
                                :show-overflow-tooltip="true"
                                :prop="select_metric"
                                :label="
                                    $t(`page.campaign.column_${select_metric}`)
                                "
                                align="right"
                            >
                                <template v-slot:header>
                                    <el-tooltip
                                        class="item"
                                        :content="
                                            $t(
                                                `page.campaign.column_${select_metric}`
                                            )
                                        "
                                        effect="dark"
                                        placement="top"
                                    >
                                        <p>
                                            {{
                                                $t(
                                                    `page.campaign.column_${select_metric}`
                                                )
                                            }}
                                        </p>
                                    </el-tooltip>
                                </template>

                                <template #default="scope">
                                    <p>
                                        {{
                                            m__formatterColumn(
                                                scope.row[select_metric],
                                                select_metric,
                                                currency
                                            )
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('common.device')" name="ac">
                <div v-loading="m__loading" class="relative min-h-[280px]">
                    <Chart
                        v-if="default_chart_options"
                        :options="default_chart_options"
                    ></Chart>

                    <div
                        v-if="show_no_data"
                        class="w-full h-full bg-white absolute top-0 left-0"
                    >
                        <div
                            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        >
                            <div class="util-flex-center flex-col">
                                <img
                                    class="w-[140px] h-[140px]"
                                    :src="
                                        require('@/assets/images/no_data.png')
                                    "
                                />
                                <p class="mt-2 text-desc-text text-sm">
                                    {{ $t('common.no_data') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="select_metric" class="mt-4 flex space-x-8">
                    <div class="flex-1">
                        <p class="text-sm font-semibold">
                            {{ $t('common.operating_system') }}
                        </p>
                        <el-table
                            class="campaign-table"
                            height="320"
                            :data="detail_platform_data"
                        >
                            <el-table-column
                                :label="$t('common.rank')"
                                type="index"
                                width="100"
                            />

                            <el-table-column
                                width="160"
                                :show-overflow-tooltip="true"
                                prop="platform"
                                :label="$t('common.operating_system')"
                            >
                            </el-table-column>

                            <el-table-column
                                :show-overflow-tooltip="true"
                                :prop="select_metric"
                                :label="
                                    $t(`page.campaign.column_${select_metric}`)
                                "
                                align="right"
                            >
                                <template v-slot:header>
                                    <el-tooltip
                                        class="item"
                                        :content="
                                            $t(
                                                `page.campaign.column_${select_metric}`
                                            )
                                        "
                                        effect="dark"
                                        placement="top"
                                    >
                                        <p>
                                            {{
                                                $t(
                                                    `page.campaign.column_${select_metric}`
                                                )
                                            }}
                                        </p>
                                    </el-tooltip>
                                </template>

                                <template #default="scope">
                                    <p>
                                        {{
                                            m__formatterColumn(
                                                scope.row[select_metric],
                                                select_metric,
                                                currency
                                            )
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex-1">
                        <p class="text-sm font-semibold">
                            {{ $t('common.device') }}
                        </p>
                        <el-table
                            class="campaign-table"
                            height="320"
                            :data="detail_device_data"
                        >
                            <el-table-column
                                :label="$t('common.rank')"
                                type="index"
                                width="100"
                            />

                            <el-table-column
                                width="160"
                                :show-overflow-tooltip="true"
                                prop="device_brand_id"
                                :label="$t('common.device')"
                            >
                            </el-table-column>

                            <el-table-column
                                :show-overflow-tooltip="true"
                                :prop="select_metric"
                                :label="
                                    $t(`page.campaign.column_${select_metric}`)
                                "
                                align="right"
                            >
                                <template v-slot:header>
                                    <el-tooltip
                                        class="item"
                                        :content="
                                            $t(
                                                `page.campaign.column_${select_metric}`
                                            )
                                        "
                                        effect="dark"
                                        placement="top"
                                    >
                                        <p>
                                            {{
                                                $t(
                                                    `page.campaign.column_${select_metric}`
                                                )
                                            }}
                                        </p>
                                    </el-tooltip>
                                </template>

                                <template #default="scope">
                                    <p>
                                        {{
                                            m__formatterColumn(
                                                scope.row[select_metric],
                                                select_metric,
                                                currency
                                            )
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import { getReportFromTTA } from '@/services/atosa-tiktok-ads/reporting'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import { formatAge } from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_utils'

export default {
    components: {
        Chart
    },

    mixins: [campaignMixin],

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'ranger_date',
        'currency',
        'objective_type',
        'type',
        'data_id',
        'region_options',
        'device_model_options',
        'interest_category_options'
    ],

    data() {
        return {
            default_chart_options: null,
            age_gender_chart_options: null,
            radio_group_age_gender: 'age',
            select_metric: this.p__trend_chart_options_2[0].value,
            active_tab: 'age_gender',
            detail_device_data: [],
            detail_platform_data: [],
            detail_country_data: [],
            detail_province_data: [],
            detail_interest_category_data: [],
            data_report_chart: [],
            select_interest_category_level: 'interest_category',
            interest_category_level_options: [
                {
                    label: this.$t('common.level_xxx', {
                        xxx: 1
                    }),
                    value: 'interest_category'
                },
                {
                    label: this.$t('common.level_xxx', {
                        xxx: 2
                    }),
                    value: 'interest_category_tier2'
                },
                {
                    label: this.$t('common.level_xxx', {
                        xxx: 3
                    }),
                    value: 'interest_category_tier3'
                },
                {
                    label: this.$t('common.level_xxx', {
                        xxx: 3
                    }),
                    value: 'interest_category_tier4'
                }
            ]
        }
    },

    computed: {
        metrics() {
            return this.p__trend_chart_options_for_audience.map(
                (item) => item.value
            )
        },

        show_no_data() {
            if (!this.data_report_chart.length) {
                return true
            }
            const condition = this.data_report_chart.every(
                (item) => +item[this.select_metric] === 0
            )

            if (condition) {
                return true
            }

            return false
        },

        s__language() {
            return this.$store.getters.app.s__language
        }
    },

    watch: {
        ranger_date() {
            this.handleChangeTab()
        },

        data_id() {
            this.active_tab = 'age_gender'
            this.radio_group_age_gender = 'age'

            this.handleChangeTab()
        },

        select_metric() {
            this.handleChangeTab()
        },

        device_model_options() {
            this.formatNameDevice(this.detail_device_data)
        },

        interest_category_options() {
            this.formatInterestCategory(this.detail_interest_category_data)
        },

        region_options() {
            this.formatNameProvince(this.detail_province_data)
        }
    },

    mounted() {
        this.fetchOverviewReport(this.radio_group_age_gender)
    },

    methods: {
        formatNameDevice(data) {
            if (this.device_model_options.length) {
                this.detail_device_data = data.map((item) => {
                    const temp_item = this.device_model_options.find(
                        (it) => it.device_model_id === item['device_brand_id']
                    )
                    if (temp_item) {
                        return {
                            ...item,
                            device_brand_id: temp_item.device_model_name
                        }
                    }
                    return item
                })
            } else {
                this.detail_device_data = data
            }
        },

        formatInterestCategory(data) {
            if (this.interest_category_options.length) {
                this.detail_interest_category_data = data.map((item) => {
                    const temp_item = this.interest_category_options.find(
                        (it) =>
                            it.interest_category_id ===
                            item[
                                this.select_interest_category_level ===
                                'interest_category'
                                    ? 'interest_category_v2'
                                    : this.select_interest_category_level
                            ]
                    )
                    if (temp_item) {
                        return {
                            ...item,
                            interest_category: temp_item.interest_category_name
                        }
                    }
                    return item
                })
            } else {
                this.detail_interest_category_data = data
            }
        },

        formatNameProvince(data) {
            if (this.region_options.length) {
                this.detail_province_data = data.map((item) => {
                    const temp_item = this.region_options.find(
                        (it) => it.region_id === item['province_id']
                    )
                    if (temp_item) {
                        return {
                            ...item,
                            province_id: temp_item.region_name
                        }
                    }
                    return item
                })
            } else {
                this.detail_province_data = data
            }
        },

        async fetchOverviewReport(dimension) {
            if (!this.data_id) {
                return
            }
            this.m__loading = true

            try {
                let data_level = ''
                let field_name = ''
                if (this.type === 'campaign_id') {
                    data_level = 'AUCTION_CAMPAIGN'
                    field_name = 'campaign_ids'
                } else if (this.type === 'adgroup_id') {
                    data_level = 'AUCTION_ADGROUP'
                    field_name = 'adgroup_ids'
                } else if (this.type === 'ad_id') {
                    data_level = 'AUCTION_AD'
                    field_name = 'ad_ids'
                }

                const filtering = [
                    {
                        filter_value: JSON.stringify([this.data_id]),
                        field_name,
                        filter_type: 'IN'
                    }
                ]

                const response = await getReportFromTTA(
                    this.tiktok_account_id,
                    {
                        page_size: 50,
                        page: 1,
                        filtering: JSON.stringify(filtering),
                        advertiser_id: this.advertiser_id,
                        start_date: this.ranger_date[0],
                        end_date: this.ranger_date[1],
                        type: this.type,
                        report_type: 'AUDIENCE',
                        order_field: this.select_metric,
                        order_type: 'DESC',
                        metrics: JSON.stringify(this.metrics),
                        dimensions: JSON.stringify(dimension.split(',')),
                        data_level
                    }
                )
                const temp = response.data.list.map((item) => ({
                    ...item.dimensions,
                    ...item.metrics
                }))

                if (dimension === 'device_brand_id') {
                    this.formatNameDevice(temp)

                    return
                }

                if (dimension === 'platform') {
                    this.detail_platform_data = temp

                    return
                }

                if (dimension === 'country_code') {
                    this.detail_country_data = temp

                    return
                }

                if (dimension === 'province_id') {
                    this.formatNameProvince(temp)

                    return
                }

                if (dimension.includes('interest_category')) {
                    this.formatInterestCategory(temp)

                    return
                }

                this.data_report_chart = temp

                this.formatChartOptions(dimension)
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async handleChangeTab() {
            if (this.active_tab === 'age_gender') {
                this.radio_group_age_gender = 'age'
                this.handleChangeGroupAgeGender()
                return
            }

            if (this.active_tab === 'ac') {
                this.fetchOverviewReport('device_brand_id')
                this.fetchOverviewReport('platform')
            }

            if (this.active_tab === 'country_code') {
                this.fetchOverviewReport('province_id')
            }

            this.fetchOverviewReport(this.active_tab)
        },

        async handleChangeGroupAgeGender() {
            let dimension = 'age'

            if (this.radio_group_age_gender === 'gender') {
                dimension = 'gender'
            } else if (this.radio_group_age_gender === 'age_gender') {
                dimension = 'age,gender'
            }

            this.fetchOverviewReport(dimension)
        },

        formatChartOptions(dimension) {
            if (['age', 'gender', 'ac'].some((item) => dimension === item)) {
                const m__formatterColumn = (x, y) =>
                    this.m__formatterColumn(x, y, this.currency)
                const p__getNameByTrend = this.p__getNameByTrend

                const categories = this.data_report_chart.map((item) => {
                    const name = item[dimension]
                    if (name === 'MALE') {
                        return this.$t('common.male')
                    }
                    if (name === 'FEMALE') {
                        return this.$t('common.female')
                    }
                    if (name.includes('AGE')) {
                        return formatAge(name)
                    }

                    return name
                })

                const series_data = this.data_report_chart.map(
                    (item) => +item[this.select_metric]
                )

                const series = [
                    {
                        name: this.select_metric,
                        color: '#3d91b3',
                        data: series_data
                    }
                ]

                this.default_chart_options = {
                    chart: {
                        type: 'bar',
                        height: 280
                    },
                    title: {
                        text: '',
                        align: 'left'
                    },
                    xAxis: {
                        categories,
                        title: {
                            text: null
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        },
                        labels: {
                            overflow: 'justify'
                        }
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        shared: true,
                        pointFormatter: function () {
                            const point = this
                            const series = point.series
                            const y = m__formatterColumn(
                                point.y || 0,
                                series.name
                            )
                            return `${p__getNameByTrend(
                                series.name
                            )}: <b>${y}</b><br/>`
                        }
                    },
                    credits: {
                        enabled: false
                    },
                    series
                }
            }

            if (dimension === 'age,gender') {
                const m__formatterColumn = (x) =>
                    this.m__formatterColumn(
                        x,
                        this.select_metric,
                        this.currency
                    )
                const categories = [
                    ...new Set(
                        this.data_report_chart.map((x) => formatAge(x.age))
                    )
                ]
                const data_male = []
                const data_female = []
                categories.forEach((cat) => {
                    const items = this.data_report_chart.filter(
                        (i) => formatAge(i.age) === cat
                    )
                    if (items.length) {
                        items.forEach((item) => {
                            if (item.gender === 'MALE') {
                                data_male.push({
                                    color: '#347DC1',
                                    y: +item[this.select_metric]
                                })
                            }
                            if (item.gender === 'FEMALE') {
                                data_female.push({
                                    color: '#CC6594',
                                    y: +item[this.select_metric]
                                })
                            }
                        })
                    }
                })
                const series = [
                    {
                        name: this.$t('common.male'),
                        type: 'column',
                        color: '#347DC1',
                        data: data_male
                    },
                    {
                        name: this.$t('common.female'),
                        type: 'column',
                        color: '#CC6594',
                        data: data_female
                    }
                ]

                this.age_gender_chart_options = {
                    chart: {
                        type: 'column',
                        height: 280
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories,
                        crosshair: true
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        }
                    },
                    tooltip: {
                        shared: true,
                        pointFormatter: function () {
                            const point = this
                            const series = point.series
                            const y = m__formatterColumn(point.y || 0)
                            return `${series.name}: <b>${y}</b><br/>`
                        }
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                            colorByPoint: false
                        },
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series
                }
            }
        }
    }
}
</script>
