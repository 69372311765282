<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="840px"
        :wrapper_closable="true"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('common.batch_budget_adjustments') }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="
                        m__loading || !adgroup_to_edit_budget_list.length
                    "
                    :loading="m__loading"
                    @click="handleConfirm()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-4">
            <el-alert
                v-if="!adgroup_to_edit_budget_list.length"
                type="warning"
                :closable="false"
                class="mb-2"
            >
                {{ $t('common.no_suitable_ad_groups_bulk_budget_changes') }}
            </el-alert>

            <el-table
                ref="multipleTable"
                class="new-campaign-table"
                :show-summary="true"
                max-height="560"
                :data="adgroup_to_edit_budget_list"
                :summary-method="getSummaries"
            >
                <el-table-column
                    min-width="360"
                    prop="adgroup.adgroup_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div class="group hover:text-brand-atosa--light">
                            <p class="text-desc-text text-xs">
                                #{{ scope.row.id }}
                            </p>

                            <div class="group flex items-center">
                                <div class="cursor-pointer">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.adgroup.adgroup_name
                                        "
                                        placement="top"
                                    >
                                        <p class="truncate max-w-[320px]">
                                            {{ scope.row.adgroup.adgroup_name }}
                                        </p>
                                    </el-tooltip>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="adgroup.budget"
                    sortable
                    :label="$t('common.budget')"
                    width="160px"
                    align="right"
                >
                    <template #default="scope">
                        <div>
                            <p class="font-semibold cursor-pointer">
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.adgroup.budget,
                                        scope.row.currency
                                    )
                                }}
                            </p>

                            <p class="component-text-desc">
                                {{
                                    p__formatBudgetMode(
                                        scope.row.adgroup.budget_mode
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="new_budget"
                    sortable
                    :label="$t('common.new_budget')"
                    width="220px"
                    align="right"
                >
                    <template #default="scope">
                        <div>
                            <!-- <el-input-number
                                v-model="scope.row.new_budget"
                                size="small"
                                controls-position="right"
                                :min="
                                    p__findMinimumDailyBudgetAdgroup(
                                        scope.row.currency
                                    )
                                "
                            ></el-input-number> -->

                            <el-input
                                v-model="scope.row.new_budget"
                                type="number"
                                size="small"
                            >
                                <template slot="append">
                                    {{ scope.row.currency }}
                                </template>
                            </el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="component-box mt-4">
                <p class="text-sm font-semibold mb-1">
                    {{ $t('common.budget') }}
                </p>
                <div class="flex space-x-2">
                    <el-select
                        v-model="type_plus"
                        size="small"
                        class="w-[120px]"
                        placeholder="Select"
                    >
                        <el-option
                            :label="$t('common.replace')"
                            :value="'replace'"
                        >
                        </el-option>
                        <el-option :label="$t('common.plus')" :value="'plus'">
                        </el-option>
                        <el-option :label="$t('common.minus')" :value="'minus'">
                        </el-option>
                    </el-select>

                    <el-input-number
                        v-if="kind_global === 'number'"
                        v-model="global_budget"
                        size="small"
                        controls-position="right"
                        class="w-[140px]"
                        :min="min_budget"
                    ></el-input-number>

                    <el-input-number
                        v-if="kind_global === 'percent'"
                        v-model="global_percent"
                        size="small"
                        controls-position="right"
                        class="w-[140px]"
                        :min="0"
                        :max="99"
                    ></el-input-number>

                    <el-select
                        v-model="kind_global"
                        size="small"
                        class="w-[120px]"
                        placeholder="Select"
                    >
                        <el-option
                            :label="$t('common.budget')"
                            :value="'number'"
                        >
                        </el-option>
                        <el-option
                            :label="$t('common.percent')"
                            :value="'percent'"
                        >
                        </el-option>
                    </el-select>

                    <el-button
                        size="small"
                        plain
                        type="success"
                        class="!ml-4"
                        @click="handleChangeAll"
                    >
                        {{ $t('common.batch_editing') }}</el-button
                    >
                </div>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import { updateAdgroup } from '@/services/atosa-tiktok-ads/adgroup'

export default {
    props: [
        'visible',
        'multiple_selection',
        'atosa_bc_list',
        'advertiser_id',
        'belong_to_atosa',
        'tiktok_account_id'
    ],

    data() {
        return {
            min_budget: 0,
            type_plus: 'replace',
            kind_global: 'number',
            global_budget: 0,
            global_percent: 0,
            adgroup_to_edit_budget_list: []
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.adgroup_to_edit_budget_list = this.multiple_selection
                    .filter(
                        (item) =>
                            item.adgroup.budget_mode !==
                                'BUDGET_MODE_INFINITE' &&
                            this.atosa_bc_list.includes(
                                item.adgroup.identity_authorized_bc_id
                            ) &&
                            this.atosa_bc_list.includes(
                                item.adgroup.store_authorized_bc_id
                            )
                    )
                    .map((item) => ({
                        ...item,
                        new_budget: item.adgroup.budget
                    }))
            }
        },

        adgroup_to_edit_budget_list() {
            if (this.adgroup_to_edit_budget_list.length > 0) {
                this.min_budget = this.p__findMinimumDailyBudgetAdgroup(
                    this.adgroup_to_edit_budget_list[0].currency
                )

                this.global_budget = this.min_budget
            }
        }
    },

    methods: {
        handleChangeAll() {
            if (this.kind_global === 'number') {
                if (this.type_plus === 'replace') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget: this.global_budget
                        }))

                    return
                }
                if (this.type_plus === 'plus') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget:
                                Number(item.new_budget) + this.global_budget
                        }))

                    return
                }
                if (this.type_plus === 'minus') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget:
                                Number(item.new_budget) - this.global_budget >
                                this.min_budget
                                    ? Number(item.new_budget) -
                                      this.global_budget
                                    : this.min_budget
                        }))

                    return
                }
            }

            if (this.kind_global === 'percent') {
                if (this.type_plus === 'replace') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget: Math.round(
                                Number(item.new_budget) *
                                    (this.global_percent / 100)
                            )
                        }))
                }

                if (this.type_plus === 'plus') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget: Math.round(
                                Number(item.new_budget) +
                                    Number(item.new_budget) *
                                        (this.global_percent / 100)
                            )
                        }))
                }

                if (this.type_plus === 'minus') {
                    this.adgroup_to_edit_budget_list =
                        this.adgroup_to_edit_budget_list.map((item) => ({
                            ...item,
                            new_budget:
                                Number(item.new_budget) -
                                    Math.round(
                                        (Number(item.new_budget) *
                                            this.global_percent) /
                                            100
                                    ) >
                                this.min_budget
                                    ? Number(item.new_budget) -
                                      Math.round(
                                          (Number(item.new_budget) *
                                              this.global_percent) /
                                              100
                                      )
                                    : this.min_budget
                        }))
                }

                return
            }
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column
                if (index === 0) {
                    const name_translate = 'adgroup'

                    sums[index] = `${this.$t('common.total')} \n ${
                        this.adgroup_to_edit_budget_list.length
                    } ${this.$t('common.xxx_has_plural_noun', {
                        xxx: this.$t(`common.${name_translate}`)
                    })}`
                    return
                }

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (
                ['campaign.budget', 'adgroup.budget', 'new_budget'].some(
                    (item) => column_property === item
                )
            ) {
                total = this.p__renderMoneyByCurrency(total, data[0].currency)
            }
            return total
        },

        async handleConfirm() {
            if (this.adgroup_to_edit_budget_list.length === 0) {
                return
            }

            this.m__loading = true
            try {
                await Promise.all(
                    this.adgroup_to_edit_budget_list.map(async (item) => {
                        await updateAdgroup(this.tiktok_account_id, {
                            advertiser_id: this.advertiser_id,
                            budget: item.new_budget,
                            store_authorized_bc_id:
                                item.adgroup.store_authorized_bc_id,
                            adgroup_id: item.adgroup.adgroup_id,
                            belong_to_atosa: this.belong_to_atosa
                        })
                    })
                )

                this.m__loading = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.p__event_bus.$emit('eb_reload_adgroup')

                this.visible_in_line = false
            } catch (error) {
                this.m__loading = false

                console.error(error)
            }
        }
    }
}
</script>
