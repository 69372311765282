<template>
    <el-popover
        v-model="visible"
        :visible-arrow="false"
        placement="bottom-start"
        width="480"
        trigger="click"
    >
        <div
            class="flex h-[280px]"
            :style="{ 'border-bottom': '1px solid #e3e3e3' }"
        >
            <div
                class="w-[240px] overflow-auto"
                :style="{ 'border-right': '1px solid #e3e3e3' }"
            >
                <LeftMenuNode
                    v-for="(item, index) in filter_options"
                    :key="index"
                    :node="item"
                    :visible="visible"
                    :num_rendered="num_rendered"
                    :selected_list_values="selected_list_values"
                    :active_filter.sync="active_filter"
                    :level="1"
                ></LeftMenuNode>
            </div>
            <div class="w-[240px]">
                <RightContent
                    v-show="active_filter"
                    :active_filter="active_filter"
                    :num_rendered.sync="num_rendered"
                    :selected_list_values.sync="selected_list_values"
                ></RightContent>
            </div>
        </div>
        <div class="flex items-center px-3 py-2">
            <div class="ml-auto">
                <el-button
                    slot="reference"
                    type="primary"
                    size="mini"
                    @click="handleChangeFiltering"
                >
                    {{ $t('button.apply') }}
                </el-button>
            </div>
        </div>
        <el-button
            slot="reference"
            icon="el-icon-s-operation"
            class="min-w-[92px]"
            size="small"
            type="info"
            plain
        >
            {{ $t('button.filters') }}
        </el-button>
    </el-popover>
</template>

<script>
import LeftMenuNode from './left-menu-node'
import RightContent from './right-content'

export default {
    components: {
        LeftMenuNode,
        RightContent
    },

    props: ['handle_change_filtering', 'filtering'],

    data() {
        return {
            num_rendered: 0,
            visible: false,
            active_filter: undefined,
            selected_list_values: {},
            filter_options: [
                {
                    title: this.$t('common.status'),
                    children: [
                        {
                            title: this.$t('common.xxx_status', {
                                xxx: this.$t('common.campaign')
                            }),
                            value: 'campaign_status',
                            right_children: [
                                {
                                    title: this.$t('common.active'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELIVERY_OK
                                },
                                {
                                    title: this.$t('common.not_delivering'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_NOT_DELIVERY
                                },
                                {
                                    title: this.$t('common.inactive'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DISABLE
                                },
                                {
                                    title: this.$t('common.deleted'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELETE
                                }
                            ]
                        },
                        {
                            title: this.$t('common.xxx_status', {
                                xxx: this.$t('common.adgroup')
                            }),
                            value: 'adgroup_status',
                            right_children: [
                                {
                                    title: this.$t('common.active'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELIVERY_OK
                                },
                                {
                                    title: this.$t('common.not_delivering'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_NOT_DELIVERY
                                },
                                {
                                    title: this.$t('common.inactive'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DISABLE
                                },
                                {
                                    title: this.$t('common.completed'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_TIME_DONE
                                },
                                {
                                    title: this.$t('common.deleted'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELETE
                                },
                                {
                                    title: this.$t('common.closed'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_RF_CLOSED
                                }
                            ]
                        },
                        {
                            title: this.$t('common.xxx_status', {
                                xxx: this.$t('common.ad')
                            }),
                            value: 'ad_status',
                            right_children: [
                                {
                                    title: this.$t('common.active'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELIVERY_OK
                                },
                                {
                                    title: this.$t('common.not_delivering'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_NOT_DELIVERY
                                },
                                {
                                    title: this.$t('common.inactive'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DISABLE
                                },
                                {
                                    title: this.$t('common.deleted'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_DELETE
                                },
                                {
                                    title: this.$t('common.closed'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_RF_CLOSED
                                },
                                {
                                    title: this.$t('common.completed'),
                                    value: this.p__list_advertiser_status
                                        .STATUS_TIME_DONE
                                }
                            ]
                        }
                    ]
                },
                {
                    title: this.$t('common.objective_type'),
                    value: 'objective_type',
                    right_children: [
                        {
                            value: 'TRAFFIC',
                            title: this.$t('common.traffic')
                        },
                        {
                            value: 'APP_INSTALL',
                            title: this.$t('page.campaign.column_app_install')
                        },
                        {
                            value: 'CONVERSIONS',
                            title: this.$t('common.conversion')
                        },
                        {
                            value: 'VIDEO_VIEWS',
                            title: this.$t('page.campaign.video_views')
                        },
                        {
                            value: 'REACH',
                            title: this.$t('common.reach')
                        },
                        {
                            value: 'LEAD_GENERATION',
                            title: this.$t('page.campaign.lead_generation')
                        },
                        {
                            title: this.$t('page.campaign.app_promotion'),
                            value: 'APP_PROMOTION'
                        },
                        // {
                        //     title: this.$t('page.campaign.website_conversions'),
                        //     value: 'WEB_CONVERSIONS'
                        // },
                        {
                            title: this.$t('page.campaign.PRODUCT_SALES'),
                            value: 'PRODUCT_SALES'
                        }
                    ]
                },
                {
                    title: this.$t('page.campaign.get_target'),
                    children: [
                        {
                            title: this.$t('common.age'),
                            value: 'age',
                            right_children: [
                                {
                                    title: '13-17',
                                    value: 'AGE_13_17'
                                },
                                {
                                    title: '18-24',
                                    value: 'AGE_18_24'
                                },
                                {
                                    title: '24-34',
                                    value: 'AGE_25_34'
                                },
                                {
                                    title: '35-44',
                                    value: 'AGE_35_44'
                                },
                                {
                                    title: '45-54',
                                    value: 'AGE_45_54'
                                },
                                {
                                    title: '55+',
                                    value: 'AGE_55_100'
                                }
                            ]
                        },
                        {
                            title: this.$t('common.gender'),
                            value: 'gender',
                            right_children: [
                                {
                                    title: this.$t('common.male'),
                                    value: 'GENDER_MALE'
                                },
                                {
                                    title: this.$t('common.female'),
                                    value: 'GENDER_FEMALE'
                                }
                            ]
                        },
                        {
                            title: this.$t(
                                'page.campaign.dpa_target_audience_type'
                            ),
                            value: 'dpa_target_audience_type',
                            right_children: [
                                {
                                    title: this.$t(
                                        'page.campaign.dpa_target_audience_type_prospecting'
                                    ),
                                    value: 'PROSPECTING'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.dpa_target_audience_type_retargeting'
                                    ),
                                    value: 'RETARGETING'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: this.$t('common.adgroup'),
                    children: [
                        {
                            title: this.$t('page.campaign.optimization_goal'),
                            value: 'optimization_goal',
                            right_children: [
                                {
                                    value: 'CLICK',
                                    title: this.$t('common.click')
                                },
                                {
                                    value: 'CONVERT',
                                    title: this.$t('common.conversion')
                                },
                                {
                                    value: 'INSTALL',
                                    title: this.$t('page.campaign.install')
                                },
                                {
                                    value: 'IN_APP_EVENT',
                                    title: this.$t('page.campaign.in_app_event')
                                },
                                {
                                    value: 'SHOW',
                                    title: this.$t('common.impression')
                                },
                                {
                                    value: 'VIDEO_VIEW',
                                    title: this.$t('page.campaign.video_view')
                                },
                                {
                                    value: 'REACH',
                                    title: this.$t('common.reach')
                                },
                                {
                                    value: 'LEAD_GENERATION',
                                    title: this.$t(
                                        'page.campaign.optimization_goal_lead_generation'
                                    )
                                },
                                {
                                    value: 'FOLLOWERS',
                                    title: this.$t('page.campaign.followers')
                                },
                                {
                                    value: 'VALUE',
                                    title: this.$t(
                                        'page.campaign.optimization_goal_gmv'
                                    )
                                },
                                {
                                    value: 'GMV',
                                    title: 'GMV'
                                },
                                {
                                    value: 'MT_LIVE_ROOM',
                                    title: this.$t(
                                        'page.campaign.viewer_retention'
                                    )
                                },
                                {
                                    value: 'PRODUCT_CLICK_IN_LIVE',
                                    title: this.$t(
                                        'page.campaign.product_click_in_live'
                                    )
                                },
                                {
                                    value: 'ENGAGED_VIEW',
                                    title: this.$t(
                                        'page.campaign.column_engaged_view'
                                    )
                                },
                                {
                                    value: 'ENGAGED_VIEW_FIFTEEN',
                                    title: this.$t(
                                        'page.campaign.column_engaged_view_15s'
                                    )
                                },
                                {
                                    value: 'TRAFFIC_LANDING_PAGE_VIEW',
                                    title: this.$t(
                                        'page.campaign.traffic_landing_page_view'
                                    )
                                }
                            ]
                        },
                        {
                            title: this.$t('page.campaign.promotion_type'),
                            value: 'promotion_type',
                            right_children: [
                                {
                                    title: this.$t('common.app'),
                                    value: 'APP'
                                },
                                {
                                    title: this.$t('common.website'),
                                    value: 'WEBSITE'
                                },
                                {
                                    title: this.$t('page.campaign.instant_form'),
                                    value: 'INSTANT_FORM'
                                },
                                {
                                    title: this.$t('common.other'),
                                    value: 'OTHERS'
                                }
                            ]
                        },
                        {
                            title: this.$t('page.campaign.bid_strategy'),
                            value: 'bid_strategy',
                            right_children: [
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_cost_cap'
                                    ),
                                    value: 'BID_STRATEGY_COST_CAP'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_bid_cap'
                                    ),
                                    value: 'BID_STRATEGY_BID_CAP'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_max_conversion'
                                    ),
                                    value: 'BID_STRATEGY_MAX_CONVERSION'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_lowest_cost'
                                    ),
                                    value: 'BID_STRATEGY_LOWEST_COST'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_highest_value'
                                    ),
                                    value: 'BID_STRATEGY_HIGHEST_VALUE'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_gmv'
                                    ),
                                    value: 'BID_STRATEGY_GMV'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.bid_strategy_minimum_roas'
                                    ),
                                    value: 'BID_STRATEGY_MINIMUM_ROAS'
                                }
                            ]
                        },
                        {
                            title: this.$t('page.campaign.delivery_type'),
                            value: 'delivery_type',
                            right_children: [
                                {
                                    title: this.$t('common.standard'),
                                    value: 'PACING_MODE_SMOOTH'
                                },
                                {
                                    title: this.$t('page.campaign.accelerated'),
                                    value: 'PACING_MODE_FAST'
                                }
                            ]
                        },
                        {
                            title: this.$t('page.campaign.billing_event'),
                            value: 'billing_event',
                            right_children: [
                                {
                                    title: 'CPC',
                                    value: 'CPC'
                                },
                                {
                                    title: 'CPM',
                                    value: 'CPM'
                                },
                                {
                                    title: 'CPV',
                                    value: 'CPV'
                                },
                                {
                                    title: 'oCPC',
                                    value: 'OCPC'
                                },
                                {
                                    title: 'oCPM',
                                    value: 'OCPM'
                                }
                            ]
                        },
                        {
                            title: this.$t('common.ad_type'),
                            value: 'ad_entity_type',
                            right_children: [
                                {
                                    title: this.$t(
                                        'page.campaign.video_shopping'
                                    ),
                                    value: 'VIDEO_SHOPPING_ADS'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.live_shopping'
                                    ),
                                    value: 'LIVE_SHOPPING_ADS'
                                },
                                {
                                    title: this.$t(
                                        'page.campaign.product_shopping_ads'
                                    ),
                                    value: 'PRODUCT_SHOPPING_ADS'
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },

    watch: {
        filtering() {
            this.selected_list_values = {}

            if (!this.filtering.length) {
                return
            }
            for (const item of this.filtering) {
                this.selected_list_values[item.field_name] = JSON.parse(
                    item.filter_value
                )
            }
        }
    },

    methods: {
        handleChangeFiltering() {
            for (const [key, value] of Object.entries(
                this.selected_list_values
            )) {
                this.handle_change_filtering(key, value)
            }

            this.visible = false
        }
    }
}
</script>
