<template>
    <div>
        <el-tabs
            v-model="main_tab"
            type="card"
            class="tiktok-ads-tabs"
            @tab-remove="removeTab"
            @tab-click="handleClickTab"
        >
            <el-tab-pane
                v-for="item in editable_tabs"
                :key="item.name"
                :label="item.title"
                :name="item.name"
                :closable="editable_tabs.length > 1"
            >
            </el-tab-pane>
            <el-tab-pane
                :v-show="!m__fetching_ads_accounts"
                name="add_view"
                :closable="false"
            >
                <span slot="label"
                    ><i class="el-icon-plus"></i>
                    {{ $t('common.see_more') }}</span
                >
            </el-tab-pane>
        </el-tabs>

        <CampaignWrapper
            v-for="item in editable_tabs"
            v-show="main_tab === item.name"
            :key="item.name"
            :ads_accounts="m__ads_accounts"
            :selected_tiktok_business="m__selected_tiktok_business"
            :fetching_ads_accounts="m__fetching_ads_accounts"
            :atosa_bc_list="atosa_bc_list"
            :handleChangeTitle="
                (new_title) => handleChangeTitle(item.name, new_title)
            "
        ></CampaignWrapper>
    </div>
</template>

<script>
import CampaignWrapper from './campaign-wrapper'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { getAtosaBusinessCenters } from '@/services/atosa-tiktok-ads/business-center'

export default {
    components: {
        CampaignWrapper
    },

    mixins: [selectedTiktokBusinessMixin],

    data() {
        return {
            atosa_bc_list: [],
            main_tab: '1',
            editable_tabs: [
                {
                    title: 'Ads 1',
                    name: '1'
                }
            ],
            tab_index: 1
        }
    },

    watch: {
        m__selected_tiktok_business() {
            this.m__fetchAdsAccountsByTiktokBusiness()
        }
    },

    mounted() {
        this.getAtosaBusinessCenters()

        this.m__fetchAdsAccountsByTiktokBusiness()
    },

    methods: {
        async getAtosaBusinessCenters() {
            try {
                const response = await getAtosaBusinessCenters()

                if (response.code === 0) {
                    this.atosa_bc_list = response.data
                }
            } catch (error) {
                console.error(error)
            }
        },

        handleChangeTitle(id, new_title) {
            this.editable_tabs = this.editable_tabs.map((item) => {
                if (item.name === id) {
                    return {
                        ...item,
                        title: new_title
                    }
                }

                return item
            })
        },

        handleClickTab(el) {
            if (el.name === 'add_view') {
                this.addTab(this.main_tab)
            }
        },

        addTab() {
            const newTabName = ++this.tab_index + ''
            this.editable_tabs.push({
                title: 'Ads ' + this.tab_index,
                name: newTabName
            })
            this.main_tab = newTabName
        },

        removeTab(target_name) {
            const tabs = this.editable_tabs
            let active_name = this.main_tab
            if (active_name === target_name) {
                tabs.forEach((tab, index) => {
                    if (tab.name === target_name) {
                        const nextTab = tabs[index + 1] || tabs[index - 1]
                        if (nextTab) {
                            active_name = nextTab.name
                        }
                    }
                })
            }

            this.main_tab = active_name
            this.editable_tabs = tabs.filter((tab) => tab.name !== target_name)
        }
    }
}
</script>
