<template>
    <div
        v-if="data_lifetime"
        v-loading="m__loading"
        class="mt-6 flex items-center"
    >
        <p class="text-sm font-semibold text-brand-atosa--light">
            {{ $t('common.lifetime') }}
        </p>
        <div class="flex space-x-8 ml-8">
            <div v-for="(item, index) in data_metrics" :key="index">
                <p class="text-xs">
                    {{ item.label }}
                </p>
                <p class="text-sm font-semibold text-brand-atosa--light mt-1">
                    {{
                        item.has_currency
                            ? p__renderMoneyByCurrency(
                                  data_lifetime.metrics[item.value],
                                  currency
                              )
                            : p__formatNumberHasCommas(
                                  data_lifetime.metrics[item.value]
                              )
                    }}
                    {{ item.has_percent ? '%' : '' }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'

export default {
    props: [
        'tiktok_account_id',
        'advertiser_id',
        'data_id',
        'metrics',
        'type',
        'currency'
    ],

    data() {
        return {
            data_lifetime: null,
            data_metrics: [
                {
                    label: this.$t('common.cost'),
                    value: 'spend',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_cost_per_conversion'),
                    value: 'cost_per_conversion',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_cpc'),
                    value: 'cpc',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t(`page.campaign.column_impressions`),
                    value: 'impressions',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_ctr'),
                    value: 'ctr',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t(`page.campaign.column_clicks`),
                    value: 'clicks',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_conversion_rate'),
                    value: 'conversion_rate',
                    has_currency: false,
                    has_percent: true
                },
                {
                    label: this.$t('page.campaign.column_conversion'),
                    value: 'conversion',
                    has_currency: false,
                    has_percent: false
                }
            ]
        }
    },

    watch: {
        data_id() {
            this.fetchOverviewReport()
        }
    },

    mounted() {
        this.fetchOverviewReport()
    },

    methods: {
        async fetchOverviewReport() {
            if (!this.data_id) {
                return
            }

            this.m__loading = true

            let data_level = 'AUCTION_CAMPAIGN'
            let field_name = 'campaign_ids'
            if (this.type === 'adgroup_id') {
                data_level = 'AUCTION_ADGROUP'
                field_name = 'adgroup_ids'
            } else if (this.type === 'ad_id') {
                data_level = 'AUCTION_AD'
                field_name = 'ad_ids'
            }

            try {
                const data = {
                    page: 1,
                    page_size: 40,
                    report_type: 'BASIC',
                    data_level,
                    metrics: JSON.stringify(this.metrics),
                    start_date: '2024-03-19',
                    end_date: '2024-04-02',
                    advertiser_id: this.advertiser_id,
                    dimensions: [this.type],
                    query_lifetime: 'true',
                    filtering: [
                        {
                            field_name,
                            filter_type: 'IN',
                            filter_value: JSON.stringify([this.data_id])
                        }
                    ]
                }

                const response = await getSyncReport(
                    this.tiktok_account_id,
                    data
                )

                if (response.code === 0 && response.data.list.length > 0) {
                    this.data_lifetime = response.data.list[0]
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
