<template>
    <div>
        <div>
            <el-tooltip
                class="item"
                effect="dark"
                :content="$t('common.add_screen_to_compare_audience')"
                placement="top"
            >
                <el-button
                    plain
                    icon="el-icon-plus"
                    size="small"
                    @click="number_screen_audience.push(true)"
                >
                    {{ $t('common.add_screen') }}
                </el-button>
            </el-tooltip>
        </div>

        <div class="flex flex-col space-y-3 mt-2">
            <div
                v-for="(item, index) in number_screen_audience"
                :key="index"
                class="relative border-lg p-4 rounded-lg border-border border-[1px]"
            >
                <AudienceData
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="advertiser_id"
                    :ranger_date="ranger_date"
                    :currency="currency"
                    :type="type"
                    :data_id="data_id"
                    :objective_type="objective_type"
                    :region_options="region_options"
                    :device_model_options="device_model_options"
                    :interest_category_options="interest_category_options"
                ></AudienceData>

                <p
                    v-if="switch_compare"
                    class="text-center mt-4 text-sm italic"
                >
                    {{
                        ranger_date[0]
                            | f__format_moment($t('common.format_date'))
                    }}
                    -
                    {{
                        ranger_date[1]
                            | f__format_moment($t('common.format_date'))
                    }}
                </p>

                <div v-if="switch_compare" class="mt-4">
                    <AudienceData
                        :tiktok_account_id="tiktok_account_id"
                        :advertiser_id="advertiser_id"
                        :ranger_date="compare_ranger_date"
                        :currency="currency"
                        :type="type"
                        :data_id="data_id"
                        :objective_type="objective_type"
                        :region_options="region_options"
                        :device_model_options="device_model_options"
                        :interest_category_options="interest_category_options"
                    ></AudienceData>

                    <p class="text-center mt-4 text-sm italic">
                        {{
                            compare_ranger_date[0]
                                | f__format_moment($t('common.format_date'))
                        }}
                        -
                        {{
                            compare_ranger_date[1]
                                | f__format_moment($t('common.format_date'))
                        }}
                    </p>
                </div>

                <div
                    v-if="index > 0"
                    class="rounded-full h-6 w-6 bg-red absolute -right-2 -top-2 util-flex-center cursor-pointer opacity-70 hover:opacity-100"
                    @click="
                        number_screen_audience = number_screen_audience.filter(
                            (_, idx) => idx !== index
                        )
                    "
                >
                    <i class="el-icon-close !text-white"></i>
                </div>
            </div>
        </div>

        <div class="mt-8 mb-4 bg-border w-full h-[1px]"></div>

        <DetailDataTable
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :currency="currency"
            :type="type"
            :data_id="data_id"
            :objective_type="objective_type"
            :region_options="region_options"
            :device_model_options="device_model_options"
            :interest_category_options="interest_category_options"
        ></DetailDataTable>

        <p v-if="switch_compare" class="text-center mt-4 text-sm italic">
            {{ ranger_date[0] | f__format_moment($t('common.format_date')) }} -
            {{ ranger_date[1] | f__format_moment($t('common.format_date')) }}
        </p>

        <div v-if="switch_compare" class="mt-4">
            <DetailDataTable
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
                :ranger_date="compare_ranger_date"
                :currency="currency"
                :type="type"
                :data_id="data_id"
                :objective_type="objective_type"
                :region_options="region_options"
                :device_model_options="device_model_options"
                :interest_category_options="interest_category_options"
            ></DetailDataTable>

            <p class="text-center mt-4 text-sm italic">
                {{
                    compare_ranger_date[0]
                        | f__format_moment($t('common.format_date'))
                }}
                -
                {{
                    compare_ranger_date[1]
                        | f__format_moment($t('common.format_date'))
                }}
            </p>
        </div>
    </div>
</template>

<script>
import DetailDataTable from './detail-data-table'
import AudienceData from './audience-data'
import {
    getQueryTool,
    getRegions,
    getAllDeviceModels
} from '@/services/atosa-tiktok-ads/tool'

export default {
    components: {
        DetailDataTable,
        AudienceData
    },

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'switch_compare',
        'ranger_date',
        'compare_ranger_date',
        'currency',
        'objective_type',
        'type',
        'data_id'
    ],

    data() {
        return {
            number_screen_audience: [true],
            region_options: [],
            device_model_options: [],
            interest_category_options: []
        }
    },

    mounted() {
        this.getRegions()

        this.getAllDeviceModels()

        this.getInterestCategories()
    },

    methods: {
        async getRegions() {
            const response = await getRegions(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                language: this.s__language
            })
            this.region_options = response.data
        },

        async getAllDeviceModels() {
            const response = await getAllDeviceModels()
            this.device_model_options = response.data
        },

        async getInterestCategories() {
            const response = await getQueryTool(this.tiktok_account_id, {
                path: 'interest_category/',
                params: {
                    advertiser_id: this.advertiser_id,
                    language: this.$i18n.locale
                }
            })
            this.interest_category_options = response.data.interest_categories
        }
    }
}
</script>
