<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="840px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
    >
        <div class="h-[400px] flex border-[1px] border-border">
            <div class="w-[220px] min-w-[220px] overflow-auto">
                <p class="component-text-desc ml-2 my-2">
                    {{ $t('common.performance') }}
                </p>
                <ul>
                    <li
                        v-for="item in performance_column_options"
                        :key="item.value"
                        class="cursor-pointer py-2 pl-3 hover:bg-bg"
                        :class="[
                            selected_option &&
                            selected_option.value === item.value
                                ? 'bg-bg'
                                : ''
                        ]"
                        @click="selected_option = item"
                    >
                        <p>
                            {{ item.label }}
                        </p>
                    </li>
                </ul>
            </div>
            <div class="h-full w-[1px] bg-border"></div>
            <div v-if="selected_option" class="flex-1 w-[200px]">
                <el-checkbox
                    v-model="check_all"
                    class="py-3 pl-3 border-border border-b-[1px] hover:bg-bg"
                    :indeterminate="is_indeterminate"
                    @change="handleCheckAllChange"
                >
                    <p class="text-sm">
                        {{ $t('common.all') }}
                    </p>
                </el-checkbox>

                <div class="h-[calc(100%-44px)] overflow-auto">
                    <el-checkbox-group
                        v-model="checked_values"
                        @change="handleCheckedChange"
                    >
                        <el-checkbox
                            v-for="item in selected_option.child_options"
                            :key="item.value"
                            class="py-3 pl-3 hover:bg-bg"
                            :label="`${item.value}`"
                        >
                            <div>
                                <p class="text-sm">
                                    {{ item.label }}
                                </p>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <!-- <div class="h-full w-[1px] bg-border"></div> -->

            <!-- <div>
                <div class="py-2 pl-3 border-border border-b-[1px] hover:bg-bg">
                    <p class="text-sm font-semibold">
                        {{ length_selected_columns }} columns selected
                    </p>
                </div>
                <div class="w-[220px] pt-3 px-2 overflow-auto h-[360px]">
                    <draggable
                        :list="new_column_values"
                        class=""
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false"
                    >
                        <div
                            class="px-3 py-3 bg-bg mb-3 cursor-move relative"
                            v-for="element in new_column_values"
                            :key="element.keyword_id"
                        >
                            <font-awesome-icon
                                icon="fa-solid fa-grip-vertical"
                            />

                            {{ element.label }}

                            <div
                                class="absolute -right-2 -top-2 rounded-full bg-white util-flex-center w-5 h-5 shadow-lg border-border border-[1px] cursor-pointer"
                                @click="handleRemoveMetric(index)"
                            >
                                <i class="el-icon-close text-sm"></i>
                            </div>
                        </div>
                    </draggable>
                </div>
            </div> -->
        </div>

        <template slot="title">
            <div class="flex">
                <div
                    class="text-left truncate w-4/6 text-base font-semibold"
                >
                    {{ $t('button.custom_column') }}
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    @click="handleCustomColumn()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
// import draggable from 'vuedraggable'

export default {
    // components: {
    //     draggable
    // },

    props: ['visible', 'column_values'],

    data() {
        return {
            dragging: false,
            check_all: false,
            checked_values: [],
            is_indeterminate: false,
            selected_option: null,
            performance_column_values: [],
            performance_column_options: [
                {
                    label: this.$t('page.campaign.custom_column_basic_data'),
                    value: 'BASIC_DATA',
                    child_options: [
                        {
                            label: this.$t(`page.campaign.column_spend`),
                            value: 'spend'
                        },
                        {
                            label: this.$t(`page.campaign.column_cpc`),
                            value: 'cpc'
                        },
                        {
                            label: this.$t(`page.campaign.column_cpm`),
                            value: 'cpm'
                        },
                        {
                            label: this.$t(`page.campaign.column_impressions`),
                            value: 'impressions'
                        },
                        {
                            label: this.$t(`page.campaign.column_clicks`),
                            value: 'clicks'
                        },
                        {
                            label: this.$t('page.campaign.column_ctr'),
                            value: 'ctr'
                        },
                        {
                            label: this.$t('page.campaign.column_reach'),
                            value: 'reach'
                        },
                        // {
                        //     label: this.$t(
                        //         'page.campaign.column_cost_per_1000_reached'
                        //     ),
                        //     value: 'cost_per_1000_reached'
                        // },
                        {
                            label: this.$t('page.campaign.column_frequency'),
                            value: 'frequency'
                        },
                        {
                            label: this.$t('page.campaign.column_conversion'),
                            value: 'conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_conversion'
                            ),
                            value: 'cost_per_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_conversion_rate'
                            ),
                            value: 'conversion_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_conversion'
                            ),
                            value: 'real_time_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_cost_per_conversion'
                            ),
                            value: 'real_time_cost_per_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_conversion_rate'
                            ),
                            value: 'real_time_conversion_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_result'),
                            value: 'result'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_result'
                            ),
                            value: 'cost_per_result'
                        },
                        {
                            label: this.$t('page.campaign.column_result_rate'),
                            value: 'result_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_result'
                            ),
                            value: 'real_time_result'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_cost_per_result'
                            ),
                            value: 'real_time_cost_per_result'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_result_rate'
                            ),
                            value: 'real_time_result_rate'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_video_play'),
                    value: 'VIDEO_PLAY',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_video_play_actions'
                            ),
                            value: 'video_play_actions'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_watched_2s'
                            ),
                            value: 'video_watched_2s'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_watched_6s'
                            ),
                            value: 'video_watched_6s'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_average_video_play'
                            ),
                            value: 'average_video_play'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_average_video_play_per_user'
                            ),
                            value: 'average_video_play_per_user'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p25'
                            ),
                            value: 'video_views_p25'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p50'
                            ),
                            value: 'video_views_p50'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p75'
                            ),
                            value: 'video_views_p75'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_video_views_p100'
                            ),
                            value: 'video_views_p100'
                        },
                        {
                            label: this.$t('page.campaign.column_engaged_view'),
                            value: 'engaged_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_engaged_view_15s'
                            ),
                            value: 'engaged_view_15s'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_engagement'),
                    value: 'ENGAGEMENT',
                    child_options: [
                        {
                            label: this.$t('page.campaign.column_engagements'),
                            value: 'engagements'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_engagement_rate'
                            ),
                            value: 'engagement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_profile_visits'
                            ),
                            value: 'profile_visits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_profile_visits_rate'
                            ),
                            value: 'profile_visits_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_likes'),
                            value: 'likes'
                        },
                        {
                            label: this.$t('page.campaign.column_comments'),
                            value: 'comments'
                        },
                        {
                            label: this.$t('page.campaign.column_shares'),
                            value: 'shares'
                        },
                        {
                            label: this.$t('page.campaign.column_follows'),
                            value: 'follows'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_clicks_on_music_disc'
                            ),
                            value: 'clicks_on_music_disc'
                        },
                        {
                            label: this.$t('page.campaign.column_duet_clicks'),
                            value: 'duet_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_stitch_clicks'
                            ),
                            value: 'stitch_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_sound_usage_clicks'
                            ),
                            value: 'sound_usage_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_anchor_clicks'
                            ),
                            value: 'anchor_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_anchor_click_rate'
                            ),
                            value: 'anchor_click_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_clicks_on_hashtag_challenge'
                            ),
                            value: 'clicks_on_hashtag_challenge'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_duration_avg'
                            ),
                            value: 'ix_page_duration_avg'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_viewrate_avg'
                            ),
                            value: 'ix_page_viewrate_avg'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views'
                            ),
                            value: 'ix_video_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p25'
                            ),
                            value: 'ix_video_views_p25'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p50'
                            ),
                            value: 'ix_video_views_p50'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p75'
                            ),
                            value: 'ix_video_views_p75'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_video_views_p100'
                            ),
                            value: 'ix_video_views_p100'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_average_video_play'
                            ),
                            value: 'ix_average_video_play'
                        }
                    ]
                },
                {
                    label: this.$t(
                        'page.campaign.custom_column_interactive_add_on'
                    ),
                    value: 'INTERACTIVE_ADD_ON',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_impressions'
                            ),
                            value: 'interactive_add_on_impressions'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_destination_clicks'
                            ),
                            value: 'interactive_add_on_destination_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_activity_clicks'
                            ),
                            value: 'interactive_add_on_activity_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_option_a_clicks'
                            ),
                            value: 'interactive_add_on_option_a_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_interactive_add_on_option_b_clicks'
                            ),
                            value: 'interactive_add_on_option_b_clicks'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_countdown_sticker_recall_clicks'
                            ),
                            value: 'countdown_sticker_recall_clicks'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_live'),
                    value: 'LIVE',
                    child_options: [
                        {
                            label: this.$t('page.campaign.column_live_views'),
                            value: 'live_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_unique_views'
                            ),
                            value: 'live_unique_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_effective_views'
                            ),
                            value: 'live_effective_views'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_live_product_clicks'
                            ),
                            value: 'live_product_clicks'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_in_app_event'),
                    value: 'IN_APP_EVENT',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_app_install'
                            ),
                            value: 'real_time_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_real_time_app_install_cost'
                            ),
                            value: 'real_time_app_install_cost'
                        },
                        {
                            label: this.$t('page.campaign.column_app_install'),
                            value: 'app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_app_install'
                            ),
                            value: 'cost_per_app_install'
                        },
                        {
                            label: this.$t('page.campaign.column_registration'),
                            value: 'registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_registration'
                            ),
                            value: 'cost_per_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_registration_rate'
                            ),
                            value: 'registration_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_registration'
                            ),
                            value: 'total_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_registration'
                            ),
                            value: 'cost_per_total_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_purchase'),
                            value: 'purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_purchase'
                            ),
                            value: 'cost_per_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_purchase_rate'
                            ),
                            value: 'purchase_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_purchase'
                            ),
                            value: 'total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_purchase'
                            ),
                            value: 'cost_per_total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_purchase'
                            ),
                            value: 'value_per_total_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_purchase_value'
                            ),
                            value: 'total_purchase_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_active_pay_roas'
                            ),
                            value: 'total_active_pay_roas'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_app_event_add_to_cart'
                            ),
                            value: 'app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_app_event_add_to_cart'
                            ),
                            value: 'cost_per_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_app_event_add_to_cart_rate'
                            ),
                            value: 'app_event_add_to_cart_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_app_event_add_to_cart'
                            ),
                            value: 'total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_app_event_add_to_cart'
                            ),
                            value: 'cost_per_total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_app_event_add_to_cart'
                            ),
                            value: 'value_per_total_app_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_app_event_add_to_cart_value'
                            ),
                            value: 'total_app_event_add_to_cart_value'
                        },
                        {
                            label: this.$t('page.campaign.column_checkout'),
                            value: 'checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_checkout'
                            ),
                            value: 'cost_per_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_checkout_rate'
                            ),
                            value: 'checkout_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_checkout'
                            ),
                            value: 'total_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_checkout'
                            ),
                            value: 'cost_per_total_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_checkout'
                            ),
                            value: 'value_per_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_checkout_value'
                            ),
                            value: 'total_checkout_value'
                        },
                        {
                            label: this.$t('page.campaign.column_view_content'),
                            value: 'view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_view_content'
                            ),
                            value: 'cost_per_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_view_content_rate'
                            ),
                            value: 'view_content_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_view_content'
                            ),
                            value: 'total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_view_content'
                            ),
                            value: 'cost_per_total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_view_content'
                            ),
                            value: 'value_per_total_view_content'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_view_content_value'
                            ),
                            value: 'total_view_content_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_next_day_open'
                            ),
                            value: 'next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_next_day_open'
                            ),
                            value: 'cost_per_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_next_day_open_rate'
                            ),
                            value: 'next_day_open_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_next_day_open'
                            ),
                            value: 'total_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_next_day_open'
                            ),
                            value: 'cost_per_total_next_day_open'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_payment_info'
                            ),
                            value: 'add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_add_payment_info'
                            ),
                            value: 'cost_per_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_payment_info_rate'
                            ),
                            value: 'add_payment_info_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_payment_info'
                            ),
                            value: 'total_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_total_add_payment_info'
                            ),
                            value: 'cost_total_add_payment_info'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_to_wishlist'
                            ),
                            value: 'add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_add_to_wishlist'
                            ),
                            value: 'cost_per_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_to_wishlist_rate'
                            ),
                            value: 'add_to_wishlist_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_to_wishlist'
                            ),
                            value: 'total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_add_to_wishlist'
                            ),
                            value: 'cost_per_total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_add_to_wishlist'
                            ),
                            value: 'value_per_total_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_to_wishlist_value'
                            ),
                            value: 'total_add_to_wishlist_value'
                        },
                        {
                            label: this.$t('page.campaign.column_launch_app'),
                            value: 'launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_launch_app'
                            ),
                            value: 'cost_per_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_launch_app_rate'
                            ),
                            value: 'launch_app_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_launch_app'
                            ),
                            value: 'total_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_launch_app'
                            ),
                            value: 'cost_per_total_launch_app'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_tutorial'
                            ),
                            value: 'complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_complete_tutorial'
                            ),
                            value: 'cost_per_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_tutorial_rate'
                            ),
                            value: 'complete_tutorial_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_complete_tutorial'
                            ),
                            value: 'total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_complete_tutorial'
                            ),
                            value: 'cost_per_total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_complete_tutorial'
                            ),
                            value: 'value_per_total_complete_tutorial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_complete_tutorial_value'
                            ),
                            value: 'total_complete_tutorial_value'
                        },
                        {
                            label: this.$t('page.campaign.column_create_group'),
                            value: 'create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_create_group'
                            ),
                            value: 'cost_per_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_group_rate'
                            ),
                            value: 'create_group_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_group'
                            ),
                            value: 'total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_create_group'
                            ),
                            value: 'cost_per_total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_create_group'
                            ),
                            value: 'value_per_total_create_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_group_value'
                            ),
                            value: 'total_create_group_value'
                        },
                        {
                            label: this.$t('page.campaign.column_join_group'),
                            value: 'join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_join_group'
                            ),
                            value: 'cost_per_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_join_group_rate'
                            ),
                            value: 'join_group_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_join_group'
                            ),
                            value: 'total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_join_group'
                            ),
                            value: 'cost_per_total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_join_group'
                            ),
                            value: 'value_per_total_join_group'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_join_group_value'
                            ),
                            value: 'total_join_group_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_gamerole'
                            ),
                            value: 'create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_create_gamerole'
                            ),
                            value: 'cost_per_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_create_gamerole_rate'
                            ),
                            value: 'create_gamerole_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_gamerole'
                            ),
                            value: 'total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_create_gamerole'
                            ),
                            value: 'cost_per_total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_create_gamerole'
                            ),
                            value: 'value_per_total_create_gamerole'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_create_gamerole_value'
                            ),
                            value: 'total_create_gamerole_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_spend_credits'
                            ),
                            value: 'spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_spend_credits'
                            ),
                            value: 'cost_per_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_spend_credits_rate'
                            ),
                            value: 'spend_credits_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_spend_credits'
                            ),
                            value: 'total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_spend_credits'
                            ),
                            value: 'cost_per_total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_spend_credits'
                            ),
                            value: 'value_per_total_spend_credits'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_spend_credits_value'
                            ),
                            value: 'total_spend_credits_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_achieve_level'
                            ),
                            value: 'achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_achieve_level'
                            ),
                            value: 'cost_per_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_achieve_level_rate'
                            ),
                            value: 'achieve_level_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_achieve_level'
                            ),
                            value: 'total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_achieve_level'
                            ),
                            value: 'cost_per_total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_achieve_level'
                            ),
                            value: 'value_per_total_achieve_level'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_achieve_level_value'
                            ),
                            value: 'total_achieve_level_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unlock_achievement'
                            ),
                            value: 'unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_unlock_achievement'
                            ),
                            value: 'cost_per_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unlock_achievement_rate'
                            ),
                            value: 'unlock_achievement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_unlock_achievement'
                            ),
                            value: 'total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_unlock_achievement'
                            ),
                            value: 'cost_per_total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_unlock_achievement'
                            ),
                            value: 'value_per_total_unlock_achievement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_unlock_achievement_value'
                            ),
                            value: 'total_unlock_achievement_value'
                        },
                        {
                            label: this.$t('page.campaign.column_sales_lead'),
                            value: 'sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_sales_lead'
                            ),
                            value: 'cost_per_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_sales_lead_rate'
                            ),
                            value: 'sales_lead_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_sales_lead'
                            ),
                            value: 'total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_sales_lead'
                            ),
                            value: 'cost_per_total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_sales_lead'
                            ),
                            value: 'value_per_total_sales_lead'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_sales_lead_value'
                            ),
                            value: 'total_sales_lead_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_click'
                            ),
                            value: 'in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_in_app_ad_click'
                            ),
                            value: 'cost_per_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_click_rate'
                            ),
                            value: 'in_app_ad_click_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_click'
                            ),
                            value: 'total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_in_app_ad_click'
                            ),
                            value: 'cost_per_total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_in_app_ad_click'
                            ),
                            value: 'value_per_total_in_app_ad_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_click_value'
                            ),
                            value: 'total_in_app_ad_click_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_impr'
                            ),
                            value: 'in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_in_app_ad_impr'
                            ),
                            value: 'cost_per_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_in_app_ad_impr_rate'
                            ),
                            value: 'in_app_ad_impr_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_impr'
                            ),
                            value: 'total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_in_app_ad_impr'
                            ),
                            value: 'cost_per_total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_in_app_ad_impr'
                            ),
                            value: 'value_per_total_in_app_ad_impr'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_in_app_ad_impr_value'
                            ),
                            value: 'total_in_app_ad_impr_value'
                        },
                        {
                            label: this.$t('page.campaign.column_loan_apply'),
                            value: 'loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_apply'
                            ),
                            value: 'cost_per_loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_apply_rate'
                            ),
                            value: 'loan_apply_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_apply'
                            ),
                            value: 'total_loan_apply'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_apply'
                            ),
                            value: 'cost_per_total_loan_apply'
                        },
                        {
                            label: this.$t('page.campaign.column_loan_credit'),
                            value: 'loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_credit'
                            ),
                            value: 'cost_per_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_credit_rate'
                            ),
                            value: 'loan_credit_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_credit'
                            ),
                            value: 'total_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_credit'
                            ),
                            value: 'cost_per_total_loan_credit'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_disbursement'
                            ),
                            value: 'loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_loan_disbursement'
                            ),
                            value: 'cost_per_loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_loan_disbursement_rate'
                            ),
                            value: 'loan_disbursement_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_loan_disbursement'
                            ),
                            value: 'total_loan_disbursement'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_loan_disbursement'
                            ),
                            value: 'cost_per_total_loan_disbursement'
                        },
                        {
                            label: this.$t('page.campaign.column_login'),
                            value: 'login'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_login'
                            ),
                            value: 'cost_per_login'
                        },
                        {
                            label: this.$t('page.campaign.column_login_rate'),
                            value: 'login_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_total_login'),
                            value: 'total_login'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_login'
                            ),
                            value: 'cost_per_total_login'
                        },
                        {
                            label: this.$t('page.campaign.column_ratings'),
                            value: 'ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_ratings'
                            ),
                            value: 'cost_per_ratings'
                        },
                        {
                            label: this.$t('page.campaign.column_ratings_rate'),
                            value: 'ratings_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_ratings'
                            ),
                            value: 'total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_ratings'
                            ),
                            value: 'cost_per_total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_ratings'
                            ),
                            value: 'value_per_total_ratings'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_ratings_value'
                            ),
                            value: 'total_ratings_value'
                        },
                        {
                            label: this.$t('page.campaign.column_search'),
                            value: 'search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_search'
                            ),
                            value: 'cost_per_search'
                        },
                        {
                            label: this.$t('page.campaign.column_search_rate'),
                            value: 'search_rate'
                        },
                        {
                            label: this.$t('page.campaign.column_total_search'),
                            value: 'total_search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_search'
                            ),
                            value: 'cost_per_total_search'
                        },
                        {
                            label: this.$t('page.campaign.column_start_trial'),
                            value: 'start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_start_trial'
                            ),
                            value: 'cost_per_start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_start_trial_rate'
                            ),
                            value: 'start_trial_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_start_trial'
                            ),
                            value: 'total_start_trial'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_start_trial'
                            ),
                            value: 'cost_per_total_start_trial'
                        },
                        {
                            label: this.$t('page.campaign.column_subscribe'),
                            value: 'subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_subscribe'
                            ),
                            value: 'cost_per_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_subscribe_rate'
                            ),
                            value: 'subscribe_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_subscribe'
                            ),
                            value: 'total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_total_subscribe'
                            ),
                            value: 'cost_per_total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_total_subscribe'
                            ),
                            value: 'value_per_total_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_subscribe_value'
                            ),
                            value: 'total_subscribe_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_unique_custom_app_events'
                            ),
                            value: 'unique_custom_app_events'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_unique_custom_app_event'
                            ),
                            value: 'cost_per_unique_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_event_rate'
                            ),
                            value: 'custom_app_event_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_events'
                            ),
                            value: 'custom_app_events'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_custom_app_event'
                            ),
                            value: 'cost_per_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_custom_app_event'
                            ),
                            value: 'value_per_custom_app_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_app_events_value'
                            ),
                            value: 'custom_app_events_value'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_on_site_event'),
                    value: 'ON_SITE_EVENT',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_shopping_roas'
                            ),
                            value: 'onsite_shopping_roas'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_shopping'
                            ),
                            value: 'onsite_shopping'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_shopping'
                            ),
                            value: 'cost_per_onsite_shopping'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_shopping_rate'
                            ),
                            value: 'onsite_shopping_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_shopping'
                            ),
                            value: 'value_per_onsite_shopping'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_shopping_value'
                            ),
                            value: 'total_onsite_shopping_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_initiate_checkout_count'
                            ),
                            value: 'onsite_initiate_checkout_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_initiate_checkout_count'
                            ),
                            value: 'cost_per_onsite_initiate_checkout_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_initiate_checkout_count_rate'
                            ),
                            value: 'onsite_initiate_checkout_count_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_initiate_checkout_count'
                            ),
                            value: 'value_per_onsite_initiate_checkout_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_initiate_checkout_count_value'
                            ),
                            value: 'total_onsite_initiate_checkout_count_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_on_web_detail'
                            ),
                            value: 'onsite_on_web_detail'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_on_web_detail'
                            ),
                            value: 'cost_per_onsite_on_web_detail'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_on_web_detail_rate'
                            ),
                            value: 'onsite_on_web_detail_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_on_web_detail'
                            ),
                            value: 'value_per_onsite_on_web_detail'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_on_web_detail_value'
                            ),
                            value: 'total_onsite_on_web_detail_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_add_to_wishlist'
                            ),
                            value: 'onsite_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_add_to_wishlist'
                            ),
                            value: 'cost_per_onsite_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_add_to_wishlist_rate'
                            ),
                            value: 'onsite_add_to_wishlist_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_add_to_wishlist'
                            ),
                            value: 'value_per_onsite_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_add_to_wishlist_value'
                            ),
                            value: 'total_onsite_add_to_wishlist_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_add_billing'
                            ),
                            value: 'onsite_add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_add_billing'
                            ),
                            value: 'cost_per_onsite_add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_add_billing_rate'
                            ),
                            value: 'onsite_add_billing_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_add_billing'
                            ),
                            value: 'value_per_onsite_add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_add_billing_value'
                            ),
                            value: 'total_onsite_add_billing_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_on_web_cart'
                            ),
                            value: 'onsite_on_web_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_on_web_cart'
                            ),
                            value: 'cost_per_onsite_on_web_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_on_web_cart_rate'
                            ),
                            value: 'onsite_on_web_cart_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_on_web_cart'
                            ),
                            value: 'value_per_onsite_on_web_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_on_web_cart_value'
                            ),
                            value: 'total_onsite_on_web_cart_value'
                        },
                        {
                            label: this.$t('page.campaign.column_onsite_form'),
                            value: 'onsite_form'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_form'
                            ),
                            value: 'cost_per_onsite_form'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_form_rate'
                            ),
                            value: 'onsite_form_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_onsite_form'
                            ),
                            value: 'value_per_onsite_form'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_onsite_form_value'
                            ),
                            value: 'total_onsite_form_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_download_start'
                            ),
                            value: 'onsite_download_start'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_onsite_download_start'
                            ),
                            value: 'cost_per_onsite_download_start'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_onsite_download_start_rate'
                            ),
                            value: 'onsite_download_start_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_view_count'
                            ),
                            value: 'ix_page_view_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_ix_page_view_count'
                            ),
                            value: 'cost_per_ix_page_view_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_page_view_count_rate'
                            ),
                            value: 'ix_page_view_count_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_button_click_count'
                            ),
                            value: 'ix_button_click_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_ix_button_click_count'
                            ),
                            value: 'cost_per_ix_button_click_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_button_click_count_rate'
                            ),
                            value: 'ix_button_click_count_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_product_click_count'
                            ),
                            value: 'ix_product_click_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_ix_product_click_count'
                            ),
                            value: 'cost_per_ix_product_click_count'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_ix_product_click_count_rate'
                            ),
                            value: 'ix_product_click_count_rate'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_page_event'),
                    value: 'PAGE_EVENT',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_complete_payment_roas'
                            ),
                            value: 'complete_payment_roas'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_payment'
                            ),
                            value: 'complete_payment'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_complete_payment'
                            ),
                            value: 'cost_per_complete_payment'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_complete_payment_rate'
                            ),
                            value: 'complete_payment_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_complete_payment'
                            ),
                            value: 'value_per_complete_payment'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_complete_payment_rate'
                            ),
                            value: 'total_complete_payment_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_landing_page_view'
                            ),
                            value: 'total_landing_page_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_landing_page_view'
                            ),
                            value: 'cost_per_landing_page_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_landing_page_view_rate'
                            ),
                            value: 'landing_page_view_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_pageview'
                            ),
                            value: 'total_pageview'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_pageview'
                            ),
                            value: 'cost_per_pageview'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_pageview_rate'
                            ),
                            value: 'pageview_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_avg_value_per_pageview'
                            ),
                            value: 'avg_value_per_pageview'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_value_per_pageview'
                            ),
                            value: 'total_value_per_pageview'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_page_browse_view'
                            ),
                            value: 'page_browse_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_page_browse_view'
                            ),
                            value: 'cost_per_page_browse_view'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_page_browse_view_rate'
                            ),
                            value: 'page_browse_view_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_page_browse_view_value'
                            ),
                            value: 'total_page_browse_view_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_page_browse_view'
                            ),
                            value: 'value_per_page_browse_view'
                        },
                        {
                            label: this.$t('page.campaign.column_button_click'),
                            value: 'button_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_button_click'
                            ),
                            value: 'cost_per_button_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_button_click_rate'
                            ),
                            value: 'button_click_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_button_click'
                            ),
                            value: 'value_per_button_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_button_click_value'
                            ),
                            value: 'total_button_click_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_online_consult'
                            ),
                            value: 'online_consult'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_online_consult'
                            ),
                            value: 'cost_per_online_consult'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_online_consult_rate'
                            ),
                            value: 'online_consult_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_online_consult'
                            ),
                            value: 'value_per_online_consult'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_online_consult_value'
                            ),
                            value: 'total_online_consult_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_user_registration'
                            ),
                            value: 'user_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_user_registration'
                            ),
                            value: 'cost_per_user_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_user_registration_rate'
                            ),
                            value: 'user_registration_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_user_registration'
                            ),
                            value: 'value_per_user_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_user_registration_value'
                            ),
                            value: 'total_user_registration_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_product_details_page_browse'
                            ),
                            value: 'product_details_page_browse'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_product_details_page_browse'
                            ),
                            value: 'cost_per_product_details_page_browse'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_product_details_page_browse_rate'
                            ),
                            value: 'product_details_page_browse_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_product_details_page_browse'
                            ),
                            value: 'value_per_product_details_page_browse'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_product_details_page_browse_value'
                            ),
                            value: 'total_product_details_page_browse_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_web_event_add_to_cart'
                            ),
                            value: 'web_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_web_event_add_to_cart'
                            ),
                            value: 'cost_per_web_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_web_event_add_to_cart_rate'
                            ),
                            value: 'web_event_add_to_cart_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_web_event_add_to_cart'
                            ),
                            value: 'value_per_web_event_add_to_cart'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_web_event_add_to_cart_value'
                            ),
                            value: 'total_web_event_add_to_cart_value'
                        },
                        {
                            label: this.$t('page.campaign.column_on_web_order'),
                            value: 'on_web_order'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_on_web_order'
                            ),
                            value: 'cost_per_on_web_order'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_on_web_order_rate'
                            ),
                            value: 'on_web_order_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_on_web_order'
                            ),
                            value: 'value_per_on_web_order'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_on_web_order_value'
                            ),
                            value: 'total_on_web_order_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_initiate_checkout'
                            ),
                            value: 'initiate_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_initiate_checkout'
                            ),
                            value: 'cost_per_initiate_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_initiate_checkout_rate'
                            ),
                            value: 'initiate_checkout_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_initiate_checkout'
                            ),
                            value: 'value_per_initiate_checkout'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_initiate_checkout_value'
                            ),
                            value: 'total_initiate_checkout_value'
                        },
                        {
                            label: this.$t('page.campaign.column_add_billing'),
                            value: 'add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_add_billing'
                            ),
                            value: 'cost_per_add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_add_billing_rate'
                            ),
                            value: 'add_billing_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_add_billing'
                            ),
                            value: 'value_per_add_billing'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_add_billing_value'
                            ),
                            value: 'total_add_billing_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_page_event_search'
                            ),
                            value: 'page_event_search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_page_event_search'
                            ),
                            value: 'cost_per_page_event_search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_page_event_search_rate'
                            ),
                            value: 'page_event_search_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_page_event_search'
                            ),
                            value: 'value_per_page_event_search'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_page_event_search_value'
                            ),
                            value: 'total_page_event_search_value'
                        },
                        {
                            label: this.$t('page.campaign.column_form'),
                            value: 'form'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_form'
                            ),
                            value: 'cost_per_form'
                        },
                        {
                            label: this.$t('page.campaign.column_form_rate'),
                            value: 'form_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_form'
                            ),
                            value: 'value_per_form'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_form_value'
                            ),
                            value: 'total_form_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_download_start'
                            ),
                            value: 'download_start'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_download_start'
                            ),
                            value: 'cost_per_download_start'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_download_start_rate'
                            ),
                            value: 'download_start_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_download_start'
                            ),
                            value: 'value_per_download_start'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_download_start_value'
                            ),
                            value: 'total_download_start_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_on_web_add_to_wishlist'
                            ),
                            value: 'on_web_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_on_web_add_to_wishlist'
                            ),
                            value: 'cost_per_on_web_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_on_web_add_to_wishlist_per_click'
                            ),
                            value: 'on_web_add_to_wishlist_per_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_on_web_add_to_wishlist'
                            ),
                            value: 'value_per_on_web_add_to_wishlist'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_on_web_add_to_wishlist_value'
                            ),
                            value: 'total_on_web_add_to_wishlist_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_on_web_subscribe'
                            ),
                            value: 'on_web_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_on_web_subscribe'
                            ),
                            value: 'cost_per_on_web_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_on_web_subscribe_per_click'
                            ),
                            value: 'on_web_subscribe_per_click'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_on_web_subscribe'
                            ),
                            value: 'value_per_on_web_subscribe'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_total_on_web_subscribe_value'
                            ),
                            value: 'total_on_web_subscribe_value'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_page_events'
                            ),
                            value: 'custom_page_events'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_custom_page_event'
                            ),
                            value: 'cost_per_custom_page_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_page_event_rate'
                            ),
                            value: 'custom_page_event_rate'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_value_per_custom_page_event'
                            ),
                            value: 'value_per_custom_page_event'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_custom_page_events_value'
                            ),
                            value: 'custom_page_events_value'
                        }
                    ]
                },
                {
                    label: this.$t('page.campaign.custom_column_attribution'),
                    value: 'ATTRIBUTION',
                    child_options: [
                        {
                            label: this.$t(
                                'page.campaign.column_vta_app_install'
                            ),
                            value: 'vta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_app_install'
                            ),
                            value: 'cost_per_vta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_vta_conversion'
                            ),
                            value: 'vta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_conversion'
                            ),
                            value: 'cost_per_vta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_vta_registration'
                            ),
                            value: 'vta_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_registration'
                            ),
                            value: 'cost_per_vta_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_vta_purchase'),
                            value: 'vta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_vta_purchase'
                            ),
                            value: 'cost_per_vta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_app_install'
                            ),
                            value: 'cta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_app_install'
                            ),
                            value: 'cost_per_cta_app_install'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_conversion'
                            ),
                            value: 'cta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_conversion'
                            ),
                            value: 'cost_per_cta_conversion'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cta_registration'
                            ),
                            value: 'cta_registration'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_registration'
                            ),
                            value: 'cost_per_cta_registration'
                        },
                        {
                            label: this.$t('page.campaign.column_cta_purchase'),
                            value: 'cta_purchase'
                        },
                        {
                            label: this.$t(
                                'page.campaign.column_cost_per_cta_purchase'
                            ),
                            value: 'cost_per_cta_purchase'
                        }
                    ]
                }
                // {
                //     label: this.$t('page.campaign.custom_column_offline_event'),
                //     value: 'OFFLINE_EVENT',
                //     child_options: [
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_shopping_events'
                //             ),
                //             value: 'offline_shopping_events'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_offline_shopping_event'
                //             ),
                //             value: 'cost_per_offline_shopping_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_shopping_event_rate'
                //             ),
                //             value: 'offline_shopping_event_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_offline_shopping_event'
                //             ),
                //             value: 'value_per_offline_shopping_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_shopping_events_value'
                //             ),
                //             value: 'offline_shopping_events_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_shopping_events_roas'
                //             ),
                //             value: 'offline_shopping_events_roas'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_contact_events'
                //             ),
                //             value: 'offline_contact_events'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_offline_contact_event'
                //             ),
                //             value: 'cost_per_offline_contact_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_contact_event_rate'
                //             ),
                //             value: 'offline_contact_event_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_offline_contact_event'
                //             ),
                //             value: 'value_per_offline_contact_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_contact_events_value'
                //             ),
                //             value: 'offline_contact_events_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_subscribe_events'
                //             ),
                //             value: 'offline_subscribe_events'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_offline_subscribe_event'
                //             ),
                //             value: 'cost_per_offline_subscribe_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_subscribe_event_rate'
                //             ),
                //             value: 'offline_subscribe_event_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_offline_subscribe_event'
                //             ),
                //             value: 'value_per_offline_subscribe_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_subscribe_events_value'
                //             ),
                //             value: 'offline_subscribe_events_value'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_form_events'
                //             ),
                //             value: 'offline_form_events'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_cost_per_offline_form_event'
                //             ),
                //             value: 'cost_per_offline_form_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_form_event_rate'
                //             ),
                //             value: 'offline_form_event_rate'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_value_per_offline_form_event'
                //             ),
                //             value: 'value_per_offline_form_event'
                //         },
                //         {
                //             label: this.$t(
                //                 'page.campaign.column_offline_form_events_value'
                //             ),
                //             value: 'offline_form_events_value'
                //         }
                //     ]
                // }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        column_values_in_line: {
            get() {
                return this.column_values
            },
            set(value) {
                this.$emit('update:column_values', value)
            }
        },

        list_value() {
            const result = []
            if (this.selected_option) {
                this.selected_option.child_options.forEach((item) => {
                    result.push(item.value)
                })
            }

            return result
        },

        length_selected_columns() {
            let length = 0
            if (this.column_values_in_line.length) {
                this.column_values_in_line.forEach((item) => {
                    length += item.child_values.length
                })
            }

            return length
        },

        new_column_values() {
            const arr = []

            if (this.column_values_in_line.length) {
                this.column_values_in_line.forEach((item) => {
                    const temp_column = this.performance_column_options.find(
                        (it) => it.value === item.value
                    )

                    item.child_values.forEach((it) => {
                        const value = temp_column.child_options.find(
                            (i) => i.value === it
                        )
                        arr.push({
                            ...value,
                            value_parent: item.value
                        })
                    })
                })
            }
            return arr
        }
    },

    watch: {
        column_values_in_line() {
            this.performance_column_values = this.column_values_in_line

            const performance_column = this.performance_column_values.find(
                (item) => item.value === this.selected_option.value
            )

            this.checked_values = performance_column
                ? performance_column.child_values
                : []

            this.handleCheckedChange(this.checked_values)
        },

        selected_option() {
            const performance_column = this.performance_column_values.find(
                (item) => item.value === this.selected_option.value
            )

            this.checked_values = performance_column
                ? performance_column.child_values
                : []

            this.handleCheckedChange(this.checked_values)
        }
    },

    mounted() {
        this.selected_option = this.performance_column_options[0]
    },

    methods: {
        handleCheckAllChange(value) {
            this.checked_values = value
                ? [...this.checked_values, ...this.list_value]
                : []
            this.is_indeterminate = false

            const performance_column = this.performance_column_values.find(
                (item) => item.value === this.selected_option.value
            )

            if (performance_column) {
                this.performance_column_values =
                    this.performance_column_values.map((item) => {
                        if (item.value === this.selected_option.value) {
                            return {
                                value: this.selected_option.value,
                                child_values: this.checked_values
                            }
                        }

                        return item
                    })
            } else {
                this.performance_column_values = [
                    ...this.performance_column_values,
                    {
                        value: this.selected_option.value,
                        child_values: this.checked_values
                    }
                ]
            }
        },

        handleCheckedChange(value) {
            const checkedCount = value.length
            this.check_all = checkedCount === this.list_value.length
            this.is_indeterminate =
                checkedCount > 0 && checkedCount < this.list_value.length
            const performance_column = this.performance_column_values.find(
                (item) => item.value === this.selected_option.value
            )

            if (performance_column) {
                this.performance_column_values =
                    this.performance_column_values.map((item) => {
                        if (item.value === this.selected_option.value) {
                            return {
                                value: this.selected_option.value,
                                child_values: this.checked_values
                            }
                        }

                        return item
                    })
            } else {
                this.performance_column_values = [
                    ...this.performance_column_values,
                    {
                        value: this.selected_option.value,
                        child_values: value
                    }
                ]
            }
        },

        handleCustomColumn() {
            const temp = this.performance_column_values
                .filter((item) => item.child_values.length)
                .map((item) => ({
                    ...item,
                    child_values: [...new Set(item.child_values)]
                }))

            if (!temp.length) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_store_id')
                )
                return
            }

            this.column_values_in_line = temp

            this.visible_in_line = false
        }
    }
}
</script>

<style scoped>
.ghost {
    opacity: 0.8;
    background: #c8ebfb;
}
</style>
