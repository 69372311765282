import request from './_config'

const path = `creative_tool`

export function getPreviewAd(data) {
    return request({
        url: `${path}/preview_ads`,
        method: 'POST',
        data
    })
}
