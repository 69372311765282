<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="560px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
    >
        <div>
            <img
                :src="require('@/assets/images/create_new_campaign.png')"
                class="w-[320px] h-[180px] mx-auto"
                alt=""
            />
            <p
                class="text-lg leading-[1.7rem] text-center font-semibold mt-6 break-word"
            >
                {{ $t('page.campaign.desc_create_new_campaign') }}
            </p>
            <div class="mt-8 text-center">
                <el-button
                    type="danger"
                    size="medium"
                    class="uppercase"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${p__route.create_campaign}`
                        })
                    "
                >
                    {{ $t('page.campaign.create_other_campaign') }}
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible'],

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    }
}
</script>
