<template>
    <section
        v-loading="m__loading"
        class="component-box !rounded-br-none relative"
    >
        <div class="flex space-x-3 overflow-x-auto pb-2">
            <div
                v-for="(item, index) in p__trend_basic_chart_options"
                :key="index"
                class="w-[160px] flex-shrink-0 bg-bg rounded-lg flex overflow-hidden cursor-pointer"
                :class="[
                    hasSelectedTrend(item.value)
                        ? ''
                        : 'opacity-70 hover:opacity-800 group'
                ]"
                @click="handleChangeSelectedTrend(item.value)"
            >
                <div
                    class="w-1 h-full"
                    :style="{
                        backgroundColor: getColorByTrend(item.value)
                    }"
                    :class="[
                        hasSelectedTrend(item.value)
                            ? ''
                            : 'invisible group-hover:visible'
                    ]"
                ></div>
                <div class="ml-4 py-2">
                    <div class="flex space-x-1 items-center">
                        <p class="text-xs font-semibold">
                            {{ p__getNameByTrend(item.value) }}
                        </p>
                        <el-tooltip effect="dark" placement="top">
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item.value}`
                                        )
                                    "
                                ></p>
                            </div>

                            <i class="el-icon-info text-desc-text text-sm"></i>
                        </el-tooltip>
                    </div>

                    <p
                        :style="{ color: getColorByTrend(item.value) }"
                        class="text-base font-semibold mt-1"
                    >
                        {{
                            m__formatterColumn(
                                total_data_overview
                                    ? total_data_overview[item.value]
                                    : 0,
                                item.value,
                                currency
                            )
                        }}
                    </p>
                    <span
                        v-if="switch_compare"
                        class="text-xs font-semibold text-desc-text"
                    >
                        {{
                            m__formatterColumn(
                                compare_total_data_overview
                                    ? compare_total_data_overview[item.value]
                                    : 0,
                                item.value,
                                currency
                            )
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div v-show="show_chart" class="mt-4">
            <div class="relative">
                <Chart
                    :data_list="data_list"
                    :selected_trends="selected_trends"
                    :format_date="$t('common.format_date')"
                ></Chart>

                <p
                    v-if="switch_compare"
                    class="text-center mt-2 text-sm italic"
                >
                    {{
                        ranger_date[0]
                            | f__format_moment($t('common.format_date'))
                    }}
                    -
                    {{
                        ranger_date[1]
                            | f__format_moment($t('common.format_date'))
                    }}
                </p>

                <div
                    v-if="!m__loading && !data_list.length"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <div class="util-flex-center flex-col">
                        <img
                            class="w-[140px] h-[140px]"
                            :src="require('@/assets/images/no_data.png')"
                        />
                        <p class="mt-2 text-desc-text text-sm">
                            {{ $t('common.no_data') }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-show="switch_compare" class="relative mt-4">
                <Chart
                    :data_list="compare_data_list"
                    :selected_trends="selected_trends"
                    :format_date="$t('common.format_date')"
                ></Chart>

                <p
                    v-if="switch_compare"
                    class="text-center mt-2 text-sm italic"
                >
                    {{
                        compare_ranger_date[0]
                            | f__format_moment($t('common.format_date'))
                    }}
                    -
                    {{
                        compare_ranger_date[1]
                            | f__format_moment($t('common.format_date'))
                    }}
                </p>

                <div
                    v-if="!m__loading && !compare_data_list.length"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <div class="util-flex-center flex-col">
                        <img
                            class="w-[140px] h-[140px]"
                            :src="require('@/assets/images/no_data.png')"
                        />
                        <p class="mt-2 text-desc-text text-sm">
                            {{ $t('common.no_data') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex">
            <el-button
                icon="el-icon-s-marketing"
                size="small"
                class="ml-auto mt-2"
                @click="$router.push(query_detail_ads_account)"
            >
                {{ $t('common.detailed_analysis') }}
            </el-button>
        </div>

        <div
            class="absolute -bottom-9 right-0 h-9 w-12 bg-white rounded-b-lg z-40 util-flex-center cursor-pointer group"
            @click="show_chart = !show_chart"
        >
            <i
                v-if="show_chart"
                class="el-icon-top group-hover:scale-125 transition duration-300"
            ></i>
            <i
                v-else
                class="el-icon-bottom group-hover:scale-125 transition duration-300"
            ></i>
        </div>
    </section>
</template>

<script>
import Chart from './chart'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import moment from 'moment'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'

export default {
    components: {
        Chart
    },

    mixins: [campaignMixin],

    props: [
        'ranger_date',
        'advertiser_id',
        'tiktok_account_id',
        'filtering',
        'switch_compare',
        'compare_ranger_date'
    ],

    data() {
        return {
            show_chart: true,
            current_currency: 'USD',
            total_data_overview: null,
            compare_total_data_overview: null,
            data_list: [],
            compare_data_list: [],
            selected_trends: [],
            value: ''
        }
    },

    computed: {
        m__selected_tiktok_business() {
            return this.$store.getters.tiktokBusiness
                .s__selected_tiktok_business
        },
        query_detail_ads_account() {
            let result = `/?advertiser_id=${this.advertiser_id}`

            if (this.ranger_date) {
                result += `&st=${this.ranger_date[0]}`
                result += `&et=${this.ranger_date[1]}`
            }

            if (this.switch_compare) {
                result += `&cst=${this.compare_ranger_date[0]}`
                result += `&cet=${this.compare_ranger_date[1]}`
            }

            return result
        }
    },

    watch: {
        advertiser_id() {
            setTimeout(() => {
                this.fetchDailyData()

                this.fetchCompareDailyData()
            }, 1111)
        },

        // filtering() {
        //     this.fetchOverviewReport()
        // },

        ranger_date() {
            this.fetchDailyData()
        },

        compare_ranger_date() {
            this.fetchCompareDailyData()
        },

        show_chart() {
            localStorage.setItem(
                `storage_show_chart`,
                JSON.stringify(this.show_chart)
            )
        }
    },

    mounted() {
        this.getSelectedTrendsFromStorage()

        this.getShowChartFromStorage()
    },

    methods: {
        getSelectedTrendsFromStorage() {
            const temp = JSON.parse(
                localStorage.getItem(`storage_selected_trends`)
            )

            if (temp && temp.length) {
                this.selected_trends = temp
            } else {
                this.selected_trends = this.p__trend_basic_chart_options
                    .slice(0, 4)
                    .map((trend) => trend.value)
            }
        },

        getShowChartFromStorage() {
            const temp = JSON.parse(localStorage.getItem(`storage_show_chart`))

            if (temp !== null) {
                this.show_chart = temp
            }
        },

        hasSelectedTrend(value) {
            const trend = this.selected_trends.find((item) => item === value)

            if (trend) {
                return true
            }
            return false
        },

        getColorByTrend(value) {
            const trend = this.p__trend_basic_chart_options.find(
                (item) => item.value === value
            )

            if (trend) {
                return trend.color
            }

            return 'red'
        },

        handleChangeSelectedTrend(value) {
            const index = this.selected_trends.findIndex(
                (item) => item === value
            )
            if (index > -1) {
                if (this.selected_trends.length === 1) {
                    return
                }
                this.selected_trends.splice(index, 1)
            } else {
                this.selected_trends = [...this.selected_trends, value]
            }

            localStorage.setItem(
                `storage_selected_trends`,
                JSON.stringify(this.selected_trends)
            )
        },

        async fetchDailyData() {
            const response = await this.fetchSyncReport(this.ranger_date)
            response.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )

            this.data_list = response

            const result = this.p__calculateMetrics(
                response.map((item) => item.metrics)
            )

            this.total_data_overview = result
        },

        async fetchCompareDailyData() {
            const response = await this.fetchSyncReport(
                this.compare_ranger_date
            )
            response.sort((a, b) =>
                moment(a.dimensions.stat_time_day).diff(
                    moment(b.dimensions.stat_time_day)
                )
            )
            this.compare_data_list = response

            const result = this.p__calculateMetrics(
                response.map((item) => item.metrics)
            )

            this.compare_total_data_overview = result
        },

        async fetchSyncReport(ranger_date) {
            if (!this.tiktok_account_id) {
                return []
            }

            const arr_30days = this.p__getArr30days(ranger_date)

            const result = []

            this.m__loading = true

            try {
                await Promise.all(
                    arr_30days.map(async (new_ranger_date) => {
                        const start_date = new_ranger_date[0]
                        const end_date = new_ranger_date[1]

                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 40,
                                report_type: 'BASIC',
                                data_level: 'AUCTION_ADVERTISER',
                                metrics: this.p__trend_basic_chart_options.map(
                                    (item) => item.value
                                ),
                                start_date,
                                end_date,
                                advertiser_id: this.advertiser_id,
                                dimensions: ['stat_time_day', 'advertiser_id']
                            }
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            result.push(...response.data.list)
                        }
                    })
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return result
        }
    }
}
</script>
