<template>
    <custom-drawer
        v-loading="m__loading"
        :size="1200"
        :wrapper_closable="false"
        :confirm_to_close="false"
        :destroy_on_close="true"
        :close_on_press_escape="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div
                v-if="data_report_campaign"
                class="flex space-x-2 items-center text-sm h-full"
            >
                <el-tooltip
                    class="item"
                    effect="dark"
                    :content="data_report_campaign.campaign.campaign_name"
                    placement="top"
                >
                    <p
                        class="truncate max-w-[200px] cursor-pointer"
                        :class="[
                            type_inline === 'campaign_id' ? 'font-semibold' : ''
                        ]"
                        @click="
                            handleChooseDataReport(
                                data_report_campaign,
                                'campaign_id'
                            )
                        "
                    >
                        {{ data_report_campaign.campaign.campaign_name }}
                    </p>
                </el-tooltip>

                <span> / </span>
                <el-dropdown trigger="click">
                    <div class="flex items-center">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="
                                type_inline === 'adgroup_id' &&
                                selected_item_to_report
                                    ? selected_item_to_report.adgroup
                                          .adgroup_name
                                    : `${data_report_adgroups.length} ${$t(
                                          'common.adgroup'
                                      )}`
                            "
                            placement="top"
                        >
                            <p
                                class="el-dropdown-link truncate max-w-[200px]"
                                :class="[
                                    type_inline === 'adgroup_id'
                                        ? 'font-semibold'
                                        : ''
                                ]"
                            >
                                {{
                                    type_inline === 'adgroup_id' &&
                                    selected_item_to_report
                                        ? selected_item_to_report.adgroup
                                              .adgroup_name
                                        : `${data_report_adgroups.length} ${$t(
                                              'common.adgroup'
                                          )}`
                                }}
                            </p>
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-tooltip>

                        <i
                            v-if="data_report_adgroups.length"
                            class="el-icon-arrow-down el-icon--right"
                        ></i>
                    </div>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(item, index) in data_report_adgroups"
                            :key="index"
                            @click.native="
                                handleChooseDataReport(item, 'adgroup_id')
                            "
                        >
                            <p>{{ item.adgroup.adgroup_name }}</p>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <span> / </span>

                <el-dropdown trigger="click">
                    <div class="flex items-center">
                        <el-tooltip
                            class="item"
                            effect="dark"
                            :content="
                                type_inline === 'ad_id' &&
                                selected_item_to_report
                                    ? selected_item_to_report.ad.ad_name
                                    : `${data_report_ads.length} ${$t(
                                          'common.ad'
                                      )}`
                            "
                            placement="top"
                        >
                            <p
                                class="el-dropdown-link truncate max-w-[200px]"
                                :class="[
                                    type_inline === 'ad_id'
                                        ? 'font-semibold'
                                        : ''
                                ]"
                            >
                                {{
                                    type_inline === 'ad_id' &&
                                    selected_item_to_report
                                        ? selected_item_to_report.ad.ad_name
                                        : `${data_report_ads.length} ${$t(
                                              'common.ad'
                                          )}`
                                }}
                            </p>
                        </el-tooltip>

                        <i
                            v-if="data_report_ads.length"
                            class="el-icon-arrow-down el-icon--right"
                        ></i>
                    </div>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                            v-for="(item, index) in data_report_ads"
                            :key="index"
                            @click.native="
                                handleChooseDataReport(item, 'ad_id')
                            "
                        >
                            <p>{{ item.ad.ad_name }}</p>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </template>

        <div v-if="data_report_campaign" class="px-5 py-4">
            <div class="component-box relative mb-4">
                <div class="flex items-center">
                    <div
                        v-if="selected_item_to_report"
                        class="flex items-center justify-between flex-1 h-full"
                    >
                        <div v-if="type_inline === 'campaign_id'">
                            <p class="font-semibold text-sm">
                                {{ $t('button.view_data') }} :
                                {{
                                    selected_item_to_report.campaign
                                        .campaign_name
                                }}
                            </p>
                            <p class="text-desc-text text-xs">
                                {{ $t('common.objective_type') }} :
                                {{
                                    p__formatObjectiveType(
                                        selected_item_to_report.campaign
                                            .objective_type
                                    )
                                }}
                                - #{{
                                    selected_item_to_report.campaign.campaign_id
                                }}
                            </p>
                        </div>
                        <div v-else-if="type_inline === 'adgroup_id'">
                            <p class="font-semibold text-sm">
                                {{ $t('button.view_data') }} :
                                {{
                                    selected_item_to_report.adgroup.adgroup_name
                                }}
                            </p>
                            <p class="text-desc-text text-xs">
                                #{{
                                    selected_item_to_report.adgroup.adgroup_id
                                }}
                            </p>
                        </div>
                        <div v-else-if="type_inline === 'ad_id'">
                            <p class="font-semibold text-sm">
                                {{ $t('button.view_data') }} :
                                {{ selected_item_to_report.ad.ad_name }}
                            </p>
                            <p class="text-desc-text text-xs">
                                #{{ selected_item_to_report.ad.ad_id }}
                            </p>
                        </div>
                    </div>

                    <div class="!ml-auto"></div>

                    <el-switch
                        size="small"
                        :value="operation_status"
                        @change="handleChangeStatusCampaign()"
                    >
                    </el-switch>

                    <p class="ml-1 text-sm">
                        {{ $t('table.header.on_off') }}
                    </p>
                </div>

                <Lifetime
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="advertiser_id"
                    :currency="selected_item.currency"
                    :type="type_inline"
                    :data_id="data_id"
                    :metrics="metrics"
                ></Lifetime>

                <div class="flex mt-4 relative items-center space-x-4">
                    <el-switch
                        v-model="switch_compare"
                        class="ml-auto"
                        :active-text="$t('common.compare')"
                    >
                    </el-switch>

                    <el-date-picker
                        v-model="m__ranger_date"
                        class="!w-[360px]"
                        size="small"
                        type="daterange"
                        unlink-panels
                        popper-class="custom-el-date"
                        :start-placeholder="$t('date_picker.placeholder.start')"
                        :end-placeholder="$t('date_picker.placeholder.end')"
                        :picker-options="m__picker_options"
                        :format="$t('common.format_value_date_picker')"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>

                    <div
                        v-if="switch_compare"
                        class="absolute top-[116%] -right-2 z-[20] px-2 pb-2 pt-0 bg-white"
                    >
                        <el-date-picker
                            v-model="compare_ranger_date"
                            class="!w-[360px] ml-auto"
                            popper-class="custom-el-date"
                            size="small"
                            type="daterange"
                            unlink-panels
                            :start-placeholder="
                                $t('date_picker.placeholder.start')
                            "
                            :end-placeholder="$t('date_picker.placeholder.end')"
                            :picker-options="m__picker_options"
                            :format="$t('common.format_value_date_picker')"
                            value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                    </div>
                </div>

                <div class="left-5 absolute bottom-0">
                    <el-tabs
                        v-model="secondary_active_tab"
                        class="el-tabs-secondary no-margin"
                    >
                        <el-tab-pane
                            :label="$t('common.daily')"
                            name="first"
                        ></el-tab-pane>
                        <el-tab-pane name="second">
                            <span slot="label">
                                {{ $t('common.audience') }}
                                <el-tooltip
                                    effect="dark"
                                    :content="
                                        $t(
                                            'page.campaign.desc_view_data_audience'
                                        )
                                    "
                                    placement="top"
                                >
                                    <i class="el-icon-info text-desc-text"></i>
                                </el-tooltip>
                            </span>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>

            <div v-show="secondary_active_tab === 'first'">
                <div class="component-box">
                    <div class="flex space-x-10">
                        <div
                            v-if="
                                type_inline !== 'ad_id' &&
                                selected_item_to_report
                            "
                            class="min-w-[100px] pr-4 bg-bg rounded-lg flex overflow-hidden"
                        >
                            <div
                                class="w-1 h-full"
                                style="background-color: rgb(26, 188, 156)"
                            ></div>
                            <div class="py-2 ml-4">
                                <p class="text-xs font-semibold">
                                    {{ $t('common.budget') }}
                                </p>
                                <p
                                    class="text-sm font-semibold mt-1"
                                    style="color: rgb(26, 188, 156)"
                                >
                                    {{
                                        type_inline === 'campaign_id'
                                            ? selected_item_to_report.campaign
                                                  .budget_mode ===
                                              'BUDGET_MODE_INFINITE'
                                                ? $t(
                                                      `common.budget_mode_infinite`
                                                  )
                                                : p__renderMoneyByCurrency(
                                                      selected_item_to_report
                                                          .campaign.budget,
                                                      selected_item_to_report.currency
                                                  )
                                            : p__renderMoneyByCurrency(
                                                  selected_item_to_report
                                                      .adgroup.budget,
                                                  selected_item_to_report.currency
                                              )
                                    }}
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="selected_item_to_report"
                            class="flex space-x-8"
                        >
                            <div
                                v-for="(item, index) in data_metrics"
                                :key="index"
                            >
                                <p class="text-xs">
                                    {{ item.label }}
                                </p>
                                <p class="text-sm font-semibold mt-1">
                                    {{
                                        item.has_currency
                                            ? p__renderMoneyByCurrency(
                                                  selected_item_to_report[
                                                      item.value
                                                  ],
                                                  selected_item_to_report.currency
                                              )
                                            : p__formatNumberHasCommas(
                                                  selected_item_to_report[
                                                      item.value
                                                  ]
                                              )
                                    }}
                                    {{ item.has_percent ? '%' : '' }}
                                </p>
                                <p
                                    v-if="
                                        switch_compare &&
                                        compare_selected_item_to_report
                                    "
                                    class="text-xs text-desc-text font-semibold mt-1"
                                >
                                    {{
                                        item.has_currency
                                            ? p__renderMoneyByCurrency(
                                                  compare_selected_item_to_report[
                                                      item.value
                                                  ],
                                                  compare_selected_item_to_report.currency
                                              )
                                            : p__formatNumberHasCommas(
                                                  compare_selected_item_to_report[
                                                      item.value
                                                  ]
                                              )
                                    }}
                                    {{ item.has_percent ? '%' : '' }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="component-box mt-4">
                    <div class="mb-2 -ml-2">
                        <div class="flex flex-wrap space-x-2">
                            <div
                                v-for="(it, index) in selected_metrics"
                                :key="index"
                                class="relative mt-2 ml-2"
                            >
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    placement="top"
                                >
                                    <div slot="content" class="max-w-[360px]">
                                        <p
                                            class="text-sm"
                                            v-html="it.label"
                                        ></p>

                                        <p
                                            v-if="
                                                $t(
                                                    `page.campaign.description_column_${it.value}`
                                                ) !==
                                                `page.campaign.description_column_${it.value}`
                                            "
                                            class="mt-1"
                                            v-html="
                                                $t(
                                                    `page.campaign.description_column_${it.value}`
                                                )
                                            "
                                        ></p>
                                    </div>
                                    <div
                                        class="bg-bg flex h-auto overflow-hidden rounded-lg cursor-pointer"
                                    >
                                        <div
                                            class="min-w-1 w-1 h-auto"
                                            :style="{
                                                backgroundColor: it.color
                                            }"
                                        ></div>
                                        <div class="py-1 ml-4">
                                            <el-select
                                                :value="it.value"
                                                class="el-select-border-transparent"
                                                size="small"
                                                @change="
                                                    (val) =>
                                                        handleChangeMetric(
                                                            val,
                                                            index
                                                        )
                                                "
                                            >
                                                <el-option-group
                                                    v-for="(
                                                        group, idex
                                                    ) in p__view_data_metric_options"
                                                    :key="idex"
                                                    :label="group.label"
                                                >
                                                    <el-option
                                                        v-for="(
                                                            item, idx
                                                        ) in group.options"
                                                        :key="idx"
                                                        :label="item.label"
                                                        :value="item.value"
                                                        :disabled="
                                                            selected_metrics.some(
                                                                (ite) =>
                                                                    ite.value ===
                                                                    item.value
                                                            )
                                                        "
                                                    >
                                                    </el-option>
                                                </el-option-group>
                                            </el-select>
                                        </div>
                                    </div>
                                </el-tooltip>

                                <div
                                    v-if="selected_metrics.length > 1"
                                    class="absolute -right-1 -top-2 rounded-full bg-white util-flex-center w-5 h-5 shadow-lg border-border border-[1px] cursor-pointer"
                                    @click="handleRemoveMetric(index)"
                                >
                                    <i class="el-icon-close text-sm"></i>
                                </div>
                            </div>

                            <el-button
                                v-if="
                                    selected_metrics.length !==
                                    p__trend_chart_options_2.length
                                "
                                class="mt-2 !h-10"
                                @click="handleAddMetrics"
                            >
                                <i class="el-icon-plus"></i>
                            </el-button>
                        </div>
                    </div>

                    <el-tabs v-model="active_tab">
                        <el-tab-pane
                            :label="$t('page.campaign.overall_trends')"
                            name="first"
                        >
                            <div class="flex mb-2">
                                <el-radio-group
                                    v-model="radio_choose_stat_time"
                                    class="ml-auto"
                                    size="small"
                                >
                                    <el-radio-button label="stat_time_day">
                                        {{ $t('common.day') }}
                                    </el-radio-button>
                                    <el-radio-button
                                        :disabled="disabled_stat_time_hour"
                                        label="stat_time_hour"
                                    >
                                        <span>
                                            {{ $t('common.hour') }}
                                            <el-tooltip
                                                effect="dark"
                                                :content="
                                                    $t(
                                                        'page.campaign.exceed_duration_xxx_days',
                                                        {
                                                            xxx: exceed_duration_days
                                                        }
                                                    )
                                                "
                                                placement="top"
                                            >
                                                <i class="el-icon-info"></i>
                                            </el-tooltip>
                                        </span>
                                    </el-radio-button>
                                </el-radio-group>
                            </div>

                            <OverallTrends
                                :tiktok_account_id="tiktok_account_id"
                                :advertiser_id="advertiser_id"
                                :switch_compare="switch_compare"
                                :ranger_date="m__ranger_date"
                                :compare_ranger_date="compare_ranger_date"
                                :currency="selected_item.currency"
                                :selected_metrics="selected_metrics"
                                :stat_time="radio_choose_stat_time"
                                :type="type_inline"
                                :data_id="data_id"
                            ></OverallTrends>
                        </el-tab-pane>
                        <el-tab-pane
                            :label="$t('page.campaign.placements')"
                            name="second"
                        >
                            <Placements
                                :tiktok_account_id="tiktok_account_id"
                                :advertiser_id="advertiser_id"
                                :switch_compare="switch_compare"
                                :ranger_date="m__ranger_date"
                                :compare_ranger_date="compare_ranger_date"
                                :currency="selected_item.currency"
                                :selected_metrics="selected_metrics"
                                :type="type_inline"
                                :data_id="data_id"
                            ></Placements>
                        </el-tab-pane>
                        <el-tab-pane
                            v-if="type_inline === 'campaign_id'"
                            :label="$t('common.adgroup')"
                            name="three"
                        >
                            <Adgroup
                                :tiktok_account_id="tiktok_account_id"
                                :advertiser_id="advertiser_id"
                                :switch_compare="switch_compare"
                                :ranger_date="m__ranger_date"
                                :compare_ranger_date="compare_ranger_date"
                                :data_report_adgroups="data_report_adgroups"
                                :currency="selected_item.currency"
                                :selected_metrics="selected_metrics"
                                :data_id="data_id"
                            ></Adgroup>
                        </el-tab-pane>
                        <el-tab-pane
                            v-if="type_inline === 'adgroup_id'"
                            :label="$t('common.ad')"
                            name="four"
                        >
                            <Ad
                                :tiktok_account_id="tiktok_account_id"
                                :advertiser_id="advertiser_id"
                                :switch_compare="switch_compare"
                                :ranger_date="m__ranger_date"
                                :compare_ranger_date="compare_ranger_date"
                                :data_report_ads="data_report_ads"
                                :selected_metrics="selected_metrics"
                                :currency="selected_item.currency"
                                :data_id="data_id"
                            ></Ad>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>

            <div
                v-show="secondary_active_tab === 'second'"
                class="component-box"
            >
                <Audience
                    :tiktok_account_id="tiktok_account_id"
                    :advertiser_id="advertiser_id"
                    :switch_compare="switch_compare"
                    :ranger_date="m__ranger_date"
                    :compare_ranger_date="compare_ranger_date"
                    :currency="selected_item.currency"
                    :type="type_inline"
                    :data_id="data_id"
                    :metrics="metrics"
                    :objective_type="
                        data_report_campaign.campaign.objective_type
                    "
                ></Audience>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import Placements from './placements'
import Adgroup from './adgroup'
import Ad from './ad'
import Audience from './audience'
import Lifetime from './lifetime'
import OverallTrends from './overall-trends'
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import moment from 'moment'
import { updateCampaignStatus } from '@/services/atosa-tiktok-ads/campaign'
import { updateAdStatus } from '@/services/atosa-tiktok-ads/ad'
import { updateAdgroupStatus } from '@/services/atosa-tiktok-ads/adgroup'
import { getReportCampaigns } from '@/services/atosa-tiktok-ads/reporting'

export default {
    components: {
        OverallTrends,
        Lifetime,
        Adgroup,
        Ad,
        Audience,
        Placements
    },

    mixins: [rangerDateMixin],

    props: [
        'visible',
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'belong_to_atosa',
        'ranger_date',
        'type'
    ],

    data() {
        return {
            switch_compare: false,
            compare_ranger_date: [],
            operation_status: false,
            exceed_duration_days: 21,
            radio_choose_stat_time: 'stat_time_day',
            selected_metrics: [],
            secondary_active_tab: 'first',
            active_tab: 'first',
            type_inline: '',
            data_report_campaign: null,
            data_report_adgroups: [],
            data_report_ads: [],
            selected_item_to_report: null,
            compare_selected_item_to_report: null,
            data_metrics: [
                {
                    label: this.$t('common.cost'),
                    value: 'spend',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_cost_per_conversion'),
                    value: 'cost_per_conversion',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_cpc'),
                    value: 'cpc',
                    has_currency: true,
                    has_percent: false
                },
                {
                    label: this.$t(`page.campaign.column_impressions`),
                    value: 'impressions',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_ctr'),
                    value: 'ctr',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t(`page.campaign.column_clicks`),
                    value: 'clicks',
                    has_currency: false,
                    has_percent: false
                },
                {
                    label: this.$t('page.campaign.column_conversion_rate'),
                    value: 'conversion_rate',
                    has_currency: false,
                    has_percent: true
                },
                {
                    label: this.$t('page.campaign.column_conversion'),
                    value: 'conversion',
                    has_currency: false,
                    has_percent: false
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        },

        disabled_stat_time_hour() {
            return (
                moment(this.m__ranger_date[1]).diff(
                    moment(this.m__ranger_date[0]),
                    'days'
                ) > this.exceed_duration_days
            )
        },

        metrics() {
            return this.p__trend_chart_options_2.map((item) => item.value)
        },

        campaign_id() {
            if (this.type_inline === 'campaign_id') {
                return (
                    this.selected_item?.campaign?.campaign_id ||
                    this.selected_item_to_report.campaign.campaign_id
                )
            }
            if (this.type_inline === 'adgroup_id') {
                return (
                    this.selected_item?.adgroup?.campaign_id ||
                    this.selected_item_to_report.adgroup.campaign_id
                )
            }
            if (this.type_inline === 'ad_id') {
                return (
                    this.selected_item?.ad?.campaign_id ||
                    this.selected_item_to_report.ad.campaign_id
                )
            }

            return ''
        },

        data_id() {
            if (!this.selected_item_to_report) {
                return ''
            }
            if (this.type_inline === 'campaign_id') {
                return this.selected_item_to_report.campaign.campaign_id
            }
            if (this.type_inline === 'adgroup_id') {
                return this.selected_item_to_report.adgroup.adgroup_id
            }
            if (this.type_inline === 'ad_id') {
                return this.selected_item_to_report.ad.ad_id
            }

            return ''
        }
    },

    watch: {
        selected_item() {
            this.type_inline = this.type

            this.active_tab = 'first'

            this.secondary_active_tab = 'first'

            this.m__ranger_date = this.ranger_date

            this.fetchReportCampaign(this.m__ranger_date)

            this.fetchReportAdgroups(this.m__ranger_date)

            this.fetchReportAds(this.m__ranger_date)
        },

        selected_item_to_report() {
            this.getOperationStatus()
        },

        m__ranger_date() {
            this.radio_choose_stat_time = 'stat_time_day'

            this.fetchReportCampaign(this.m__ranger_date)

            this.fetchReportAdgroups(this.m__ranger_date)

            this.fetchReportAds(this.m__ranger_date)
        },

        selected_metrics() {
            localStorage.setItem(
                'storage_selected_metrics',
                JSON.stringify(this.selected_metrics)
            )
        },

        compare_ranger_date() {
            this.fetchCompareSelectedItemToReport()
        },

        switch_compare() {
            if (this.switch_compare) {
                const start_date = moment(this.m__ranger_date[0])
                const end_date = moment(this.m__ranger_date[1])

                const days_diff = end_date.diff(start_date, 'days')

                const second_day = start_date.subtract(1, 'days')
                const first_day = moment(second_day).subtract(days_diff, 'days')

                this.compare_ranger_date = [
                    first_day.format('YYYY-MM-DD'),
                    second_day.format('YYYY-MM-DD')
                ]
            }
        }
    },

    mounted() {
        this.getSelectedMetricsFromStorage()
    },

    methods: {
        async fetchCompareSelectedItemToReport() {
            this.m__loading = true

            try {
                const list = await this.fetchReportData(
                    this.type_inline,
                    this.compare_ranger_date
                )

                if (this.type_inline === 'adgroup_id') {
                    this.compare_selected_item_to_report = list.find(
                        (item) =>
                            item.adgroup.adgroup_id ===
                            this.selected_item.adgroup.adgroup_id
                    )
                }

                if (this.type_inline === 'campaign_id') {
                    this.compare_selected_item_to_report = list[0]
                }

                if (this.type_inline === 'ad_id') {
                    this.compare_selected_item_to_report = list.find(
                        (item) => item.ad.ad_id === this.selected_item.ad.ad_id
                    )
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async handleChangeStatusCampaign() {
            this.operation_status = !this.operation_status

            const operation_status = this.operation_status
                ? 'ENABLE'
                : 'DISABLE'

            if (this.type_inline === 'campaign_id') {
                try {
                    await updateCampaignStatus(this.tiktok_account_id, {
                        advertiser_id: this.advertiser_id,
                        campaign_ids: [this.data_id],
                        belong_to_atosa: this.belong_to_atosa,
                        operation_status
                    })

                    this.p__event_bus.$emit('eb_reload_campaign')
                    this.p__event_bus.$emit('eb_reload_adgroup')
                    this.p__event_bus.$emit('eb_reload_ad')

                    this.p__showNotify(
                        'success',
                        this.$t(
                            'page.campaign.message_update_status_xxx_success',
                            {
                                xxx: this.$t(`common.campaign`)
                            }
                        )
                    )
                } catch (error) {
                    console.error(error)

                    this.p__showNotify(
                        'error',
                        this.$t(
                            'page.campaign.message_update_status_xxx_failed',
                            {
                                xxx: this.$t(`common.campaign`)
                            }
                        )
                    )
                }

                return
            }

            if (this.type_inline === 'adgroup_id') {
                try {
                    await updateAdgroupStatus(this.tiktok_account_id, {
                        advertiser_id: this.advertiser_id,
                        adgroup_ids: [this.data_id],
                        operation_status
                    })

                    this.p__event_bus.$emit('eb_reload_adgroup')
                    this.p__event_bus.$emit('eb_reload_ad')

                    this.p__showNotify(
                        'success',
                        this.$t(
                            'page.campaign.message_update_status_xxx_success',
                            {
                                xxx: this.$t(`common.adgroup`)
                            }
                        )
                    )
                } catch (error) {
                    console.error(error)

                    this.p__showNotify(
                        'error',
                        this.$t(
                            'page.campaign.message_update_status_xxx_failed',
                            {
                                xxx: this.$t(`common.adgroup`)
                            }
                        )
                    )
                }

                return
            }

            if (this.type_inline === 'ad_id') {
                try {
                    await updateAdStatus(this.tiktok_account_id, {
                        advertiser_id: this.advertiser_id,
                        ad_ids: [this.data_id],
                        operation_status
                    })

                    this.p__event_bus.$emit('eb_reload_ad')

                    this.p__showNotify(
                        'success',
                        this.$t(
                            'page.campaign.message_update_status_xxx_success',
                            {
                                xxx: this.$t(`common.ad`)
                            }
                        )
                    )
                } catch (error) {
                    console.error(error)

                    this.p__showNotify(
                        'error',
                        this.$t(
                            'page.campaign.message_update_status_xxx_failed',
                            {
                                xxx: this.$t(`common.ad`)
                            }
                        )
                    )
                }
            }
        },

        getSelectedMetricsFromStorage() {
            const temp = JSON.parse(
                localStorage.getItem(`storage_selected_metrics`)
            )

            if (temp && temp.length) {
                this.selected_metrics = temp
            } else {
                this.selected_metrics = [
                    { ...this.p__trend_chart_options_2[0] }
                ]
            }
        },

        getOperationStatus() {
            if (!this.selected_item_to_report) {
                return
            }

            if (this.type_inline === 'campaign_id') {
                this.operation_status =
                    this.selected_item_to_report.campaign.operation_status ===
                    'ENABLE'
            }
            if (this.type_inline === 'adgroup_id') {
                this.operation_status =
                    this.selected_item_to_report.adgroup.operation_status ===
                    'ENABLE'
            }
            if (this.type_inline === 'ad_id') {
                this.operation_status =
                    this.selected_item_to_report.ad.operation_status ===
                    'ENABLE'
            }
        },

        handleChangeMetric(value, index) {
            const item = this.p__trend_chart_options_2.find(
                (i) => i.value === value
            )

            this.selected_metrics = this.selected_metrics.map((it, idx) => {
                if (index === idx) {
                    return item
                }

                return it
            })
        },

        handleRemoveMetric(index) {
            if (this.selected_metrics.length === 1) {
                return
            }
            this.selected_metrics.splice(index, 1)
        },

        handleAddMetrics() {
            for (let i = 0; i < this.p__trend_chart_options_2.length; i++) {
                const item = this.p__trend_chart_options_2[i]
                if (
                    this.selected_metrics.every((it) => it.value !== item.value)
                ) {
                    this.selected_metrics = [
                        ...this.selected_metrics,
                        { ...item }
                    ]
                    return
                }
            }
        },

        handleChooseDataReport(data, type) {
            this.active_tab = 'first'
            this.secondary_active_tab = 'first'

            this.type_inline = type
            this.selected_item_to_report = data
        },

        async fetchReportCampaign(ranger_date) {
            this.m__loading = true

            try {
                const list = await this.fetchReportData(
                    'campaign_id',
                    ranger_date
                )

                if (list.length) {
                    this.data_report_campaign = list[0]
                }

                if (this.type_inline === 'campaign_id') {
                    this.selected_item_to_report = this.data_report_campaign
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchReportAdgroups(ranger_date) {
            this.m__loading = true

            try {
                const list = await this.fetchReportData(
                    'adgroup_id',
                    ranger_date
                )

                if (list.length) {
                    this.data_report_adgroups = list
                }

                if (this.type_inline === 'adgroup_id') {
                    this.selected_item_to_report =
                        this.data_report_adgroups.find(
                            (item) =>
                                item.adgroup.adgroup_id ===
                                this.selected_item.adgroup.adgroup_id
                        )
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchReportAds(ranger_date) {
            this.m__loading = true

            try {
                const list = await this.fetchReportData('ad_id', ranger_date)

                if (list.length) {
                    this.data_report_ads = list
                }

                if (this.type_inline === 'ad_id') {
                    this.selected_item_to_report = this.data_report_ads.find(
                        (item) => item.ad.ad_id === this.selected_item.ad.ad_id
                    )
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchReportData(type, ranger_date) {
            if (!this.selected_item || !this.campaign_id) {
                return []
            }

            try {
                const response = await getReportCampaigns(
                    this.tiktok_account_id,
                    {
                        page_size: 50,
                        page: 1,
                        advertiser_id: this.advertiser_id,
                        type,
                        report_type: 'BASIC',
                        start_date: ranger_date[0],
                        end_date: ranger_date[1],
                        filtering: JSON.stringify([
                            {
                                filter_value: JSON.stringify([
                                    this.campaign_id
                                ]),
                                field_name: 'campaign_ids',
                                filter_type: 'IN'
                            }
                        ]),
                        metrics: JSON.stringify(this.metrics)
                    }
                )
                return response.data.list
            } catch (error) {
                console.error(error)
                return []
            }
        }
    }
}
</script>
