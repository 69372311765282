<template>
    <custom-drawer
        v-loading="loading"
        size="1200px"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between h-full pl-2 pr-4">
                <div>
                    <p class="text-sm font-semibold">
                        {{ `${$t('common.copy')}  ${$t('common.campaign')}` }}
                    </p>
                    <p class="text-desc-text text-xs">
                        {{ `${copy_item ? `#${copy_item.campaign_id}` : ``}` }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-copy-document"
                    type="primary"
                    size="small"
                    :disabled="multiple_selection_adgroups.length === 0"
                    @click="handleCopy"
                >
                    {{ $t('common.copy') }}
                </el-button>
            </div>
        </template>

        <section class="py-4 pl-4 pr-4">
            <div
                v-if="copy_item"
                id="input_campaign_name_wrapper"
                class="component-box mb-4"
            >
                <p class="component-label">
                {{ $t('common.advertiser_account') }}
              </p>
              <el-select
              v-model="selected_ads_account"
              size="small"
              filterable
              :placeholder="
                  $t('input.placeholder.select_ads_account')
              "
              class="w-[340px] mb-3"
              >
                  <el-option-group
                      :label="$t('common.advertiser_account')"
                  >
                      <el-option
                          v-for="item in ads_accounts"
                          :key="item.advertiser_id"
                          :label="item.name"
                          :value="item.advertiser_id"
                      >
                          <div class="relative">
                              <div
                                  v-if="item.belong_to_atosa"
                                  class="-right-4 bg-brand-atosa--light util-flex-center absolute bottom-0 h-5 px-1 text-white"
                              >
                                  <span class="text-xxxs uppercase">
                                      atosa
                                  </span>
                              </div>
                              <div class="flex flex-col py-1">
                                  <span
                                      class="text-xs font-semibold leading-5"
                                  >
                                      {{ item.name }}
                                  </span>
                                  <div
                                      class="flex items-center space-x-1 leading-3"
                                  >
                                      <i class="el-icon-money"></i>

                                      <span class="text-xs">
                                          {{
                                              p__renderMoneyByCurrency(
                                                  item.balance,
                                                  item.currency
                                              )
                                          }}
                                      </span>
                                  </div>
                                  <span class="component-text-desc">
                                      #{{ item.advertiser_id }}
                                  </span>
                              </div>
                          </div>
                      </el-option>
                  </el-option-group>
              </el-select>
                <div class="flex space-x-4">
                    <div>
                        <p class="component-label">
                            {{ $t('page.campaign.campaign_name') }}
                        </p>
                        <el-input
                            id="input_campaign_name"
                            v-model="copy_item.campaign_name"
                            class="w-[360px]"
                            size="small"
                        ></el-input>
                    </div>

                    <div>
                        <p class="component-label">
                            {{ $t('common.budget') }}
                        </p>
                        <div class="flex mt-2 space-x-2">
                            <el-select
                                v-model="copy_item.budget_mode"
                                class="w-[200px]"
                                size="small"
                                filterable
                                :placeholder="
                                    $t('input.placeholder.please_select')
                                "
                            >
                                <el-option
                                    v-for="it in p__budget_mode_options"
                                    :key="it.value"
                                    :label="it.label"
                                    :value="it.value"
                                >
                                </el-option>
                                <el-option
                                    :label="$t(`common.budget_mode_infinite`)"
                                    value="BUDGET_MODE_INFINITE"
                                >
                                </el-option>
                            </el-select>

                            <div
                                v-if="
                                    copy_item.budget_mode !==
                                    'BUDGET_MODE_INFINITE'
                                "
                            >
                                <el-input
                                    v-model="copy_item.budget"
                                    class="w-[200px]"
                                    size="small"
                                    type="number"
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                >
                                    <template slot="append">
                                        {{ currency }}
                                    </template>
                                </el-input>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="component-label">
                            {{ $t('common.objective_type') }}
                        </p>
                        <p class="text-sm">
                            {{ objective_type_title }}
                        </p>
                    </div>

                    <div>
                        <p class="component-label">
                            {{ $t('page.campaign.adgroup_pane.number_copies') }}
                        </p>
                        <el-input-number
                            v-model="number_copy"
                            class="w-[120px]"
                            size="small"
                            :min="1"
                            :max="p__number_max_copy_adgroup"
                        ></el-input-number>
                    </div>

                </div>
            </div>

            <div class="component-box">
                <div>
                    <div class="flex items-center mb-2 space-x-2">
                        <p class="text-sm font-semibold">
                            Nhóm quảng cáo đã chọn
                        </p>

                        <div class="!ml-auto"></div>

                        <el-button
                            :disabled="multiple_selection_adgroups.length === 0"
                            :type="'danger'"
                            size="small"
                            @click="multiple_selection_adgroups = []"
                        >
                            {{ $t('common.deselect_all') }}
                        </el-button>
                    </div>

                    <el-table :height="280" :data="multiple_selection_adgroups">
                        <el-table-column
                            width="300"
                            prop="adgroup.adgroup_name"
                            :label="$t('common.name')"
                        >
                            <template #default="scope">
                                <div
                                    class="group hover:text-brand-atosa--light"
                                >
                                    <div class="group cursor-pointer">
                                        <p>
                                            ID:
                                            {{ scope.row.adgroup.adgroup_id }}
                                        </p>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                scope.row.adgroup.adgroup_name
                                            "
                                            placement="top"
                                        >
                                            <p>
                                                {{
                                                    scope.row.adgroup
                                                        .adgroup_name
                                                }}
                                            </p>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column
                            width="160"
                            :show-overflow-tooltip="true"
                            prop="adgroup.campaign_id"
                            :label="
                                $t('common.xxx_id', {
                                    xxx: $t(`common.campaign`)
                                })
                            "
                        />

                        <el-table-column
                            :label="$t('common.budget')"
                            width="100px"
                        >
                            <template #default="scope">
                                <p class="font-semibold">
                                    {{
                                        p__renderMoneyByCurrency(
                                            scope.row.adgroup.budget,
                                            scope.row.currency
                                        )
                                    }}
                                </p>
                                <p class="component-text-desc">
                                    {{
                                        p__formatBudgetMode(
                                            scope.row.adgroup.budget_mode
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('common.ad_scheduling')"
                            width="160px"
                        >
                            <template #default="scope">
                                <p>
                                    {{
                                        p__formatDate(
                                            scope.row.adgroup
                                                .schedule_start_time
                                        )
                                    }}
                                </p>
                                <p>
                                    {{
                                        p__formatDate(
                                            scope.row.adgroup.schedule_end_time
                                        )
                                    }}
                                </p>
                            </template>
                        </el-table-column>

                        <el-table-column
                            :label="$t('common.option')"
                            fixed="right"
                            width="320"
                        >
                            <template #default="scope">
                                <el-button
                                    size="small"
                                    @click="handleEditAdGroup(scope.row)"
                                >
                                    {{ $t('common.edit') }}
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="handleChooseAdgroup(scope.row)"
                                >
                                    {{ $t('common.copy') }}
                                </el-button>
                                <el-button
                                    :type="'danger'"
                                    size="small"
                                    @click="handleUnchooseAdgroup(scope.row.id)"
                                >
                                    {{ $t('common.unchecked') }}
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <AdgroupTable
                    :tiktok_account_id="tiktok_account_id"
                    :copy_item="copy_item"
                    :ranger_date="ranger_date"
                    :advertiser_id="advertiser_id"
                    :filtering="filtering"
                    :belong_to_atosa="belong_to_atosa"
                    :handle-choose-adgroup="handleChooseAdgroup"
                ></AdgroupTable>
            </div>
        </section>

        <EditAdgroupDrawer
            :item="active_adgroup_item"
            :objective_type="objective_type"
            :tiktok_account_id="tiktok_account_id"
            :min_budget_adgroup="min_budget_adgroup"
            :advertiser_id="advertiser_id"
            :currency="active_currency"
            :timezone="active_timezone"
            :belong_to_atosa="belong_to_atosa"
            :handle_change_adgroup="handleChangeAdgroup"
            :visible.sync="is_edit_adgroup_drawer_displayed"
        ></EditAdgroupDrawer>
    </custom-drawer>
</template>

<script>
import campaignMixin from '../../_mixin'
import { createFullCampaign } from '@/services/atosa-tiktok-ads/campaign'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import { getAds } from '@/services/atosa-tiktok-ads/ad'
import moment from 'moment'
import EditAdgroupDrawer from './edit-adgroup-drawer'
import AdgroupTable from './adgroup-table'

export default {
    components: {
        EditAdgroupDrawer,
        AdgroupTable
    },

    mixins: [campaignMixin, selectedTiktokBusinessMixin, EditAdgroupFormMixin],

    props: ['visible', 'item', 'tiktok_account_id', 'ads_accounts', 'advertiser_id'],

    data() {
        return {
            number_copy: 1,
            objective_type_title: '',
            copy_item: null,
            active_adgroup_item: null,
            loading: false,
            multiple_selection_adgroups: [],
            active_currency: null,
            active_timezone: null,
            objective_type: null,
            is_edit_adgroup_drawer_displayed: false,
            selected_ads_account: null
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        title_copy_campaign() {
            if (!this.copy_item) {
                return ''
            }

            return `${this.$t('common.copy')} ${this.$t(
                'common.campaign'
            ).toLowerCase()} #${this.copy_item.campaign_id}`
        },

        min_budget_adgroup() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.min_budget_adgroup
            }

            return 0
        },

        min_budget_campaign() {
            if (this.m__selected_ads_account) {
                return this.m__selected_ads_account.min_budget_campaign
            }

            return 0
        }
    },

    watch: {
        advertiser_id(newVal, oldVal) {
          // Kiểm tra nếu advertiser_id thay đổi thì gán vào selected_ads_account
          if (newVal !== oldVal) {
            this.selected_ads_account = newVal
          }
        },
        visible_in_line() {
            if (!this.visible_in_line) {
                this.m__data_for_pagination = []
                this.multiple_selection_adgroups = []

                return
            }

            const now = moment()

            const date = now.format('YYYYMMDDHHmmss')

            this.m__type = 'adgroup_id'

            this.copy_item = {
                ...this.item,
                campaign_name: `${this.item.campaign_name}_${date}_copy`
            }

            const filter = {
                filter_value: JSON.stringify([this.copy_item.campaign_id]),
                field_name: 'campaign_ids',
                filter_type: 'IN'
            }
            this.m__filtering = [filter]
            this.m__getReportCampaignsByAccount()

            const temp = this.p__list_ads_objectives.find((i) =>
                i.items.some((item) => item.value === this.item.objective_type)
            )

            if (temp) {
                this.objective_type_title = temp.title
            }
        },

        m__data_for_pagination() {
            this.multiple_selection_adgroups = this.m__data_for_pagination.map(
                (item) => ({
                    ...item,
                    id: this.p__renderId()
                })
            )
        }
    },

    methods: {
        handleChooseAdgroup(adgroup) {
            this.multiple_selection_adgroups.push({
                ...adgroup,
                id: this.p__renderId()
            })
        },

        handleUnchooseAdgroup(id) {
            this.multiple_selection_adgroups =
                this.multiple_selection_adgroups.filter(
                    (item) => item.id !== id
                )
        },

        handleEditAdGroup(adgroup) {
            this.is_edit_adgroup_drawer_displayed = true
            this.active_currency = adgroup.currency
            this.active_timezone = adgroup.timezone
            this.objective_type = this.copy_item.objective_type

            this.active_adgroup_item = {
                ...adgroup.adgroup,
                schedule_start_time: this.p__formatDate(
                    adgroup.adgroup.schedule_start_time
                ),
                schedule_end_time: this.p__formatDate(
                    adgroup.adgroup.schedule_end_time
                ),
                extension: this.p__default_state_infor_adgroup.extension
            }

            this.changeInforAdgroupByObjectiveType(this.objective_type)
        },

        changeInforAdgroupByObjectiveType(objective_type) {
            if (objective_type === 'REACH') {
                this.active_adgroup_item.optimization_goal = 'REACH'
                this.active_adgroup_item.billing_event = 'CPM'

                return
            }

            if (objective_type === 'TRAFFIC') {
                this.active_adgroup_item.optimization_goal = 'CLICK'
                this.active_adgroup_item.billing_event = 'CPC'
                this.active_adgroup_item.promotion_type = 'WEBSITE'
                return
            }

            if (objective_type === 'VIDEO_VIEWS') {
                this.active_adgroup_item.optimization_goal = 'ENGAGED_VIEW'
                this.active_adgroup_item.billing_event = 'CPV'
                this.active_adgroup_item.bid_display_mode = 'CPV'

                return
            }

            if (objective_type === 'LEAD_GENERATION') {
                this.active_adgroup_item.optimization_goal = 'LEAD_GENERATION'
                this.active_adgroup_item.billing_event = 'OCPM'
                this.active_adgroup_item.promotion_type = 'LEAD_GENERATION'
                this.active_adgroup_item.conversion_bid_price = 1

                return
            }

            if (objective_type === 'ENGAGEMENT') {
                this.active_adgroup_item.optimization_goal = 'FOLLOWERS'
                this.active_adgroup_item.billing_event = 'OCPM'
                this.active_adgroup_item.conversion_bid_price = 1

                return
            }

            if (objective_type === 'APP_PROMOTION') {
                this.active_adgroup_item.optimization_goal = 'CLICK'
                this.active_adgroup_item.billing_event = 'CPC'
                this.active_adgroup_item.operating_systems = ['ANDROID']
                this.active_adgroup_item.promotion_type = 'APP_ANDROID'

                return
            }

            if (objective_type === 'WEB_CONVERSIONS') {
                this.active_adgroup_item.optimization_goal = 'CONVERT'
                this.active_adgroup_item.billing_event = 'OCPM'
                this.active_adgroup_item.promotion_type = 'WEBSITE'
                this.active_adgroup_item.conversion_bid_price = 1

                return
            }

            if (objective_type === 'CATALOG_SALES') {
                this.active_adgroup_item.billing_event = 'CPM'
                this.active_adgroup_item.shopping_ads_retargeting_type = 'OFF'

                return
            }

            if (objective_type === 'PRODUCT_SALES') {
                this.active_adgroup_item.shopping_ads_type = 'LIVE'
                this.active_adgroup_item.optimization_goal = 'CLICK'
                this.active_adgroup_item.optimization_event = ''
                this.active_adgroup_item.billing_event = 'CPC'
                this.active_adgroup_item.promotion_type = 'LIVE_SHOPPING'
                this.active_adgroup_item.store_authorized_bc_id = ''

                return
            }

            this.active_adgroup_item.optimization_goal = 'CLICK'
            this.active_adgroup_item.optimization_event = ''
        },

        handleChangeAdgroup(adgroup) {
            this.m__data_for_pagination = this.m__data_for_pagination.map(
                (item) => {
                    if (item.adgroup.adgroup_id === adgroup.adgroup_id) {
                        return {
                            ...item,
                            adgroup,
                            has_change: true
                        }
                    }

                    return item
                }
            )
        },

        async handleCopy() {
            if (!this.copy_item) {
                return
            }

            if (!this.multiple_selection_adgroups.length) {
                return
            }

            if (!this.copy_item.campaign_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_input_campaign_name')
                )

                document
                    .getElementById('input_campaign_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_campaign_name').focus()
                document.getElementById('input_campaign_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return false
            }

            this.loading = true

            let has_error = false

            try {
                const adgroups = []

                const list_adgroups = this.multiple_selection_adgroups.map(
                    (item) => {
                        const temp = { ...item.adgroup }
                        removeEmptyParams(temp)
                        return temp
                    }
                )

                for (const adgroup of list_adgroups) {
                    try {
                        const temp_adgroup = {
                            ...JSON.parse(JSON.stringify(adgroup)),
                            creative: {
                                creatives: []
                            }
                        }
                        const response_get_ads = await getAds(
                            this.tiktok_account_id,
                            {
                                advertiser_id: this.advertiser_id,
                                filtering: JSON.stringify({
                                    adgroup_ids: [temp_adgroup.adgroup_id]
                                })
                            }
                        )

                        if (response_get_ads.code !== 0) {
                            has_error = true
                            continue
                        }

                        const list_ads = response_get_ads.data.list.map(
                            (item) => {
                                if (!item.ad_text) {
                                    item.ad_text = 'ad'
                                }
                                if (
                                    item.creative_type === 'SHOPPING_SHOP_PAGE'
                                ) {
                                    item.creative_type = 'PSA'
                                }
                                delete item.brand_safety_postbid_partner
                                removeEmptyParams(item)
                                return item
                            }
                        )
                        if (list_ads.length) {
                            if (temp_adgroup?.has_change) {
                                this.m__changeFormatScheduleTime(temp_adgroup)
                            } else {
                                this.m__changeScheduleTime(
                                    temp_adgroup,
                                    this.copy_item.objective_type,
                                    false
                                )
                            }

                            this.m__changeActionCategories(temp_adgroup)

                            temp_adgroup.creative.creatives.push(...list_ads)

                            adgroups.push(temp_adgroup)
                        }
                    } catch (error) {
                        console.error(error)
                    }
                }

                const budget =
                    this.copy_item.budget < this.min_budget_campaign
                        ? this.min_budget_campaign
                        : +this.copy_item.budget
                const { rta_id, ...rest } = this.copy_item

                const temp = {
                    ...rest,
                    secondary_status: 'CAMPAIGN_STATUS_ENABLE',
                    operation_status: 'ENABLE',
                    belong_to_atosa: this.belong_to_atosa,
                    budget,
                    adgroups,
                    advertiser_id: this.selected_ads_account
                }

                delete temp.app_promotion_type
                delete temp.deep_bid_type
                delete temp.campaign_app_profile_page_state
                delete temp.roas_bid

                const response = await createFullCampaign(
                    this.tiktok_account_id,
                    temp
                )

                if (response.code === 0) {
                    if (this.number_copy > 1) {
                        const number_list = Array.from(
                            { length: this.number_copy - 1 },
                            (_, i) => i
                        )

                        await Promise.all(
                            number_list.map(async (numb) => {
                                await createFullCampaign(
                                    this.tiktok_account_id,
                                    temp
                                )
                            })
                        )
                    }

                    setTimeout(() => {
                        this.p__event_bus.$emit('eb_reload_campaign')
                        this.p__event_bus.$emit('eb_reload_adgroup')
                        this.p__event_bus.$emit('eb_reload_ad')
                    }, 1111)

                    this.visible_in_line = false

                    this.p__showNotify(
                        'success',
                        this.$t('message.xxx_success', {
                            xxx: `${this.$t(`common.copy`)} ${this.$t(
                                `common.campaign`
                            )}`
                        })
                    )
                } else {
                    has_error = true
                }
            } catch (error) {
                console.error(error)
                has_error = true
            }

            if (has_error) {
                this.p__showNotify(
                    'error',
                    this.$t('message.xxx_fail', {
                        xxx: `${this.$t(`common.copy`)} ${this.$t(
                            `common.campaign`
                        )}`
                    })
                )
            }

            this.loading = false
        }
    }
}
</script>
