<template>
    <custom-drawer
        v-loading="m__loading"
        size="50%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('page.campaign.adgroup_pane.edit_campaign') }}
                    </p>
                    <p v-if="new_selected_item" class="text-desc-text text-xs">
                        #{{ new_selected_item.campaign_id }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleEditCampaign()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-4">
            <div class="component-box">
                <p class="font-semibold text-sm">
                    {{ $t('common.objective_type') }}
                </p>

                <p class="text-desc-text text-sm mt-1">
                    {{ title }}
                </p>

                <div
                    v-if="selected_ads_objective"
                    class="mt-4 w-[320px] rounded-lg py-6 px-8 flex space-x-4 cursor-pointer border-[1px] box-border select-none border-brand-atosa bg-white"
                >
                    <div class="min-w-10">
                        <font-awesome-icon
                            size="lg"
                            :icon="selected_ads_objective.icon"
                        />
                    </div>

                    <div>
                        <p class="font-semibold text-base mb-1">
                            {{ selected_ads_objective.title }}
                        </p>

                        <p class="component-text-desc min-h-[44px]">
                            {{ selected_ads_objective.description }}
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="new_selected_item" class="component-box mt-4">
                <div>
                    <label class="component-label">
                        {{ $t('page.campaign.campaign_name') }}
                    </label>
                    <br />
                    <div class="flex items-center space-x-2">
                        <el-input
                            ref="el_input_campaign_name"
                            v-model="new_selected_item.new_name"
                            size="small"
                            class="w-1/2"
                        ></el-input>

                        <NameRow
                            :selected_item.sync="new_selected_item"
                            :objective_type="new_selected_item.objective_type"
                            :ads_account_name="ads_account_name"
                            :template_name_list="template_name_list"
                            :getTemplateNames="getTemplateNames"
                            :tiktok_business_user_name="
                                tiktok_business_user_name
                            "
                        ></NameRow>
                    </div>
                </div>
                <div class="mt-4">
                    <el-checkbox
                        v-model="checked_no_limit"
                        :disabled="
                            new_selected_item.budget_mode !==
                            'BUDGET_MODE_INFINITE'
                        "
                        @change="handleChangeBudgetMode"
                    >
                        {{ $t(`common.budget_mode_infinite`) }}
                    </el-checkbox>

                    <div v-if="!checked_no_limit" class="flex mt-2 space-x-2">
                        <el-select
                            v-model="new_selected_item.budget_mode"
                            disabled
                            class="w-[200px]"
                            size="small"
                            filterable
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in p__budget_mode_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                        <el-input
                            v-model="new_selected_item.budget"
                            class="w-[200px]"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append">
                                {{ currency }}
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import { updateCampaign } from '@/services/atosa-tiktok-ads/campaign'
import NameRow from '../show-edit-multi-name-drawer/name-row'
import { getTemplateNames } from '@/services/atosa-tiktok-ads/tool'

export default {
    components: {
        NameRow
    },

    props: [
        'visible',
        'selected_item',
        'currency',
        'tiktok_account_id',
        'advertiser_id',
        'min_budget_campaign',
        'ads_account_name',
        'tiktok_business_user_name',
        'belong_to_atosa'
    ],

    data() {
        return {
            new_selected_item: null,
            checked_no_limit: true,
            selected_ads_objective: null,
            template_name_list: [],
            title: ''
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        selected_item() {
            this.new_selected_item = {
                ...this.selected_item.campaign,
                new_name: this.selected_item.campaign.campaign_name
            }

            this.getStateInfor()
        }
    },

    mounted() {
        this.getStateInfor()

        this.getTemplateNames()
    },

    methods: {
        getStateInfor() {
            if (!this.new_selected_item) {
                return
            }
            const item = this.p__list_ads_objectives.find((i) =>
                i.items.some(
                    (item) =>
                        item.value === this.new_selected_item.objective_type
                )
            )

            if (item) {
                this.title = item.title

                const temp = item.items.find(
                    (i) =>
                        (i.value === this.new_selected_item.budget_mode) === ''
                )

                if (temp) {
                    this.selected_ads_objective = temp
                }
            }

            this.checked_no_limit =
                this.new_selected_item.budget_mode === 'BUDGET_MODE_INFINITE'
        },

        async handleChangeBudgetMode() {
            this.new_selected_item.budget_mode = this.checked_no_limit
                ? 'BUDGET_MODE_INFINITE'
                : 'BUDGET_MODE_TOTAL'

            if (!this.checked_no_limit) {
                this.new_selected_item.budget = this.min_budget_campaign
            }
        },

        async getTemplateNames() {
            try {
                const response = await getTemplateNames()
                if (response.code === 0) {
                    this.template_name_list = response.data.filter(
                        (item) => item.type === 'campaign'
                    )
                }
            } catch (error) {
                console.error(error)
            }
        },

        async handleEditCampaign() {
            if (
                this.new_selected_item.budget_mode === 'BUDGET_MODE_TOTAL' &&
                this.new_selected_item.budget < this.min_budget_campaign
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_budget_than_min', {
                        budget: this.p__renderMoneyByCurrency(
                            this.min_budget_campaign,
                            this.currency
                        )
                    })
                )

                return
            }

            try {
                await updateCampaign(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    campaign_name: this.new_selected_item.campaign_name,
                    campaign_id: this.new_selected_item.campaign_id,
                    budget_mode: this.new_selected_item.budget_mode,
                    budget: this.new_selected_item.budget,
                    belong_to_atosa: this.belong_to_atosa
                })

                this.p__event_bus.$emit('eb_reload_campaign')

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
