<template>
    <custom-drawer
        v-loading="m__loading"
        size="85%"
        :confirm_to_close="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('page.campaign.edit_rule') }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleEditAutomatedRule"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-8">
            <div id="input_rule_name_wrapper" class="component-box">
                <p class="component-label">
                    {{ $t('page.campaign.rule_name') }}
                </p>

                <el-input
                    id="input_rule_name"
                    v-model="rule_name"
                    class="flex-1"
                    size="small"
                ></el-input>
            </div>

            <div class="component-box flex flex-col mt-4 space-y-8">
                <div>
                    <div class="flex items-center mb-3">
                        <div
                            class="bg-brand-atosa--light util-flex-center p-2 rounded-md"
                        >
                            <i class="el-icon-box text-white"></i>
                        </div>

                        <p class="ml-2 text-sm font-semibold">
                            {{ $t('page.campaign.apply_rule') }}
                        </p>
                    </div>

                    <p class="text-sm font-semibold">
                        {{ $t('page.campaign.select_rule_for') }}
                    </p>

                    <el-select
                        v-model="selected_apply_object.pre_condition_type"
                        class="mt-1 w-[320px]"
                        size="small"
                        disabled
                    >
                        <el-option-group
                            v-for="group in pre_condition_type_options"
                            :key="group.label"
                            :label="group.label"
                        >
                            <el-option
                                v-for="item in group.options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-option-group>
                    </el-select>
                </div>

                <div>
                    <div class="flex items-center mb-3">
                        <div
                            class="bg-brand-atosa--light util-flex-center p-2 rounded-md"
                        >
                            <i class="el-icon-star-on text-white"></i>
                        </div>

                        <p class="ml-2 text-sm font-semibold">
                            {{ $t('page.campaign.condition_and_action') }}
                        </p>
                    </div>

                    <ConditionAndAction
                        :selected_conditions.sync="selected_conditions"
                        :selected_actions.sync="selected_actions"
                        :dimension="selected_apply_object.dimension"
                        :currency="currency"
                    >
                    </ConditionAndAction>
                </div>

                <div>
                    <div class="flex items-center">
                        <div
                            class="bg-brand-atosa--light util-flex-center p-2 rounded-md"
                        >
                            <i class="el-icon-s-tools text-white"></i>
                        </div>

                        <p class="ml-2 text-sm font-semibold">
                            {{ $t('page.campaign.rule_settings') }}
                        </p>
                    </div>

                    <RunSchedule
                        :rule_exec_info.sync="rule_exec_info"
                    ></RunSchedule>

                    <div class="flex items-center mt-6 space-x-2">
                        <el-switch
                            v-model="switch_notify"
                            :disabled="
                                selected_actions.some(
                                    (item) => item.subject_type === 'MESSAGE'
                                )
                            "
                        >
                        </el-switch>
                        <p class="text-sm">
                            {{ $t('page.campaign.notify_me') }}
                        </p>
                    </div>
                    <p class="text-desc-text text-xs">
                        {{ $t('page.campaign.receive_notifications_by_email') }}
                    </p>

                    <NotifyMe
                        v-if="
                            notification.notification_type !==
                            'NOT_NOTIFICATION'
                        "
                        :notification.sync="notification"
                        class="mt-2"
                    ></NotifyMe>
                </div>
            </div>
        </div>
    </custom-drawer>
</template>

<script>
import NotifyMe from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/notify-me'
import RunSchedule from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/run-schedule'
import ConditionAndAction from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer/condition-and-action'
import { updateAutomatedRule } from '@/services/atosa-tiktok-ads/auto-ads'

const default_data = {
    switch_notify: true,
    selected_apply_object: {
        dimension: 'CAMPAIGN',
        dimension_ids: [],
        pre_condition_type: 'ALL_ACTIVE_CAMPAIGN'
    },
    rule_name: '',
    rule_exec_info: {
        exec_time_type: 'PER_HALF_HOUR',
        exec_time: '',
        time_period_info: []
    },
    notification: {
        notification_type: 'ANY_CHANGES',
        email_setting: {
            notification_period: 'EVERY_TIME',
            email_exec_time: [],
            no_result_notification: false,
            mute_option: 'UNMUTE'
        }
    },
    selected_actions: [
        {
            subject_type: 'TURN_OFF',
            action_type: 'ADJUST_TO',
            value_type: 'EXACT',
            time_end: '',
            value: {
                use_limit: false,
                value: 0,
                limit: 0
            },
            frequency_info: {
                type: 'ONLY_ONCE',
                custom_frequency_type: 'N_MINTUE_Y_TIMES',
                time: 0,
                count: 0
            }
        }
    ],
    selected_conditions: [
        {
            subject_type: 'spend',
            range_type: 'TODAY',
            calculation_type: 'OF_EACH_OBJECT',
            match_type: 'GT',
            values: [1]
        }
    ]
}

export default {
    components: {
        NotifyMe,
        RunSchedule,
        ConditionAndAction
    },

    props: [
        'visible',
        'tiktok_account_id',
        'advertiser_id',
        'currency',
        'edited_item'
    ],

    data() {
        return {
            ...default_data,
            default_pre_condition_type_options: [
                {
                    label: this.$t('common.active'),
                    options: [
                        {
                            value: 'ALL_ACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_active_campaign')
                        },
                        {
                            value: 'ALL_ACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_active_adgroup')
                        },
                        {
                            value: 'ALL_ACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_active_ad')
                        }
                    ]
                },
                {
                    label: this.$t('common.inactive'),
                    options: [
                        {
                            value: 'ALL_INACTIVE_CAMPAIGN',
                            value_dimension: 'CAMPAIGN',
                            label: this.$t('common.all_inactive_campaign')
                        },
                        {
                            value: 'ALL_INACTIVE_AD_GROUP',
                            value_dimension: 'ADGROUP',
                            label: this.$t('common.all_inactive_adgroup')
                        },
                        {
                            value: 'ALL_INACTIVE_AD',
                            value_dimension: 'AD',
                            label: this.$t('common.all_inactive_ad')
                        }
                    ]
                }
            ],
            pre_condition_type_options: []
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                const xxx =
                    this.edited_item.apply_objects[0].dimension_ids.length

                if (xxx) {
                    const selected_dimension =
                        this.edited_item.apply_objects[0].dimension
                    const dimension_ids =
                        this.edited_item.apply_objects[0].dimension_ids

                    let options = []

                    if (selected_dimension === 'CAMPAIGN') {
                        options = [
                            {
                                value: 'SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.xxx_campaign_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_ACTIVE_AD_GROUP_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_active_adgroup_under_xxx_campaign_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_ACTIVE_AD_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_active_ad_under_xxx_campaign_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_INACTIVE_AD_GROUP_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_inactive_adgroup_under_xxx_campaign_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_INACTIVE_AD_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_inactive_ad_under_xxx_campaign_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            }
                        ]
                    } else if (selected_dimension === 'ADGROUP') {
                        options = [
                            {
                                value: 'SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.xxx_adgroup_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_ACTIVE_AD_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_active_ad_under_xxx_adgroup_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            },
                            {
                                value: 'ALL_INACTIVE_AD_UNDER_SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.all_inactive_ad_under_xxx_adgroup_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            }
                        ]
                    } else if (selected_dimension === 'AD') {
                        options = [
                            {
                                value: 'SELECTED',
                                value_dimension: selected_dimension,
                                label: this.$t(
                                    'page.campaign.xxx_ad_has_selected',
                                    {
                                        xxx
                                    }
                                )
                            }
                        ]
                    }

                    const temp = [
                        {
                            label: this.$t('common.selected'),
                            options
                        }
                    ]
                    this.pre_condition_type_options = [
                        ...temp,
                        ...this.default_pre_condition_type_options
                    ]

                    this.selected_apply_object.pre_condition_type = 'SELECTED'
                    this.selected_apply_object.dimension_ids = dimension_ids
                    this.selected_apply_object.dimension = selected_dimension
                }
            }
        },

        switch_notify() {
            if (this.switch_notify) {
                if (
                    this.selected_actions.some(
                        (item) => item.subject_type === 'MESSAGE'
                    )
                ) {
                    this.notification.notification_type = 'TASK_FINISH'
                } else {
                    this.notification.notification_type = 'ANY_CHANGES'
                }
            } else {
                this.notification.notification_type = 'NOT_NOTIFICATION'
            }
        },

        selected_actions() {
            if (
                this.selected_actions.some(
                    (item) => item.subject_type === 'MESSAGE'
                )
            ) {
                this.switch_notify = true
                this.notification.notification_type = 'TASK_FINISH'
            } else {
                this.notification.notification_type = 'ANY_CHANGES'
            }
        },

        edited_item() {
            if (!this.edited_item) {
                return
            }

            this.rule_name = this.edited_item.name

            this.selected_actions = JSON.parse(
                JSON.stringify(this.edited_item.actions)
            )

            this.selected_conditions = JSON.parse(
                JSON.stringify(this.edited_item.conditions)
            )

            this.selected_apply_object = JSON.parse(
                JSON.stringify(this.edited_item.apply_objects[0])
            )

            this.rule_exec_info = JSON.parse(
                JSON.stringify(this.edited_item.rule_exec_info)
            )

            this.notification = JSON.parse(
                JSON.stringify(this.edited_item.notification)
            )
        }
    },

    mounted() {
        this.pre_condition_type_options = [
            ...this.default_pre_condition_type_options
        ]
    },

    methods: {
        checkOverlap(arr) {
            for (let i = 0; i < arr.length; i++) {
                const subArr1 = arr[i]
                const [start1, end1] = subArr1

                for (let j = i + 1; j < arr.length; j++) {
                    const subArr2 = arr[j]
                    const [start2, end2] = subArr2

                    if (
                        (start1 <= start2 && start2 <= end1) ||
                        (start2 <= start1 && start1 <= end2)
                    ) {
                        return true // Giao nhau
                    }
                }
            }
            return false // Không giao nhau
        },

        async handleEditAutomatedRule() {
            if (!this.rule_name) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.campaign.please_select_input_rule_name')
                )

                document
                    .getElementById('input_rule_name_wrapper')
                    .scrollIntoView({ behavior: 'smooth' })
                document.getElementById('input_rule_name').focus()
                document.getElementById('input_rule_name').className +=
                    ' border-red border-[1px] rounded-[8px]'

                return
            }

            if (
                this.rule_exec_info.exec_time_type ===
                'HALF_HOUR_IN_SPECIFIC_TIME_PERIOD'
            ) {
                let has_error = false
                const specified_times = []

                this.rule_exec_info.time_period_info.forEach((info) => {
                    if (info.start_time && info.end_time) {
                        let arr = info.start_time.split(':')
                        const start_time_value = +arr[0] * 60 + +arr[1]

                        arr = info.end_time.split(':')
                        const end_time_value = +arr[0] * 60 + +arr[1]
                        if (start_time_value > end_time_value) {
                            has_error = true
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.message_start_time_less_than_or_equal_end_time_in_specified_time_period_automated_rule'
                                )
                            )

                            return
                        }

                        if (!info.num.length) {
                            has_error = true
                            this.p__showNotify(
                                'warning',
                                this.$t(
                                    'page.campaign.message_havent_select_day_in_specified_time_period_automated_rule'
                                )
                            )

                            return
                        }

                        info.num.forEach((day) => {
                            const second = +day * 24 * 60
                            specified_times.push([
                                second + start_time_value,
                                second + end_time_value
                            ])
                        })
                    } else {
                        has_error = true
                        return
                    }
                })

                if (this.checkOverlap(specified_times)) {
                    has_error = true
                    this.p__showNotify(
                        'warning',
                        this.$t(
                            'page.campaign.message_overlap_specified_time_period_automated_rule'
                        )
                    )
                }

                if (has_error) {
                    return
                }
            }

            this.m__loading = true

            try {
                const conditions = this.selected_conditions.map((item) => {
                    if (!item.calculation_type) {
                        delete item.calculation_type
                    }

                    return {
                        ...item,
                        values: item.values.map((i) => `${i}`)
                    }
                })

                await updateAutomatedRule(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    rule_id: this.edited_item._id,
                    rule: {
                        name: this.rule_name,
                        notification: this.notification,
                        rule_exec_info: this.rule_exec_info,
                        apply_objects: [this.selected_apply_object],
                        actions: [...this.selected_actions],
                        conditions
                    }
                })

                this.p__showNotify(
                    'success',
                    this.$t('common.create_data_success')
                )
                this.p__event_bus.$emit('eb_reload_list_automated_rules')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        }
    }
}
</script>
