<template>
    <el-dialog
        :visible.sync="visible_in_line"
        :append-to-body="true"
        :show-close="false"
        width="850px"
    >
        <!-- <el-radio-group v-model="radio_choose_kind_config" class="mb-6">
            <el-radio label="list">{{
                $t('page.campaign.kind_config_list')
            }}</el-radio>
            <el-radio label="default">{{
                $t('page.campaign.kind_config_default')
            }}</el-radio>
        </el-radio-group> -->

        <div v-if="radio_choose_kind_config === 'list'">
            <div>
                <div class="flex space-x-6">
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.start_time') }}
                        </p>
                        <el-select
                            v-model="start_time"
                            size="small"
                            filterable
                            class="w-full"
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in time_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.end_time') }}
                        </p>

                        <el-select
                            v-model="end_time"
                            size="small"
                            filterable
                            class="w-full"
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in time_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.config_type') }}
                        </p>

                        <el-select
                            v-model="action"
                            size="small"
                            filterable
                            class="w-full"
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in action_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div v-if="action === 'BID'" class="flex space-x-6 mt-6">
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.action_bid') }}
                        </p>
                        <el-input
                            v-model="bid_value"
                            class="w-full"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append">
                                {{ currency }}
                            </template>
                        </el-input>
                    </div>

                    <div class="flex-1"></div>
                    <div class="flex-1"></div>
                </div>

                <div v-if="action === 'AUTO'" class="flex space-x-6 mt-6">
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.auto_max_cpa') }}
                        </p>
                        <el-input
                            v-model="auto_max_cpa"
                            class="w-full"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append">
                                {{ currency }}
                            </template>
                        </el-input>
                    </div>

                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.auto_min_cpa') }}
                        </p>
                        <el-input
                            v-model="auto_min_cpa"
                            class="w-full"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append">
                                {{ currency }}
                            </template>
                        </el-input>
                    </div>

                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.auto_max_cost') }}
                        </p>
                        <el-input
                            v-model="auto_max_cost"
                            class="w-full"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append"> {{ currency }}</template>
                        </el-input>
                    </div>
                </div>

                <div v-if="action === 'AUTO'" class="flex space-x-6 mt-6">
                    <div class="flex-1">
                        <p class="component-label">
                            {{ $t('page.campaign.auto_max_cost_action') }}
                        </p>
                        <el-select
                            v-model="auto_max_cost_action"
                            size="small"
                            filterable
                            class="w-full"
                            :placeholder="$t('input.placeholder.please_select')"
                        >
                            <el-option
                                v-for="item in action_options.filter(
                                    (item) => item.value !== 'AUTO'
                                )"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="flex-1">
                        <template v-if="auto_max_cost_action === 'BID'">
                            <p class="component-label">
                                {{ $t('page.campaign.auto_max_cost_bid') }}
                            </p>
                            <el-input
                                v-model="auto_max_cost_bid"
                                class="w-full"
                                size="small"
                                type="number"
                                :placeholder="
                                    $t('input.placeholder.please_input')
                                "
                            >
                                <template slot="append">
                                    {{ currency }}
                                </template>
                            </el-input>
                        </template>
                    </div>

                    <div class="flex-1"></div>
                </div>
            </div>

            <div class="flex justify-end">
                <el-button
                    type="primary"
                    icon="el-icon-plus"
                    class="mt-6"
                    size="small"
                    @click="handleAddConfig()"
                >
                    {{ $t('page.campaign.add_config') }}
                </el-button>
            </div>

            <el-table
                class="mt-8"
                :data="config_list"
                height="240"
                max-height="240"
            >
                <el-table-column
                    prop="start_time"
                    :label="$t('page.campaign.start_time')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="end_time"
                    :label="$t('page.campaign.end_time')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="action"
                    :label="$t('page.campaign.config_type')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <div>
                            <span v-if="scope.row.action === 'AUTO'">
                                {{ $t('page.campaign.action_auto') }}
                            </span>
                            <span v-if="scope.row.action === 'BID'">
                                {{ $t('page.campaign.action_bid') }}
                            </span>
                            <span v-if="scope.row.action === 'PAUSE'">
                                {{ $t('page.campaign.action_pause') }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="bid_value"
                    :label="$t('page.campaign.action_bid')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="auto_min_cpa"
                    :label="$t('page.campaign.auto_min_cpa')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="auto_max_cpa"
                    :label="$t('page.campaign.auto_max_cpa')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="auto_max_cost"
                    :label="$t('page.campaign.auto_max_cost')"
                    width="120"
                >
                </el-table-column>
                <el-table-column
                    prop="auto_max_cost_action"
                    :label="$t('page.campaign.auto_max_cost_action')"
                    width="160"
                >
                    <template slot-scope="scope">
                        <div>
                            <span
                                v-if="scope.row.auto_max_cost_action === 'BID'"
                            >
                                {{ $t('page.campaign.action_bid') }}
                            </span>
                            <span
                                v-else-if="
                                    scope.row.auto_max_cost_action === 'AUTO'
                                "
                            >
                                {{ $t('page.campaign.action_auto') }}
                            </span>
                            <span
                                v-else-if="
                                    scope.row.auto_max_cost_action === 'PAUSE'
                                "
                            >
                                {{ $t('page.campaign.action_pause') }}
                            </span>
                            <span v-else>
                                {{ scope.row.auto_max_cost_action }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="auto_max_cost_bid"
                    :label="$t('page.campaign.auto_max_cost_bid')"
                    width="160"
                >
                </el-table-column>
                <el-table-column
                    fixed="right"
                    :label="$t('common.option')"
                    width="120"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            type="danger"
                            icon="el-icon-delete"
                            @click="handleDeleteConfig(scope.row)"
                        >
                            {{ $t('button.delete') }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div v-else-if="radio_choose_kind_config === 'default'">
            <div class="flex space-x-4 mt-6">
                <div class="flex-1">
                    <p class="component-label">
                        {{ $t('page.campaign.auto_max_cost_action') }}
                    </p>
                    <el-select
                        v-model="config_default.action"
                        size="small"
                        filterable
                        class="w-full"
                        :placeholder="$t('input.placeholder.please_select')"
                    >
                        <el-option
                            v-for="item in action_options.filter(
                                (item) => item.value !== 'AUTO'
                            )"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="flex-1">
                    <template v-if="config_default.action === 'BID'">
                        <p class="component-label">
                            {{ $t('page.campaign.auto_max_cost_bid') }}
                        </p>
                        <el-input
                            v-model="config_default.bid_value"
                            class="w-full"
                            size="small"
                            type="number"
                            :placeholder="$t('input.placeholder.please_input')"
                        >
                            <template slot="append"> {{ currency }}</template>
                        </el-input>
                    </template>
                </div>

                <div class="flex-1"></div>
            </div>
        </div>

        <template slot="title">
            <div class="flex">
                <div
                    class="text-left truncate w-4/6 text-base font-semibold"
                >
                    {{
                        is_edit_form
                            ? `${$t('common.edit')} ${$t(
                                  'page.campaign.automatic_cpa_optimization'
                              )} - #${edit_auto_ads.campaign.campaign_id}`
                            : $t('page.campaign.automatic_cpa_optimization')
                    }}
                </div>
                <div class="w-2/6 text-right">
                    <el-switch
                        v-if="is_edit_form"
                        v-model="status"
                        :active-text="
                            status ? $t('common.enable') : $t('common.disable')
                        "
                    >
                    </el-switch>
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    v-if="is_edit_form"
                    type="danger"
                    class="ml-auto"
                    size="small"
                    @click="handleConfirmDelete()"
                >
                    {{ $t('button.delete') }}
                </el-button>

                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    :disabled="!config_list.length"
                    @click="handleCreateConfigAuto()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import {
    setConfigAds,
    deleteConfigAds
} from '@/services/atosa-tiktok-ads/auto-ads'

const default_data = {
    start_time: '00:00',
    end_time: '00:00',
    action: 'AUTO',
    bid_value: null,
    auto_min_cpa: null,
    auto_max_cpa: null,
    auto_max_cost: null,
    auto_max_cost_action: 'BID',
    auto_max_cost_bid: null,
    config_default: {
        action: 'BID',
        bid_value: null
    }
}

export default {
    props: [
        'edit_auto_ads',
        'visible',
        'campaign_ids',
        'advertiser_id',
        'currency',
        'tiktok_account_id'
    ],

    data() {
        const genRangeTime = () => {
            const options = []
            const list_number = [...Array(24).keys()]
            for (const iterator of list_number) {
                let hour = `${iterator}`
                if (iterator < 10) {
                    hour = `0${iterator}`
                }
                options.push({
                    label: `${hour}:00`,
                    value: `${hour}:00`
                })
                options.push({
                    label: `${hour}:30`,
                    value: `${hour}:30`
                })
            }
            return options
        }

        return {
            ...default_data,
            status: false,
            radio_choose_kind_config: 'list',
            time_options: genRangeTime(),
            is_edit_form: false,
            config_list: [],
            action_options: [
                {
                    label: this.$t('page.campaign.action_auto'),
                    value: 'AUTO'
                },
                {
                    label: this.$t('page.campaign.bid'),
                    value: 'BID'
                },
                {
                    label: this.$t('page.campaign.action_pause'),
                    value: 'PAUSE'
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            this.refreshData()
            this.config_list = []
            this.is_edit_form = false

            if (this.visible_in_line && this.edit_auto_ads) {
                this.config_list = this.edit_auto_ads.auto_ads.config_list
                this.is_edit_form = true
                this.status = this.edit_auto_ads.auto_ads.status === 'ENABLE'
            }
        }
    },

    methods: {
        refreshData() {
            Object.keys(default_data).forEach((key) => {
                this[key] = default_data[key]
            })
        },

        handleAddConfig() {
            const config = {
                id: this.config_list.length + 1,
                start_time: this.start_time,
                end_time: this.end_time,
                action: this.action,
                bid_value: '-',
                auto_min_cpa: '-',
                auto_max_cpa: '-',
                auto_max_cost: '-',
                auto_max_cost_action: '-',
                auto_max_cost_bid: '-'
            }

            if (this.action === 'BID') {
                config.bid_value = this.bid_value
            } else if (this.action === 'AUTO') {
                config.auto_min_cpa = this.auto_min_cpa
                config.auto_max_cpa = this.auto_max_cpa
                config.auto_max_cost = this.auto_max_cost
                config.auto_max_cost_action = this.auto_max_cost_action

                if (this.auto_max_cost_action === 'BID') {
                    config.auto_max_cost_bid = this.auto_max_cost_bid
                }
            }

            this.config_list = [config, ...this.config_list]

            this.refreshData()
        },

        handleDeleteConfig(item) {
            const index = this.config_list.findIndex((i) => i.id === item.id)
            if (index !== -1) {
                this.config_list.splice(index, 1)
            }
        },

        async handleCreateConfigAuto() {
            if (!this.campaign_ids.length) {
                return
            }

            this.m__loading = true

            const config_list = this.config_list.map((config) => {
                Object.keys(config).forEach(
                    (k) => config[k] === '-' && delete config[k]
                )

                return config
            })

            const status = this.is_edit_form
                ? this.status
                    ? 'ENABLE'
                    : 'DISABLE'
                : 'ENABLE'

            try {
                await setConfigAds(this.tiktok_account_id, {
                    campaign_ids: this.campaign_ids,
                    advertiser_id: this.advertiser_id,
                    config_default: this.config_default,
                    config_list,
                    status
                })

                this.p__showNotify(
                    'success',
                    this.is_edit_form
                        ? this.$t(
                            'page.campaign.message_edit_config_auto_success'
                        )
                        : this.$t(
                            'page.campaign.message_create_config_auto_success'
                        )
                )

                this.p__event_bus.$emit('eb_reload_campaign')

                this.m__loading = false

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
                this.p__showNotify(
                    'error',
                    this.is_edit_form
                        ? this.$t(
                            'page.campaign.message_edit_config_auto_failed'
                        )
                        : this.$t(
                            'page.campaign.message_create_config_auto_failed'
                        )
                )
            }

            this.m__loading = false
        },

        handleConfirmDelete() {
            this.$confirm(
                this.$t('page.campaign.message_confirm_delete'),
                this.$t('common.warning'),
                {
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.handleDeleteAutoAds()
            })
        },

        async handleDeleteAutoAds() {
            if (!this.edit_auto_ads) {
                return
            }
            try {
                await deleteConfigAds(this.tiktok_account_id, {
                    campaign_ids: this.campaign_ids,
                    advertiser_id: this.advertiser_id
                })

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_delete_config_auto_success')
                )

                this.p__event_bus.$emit('eb_reload_campaign')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_delete_config_auto_failed')
                )
            }
        }
    }
}
</script>
