<template>
    <section>
        <div class="flex items-center gap-2 flex-wrap">
            <el-button
                v-if="has_manager_ads"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="handleRedirectToCreateCampaign()"
            >
                {{ $t('button.create') }}
            </el-button>

            <el-dropdown
                trigger="click"
                :disabled="!multiple_selection_inline.length"
            >
                <el-button size="small">
                    {{ $t('common.batch_editing') }}
                    <i class="el-icon-arrow-down"></i>
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOn">
                            <span>
                                <i class="el-icon-video-play"></i>
                                {{ $t('common.turn_on') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOff">
                            <span>
                                <i class="el-icon-video-pause"></i>
                                {{ $t('common.turn_off') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="show_edit_multi_name_dialog = true">
                            <span>
                                {{ $t('common.batch_name_adjustment') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <div v-if="has_auto_ads">
                <el-dropdown trigger="click">
                    <el-button size="small">
                        {{ $t('common.automatic_rule') }}
                        <i class="el-icon-arrow-down"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <div
                                @click="
                                    is_create_automatic_rule_drawer_displayed = true
                                "
                            >
                                <p>
                                    {{ $t('page.campaign.create_rule') }}
                                </p>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div
                                @click="
                                    is_manage_automatic_rule_drawer_displayed = true
                                "
                            >
                                <p>
                                    {{ $t('page.campaign.manage_rule') }}
                                </p>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <div class="!ml-auto"></div>

            <el-select
                v-model="m__type_statistical_detail"
                size="small"
                filterable
                class="w-[190px]"
                :placeholder="$t('input.placeholder.please_select')"
            >
                <el-option
                    :label="$t('common.detailed_analysis_none')"
                    :value="'none'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_day')"
                    :value="'day'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_week')"
                    :value="'week'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_month')"
                    :value="'month'"
                >
                </el-option>
            </el-select>

            <el-dropdown>
                <el-button size="small" class="!ml-0">
                    {{ $t('button.column_display') }}
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    }
                                ])
                            "
                        >
                            {{ $t('button.default_column') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    },
                                    {
                                        value: 'ON_SITE_EVENT',
                                        child_values: [
                                            'onsite_shopping',
                                            'onsite_initiate_checkout_count',
                                            'onsite_on_web_detail',
                                            'total_onsite_shopping_value',
                                            'onsite_shopping_roas',
                                            'live_views',
                                            'video_play_actions'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.shop_ads') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: [
                                            'cost_per_1000_reached',
                                            'frequency',
                                            'clicks',
                                            'cpc',
                                            'ctr'
                                        ]
                                    },

                                    {
                                        value: 'ENGAGEMENT',
                                        child_values: [
                                            'engagements',
                                            'engagement_rate',
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    },
                                    {
                                        value: 'VIDEO_PLAY',
                                        child_values: [
                                            'video_watched_2s',
                                            'video_watched_6s',
                                            'video_views_p25',
                                            'video_views_p50',
                                            'video_views_p75',
                                            'video_views_p100',
                                            'average_video_play_per_user',
                                            'average_video_play'
                                        ]
                                    },
                                    {
                                        value: 'INTERACTIVE_ADD_ON',
                                        child_values: [
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.reach') }}
                        </p>
                    </el-dropdown-item>

                    <el-dropdown-item divided>
                        <p @click="show_custom_column_dialog = true">
                            {{ $t('button.custom_column') }}
                        </p></el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>

            <div
                class="cursor-pointer p-2 rounded-lg bg-bg"
                :class="[
                    m__data_for_pagination.length ? '' : 'cursor-not-allowed'
                ]"
                @click="m__handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <div class="relative mt-2">
            <el-table
                ref="multipleTable"
                v-loading="m__loading"
                class="campaign-table"
                :show-summary="!name_filtering"
                max-height="560"
                row-key="id"
                lazy
                :tree-props="{
                    children: 'children',
                    hasChildren: '_has_children'
                }"
                :load="m__handleLoadExpand"
                :data="m__data_for_pagination"
                :summary-method="m__getSummaries"
                @selection-change="handleSelectionChange"
                @sort-change="m__handleSortChange"
            >
                <el-table-column
                    type="selection"
                    width="40"
                    :fixed="true"
                    :reserve-selection="true"
                >
                </el-table-column>

                <el-table-column
                    v-if="m__type_statistical_detail !== 'none'"
                    :label="''"
                    :fixed="true"
                    width="60"
                >
                </el-table-column>

                <el-table-column
                    :label="$t('table.header.on_off')"
                    :fixed="true"
                    width="60"
                >
                    <template #default="scope">
                        <el-switch
                            v-if="!scope.row.is_child"
                            :disabled="
                                scope.row.ad.secondary_status ===
                                'CAMPAIGN_STATUS_DELETE'
                            "
                            :value="scope.row.ad.operation_status === 'ENABLE'"
                            @change="handleChangeStatusAd(scope.row.ad.ad_id)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="320"
                    prop="ad.ad_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div v-if="scope.row.is_child">
                            <p class="truncate max-w-[240px]">
                                {{ scope.row.child_name }}
                            </p>
                        </div>
                        <div v-else>
                            <div class="flex space-x-4 items-center">
                                <div>
                                    <div
                                        class="w-[100px] h-[56px] border-border border-[1px] rounded overflow-hidden"
                                    >
                                        <div
                                            v-if="
                                                scope.row.ad.image_ids.length &&
                                                scope.row.ad.image_ids[0]
                                            "
                                            class="w-full h-full relative"
                                        >
                                            <img
                                                v-if="scope.row.ad.video_id"
                                                :src="`https://p21-ad-sg.ibyteimg.com/medium/${scope.row.ad.image_ids[0]}`"
                                                class="w-full h-full object-cover cursor-pointer"
                                                @click="
                                                    handleChooseVideo(
                                                        scope.row.ad.video_id
                                                    )
                                                "
                                            />
                                            <img
                                                v-else
                                                :src="`https://p21-ad-sg.ibyteimg.com/medium/${scope.row.ad.image_ids[0]}`"
                                                class="w-full h-full object-cover"
                                            />

                                            <div
                                                v-if="scope.row.ad.video_id"
                                                class="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 absolute cursor-pointer"
                                                @click="
                                                    handleChooseVideo(
                                                        scope.row.ad.video_id
                                                    )
                                                "
                                            >
                                                <svg
                                                    stroke="currentColor"
                                                    fill="currentColor"
                                                    stroke-width="0"
                                                    viewBox="0 0 512 512"
                                                    height="1em"
                                                    width="1em"
                                                    class="text-2xl text-bg"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
                                                    ></path>
                                                </svg>
                                            </div>
                                        </div>

                                        <div
                                            v-else
                                            class="w-full h-full util-flex-center bg-bg opacity-80"
                                        >
                                            <svg
                                                stroke="currentColor"
                                                fill="currentColor"
                                                stroke-width="0"
                                                viewBox="0 0 24 24"
                                                height="1em"
                                                width="1em"
                                                class="text-2xl text-[#cac2c2]"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill="none"
                                                    d="M0 0h24v24H0V0z"
                                                ></path>
                                                <path
                                                    d="M21 6h-7.59l3.29-3.29L16 2l-4 4-4-4-.71.71L10.59 6H3a2 2 0 00-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V8a2 2 0 00-2-2zm0 14H3V8h18v12zM9 10v8l7-4z"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>

                                    <p
                                        class="text-center mt-1 hover:underline hover:text-brand-atosa--light cursor-pointer"
                                        @click="
                                            handleChooseAdToPreview(
                                                scope.row.id
                                            )
                                        "
                                    >
                                        {{ $t('common.preview') }}
                                    </p>
                                </div>

                                <div
                                    class="group hover:text-brand-atosa--light"
                                >
                                    <div class="group flex items-center">
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="scope.row.ad.ad_name"
                                            placement="top"
                                        >
                                            <p class="truncate max-w-[240px]">
                                                {{ scope.row.ad.ad_name }}
                                            </p>
                                        </el-tooltip>

                                        <el-tooltip
                                            effect="dark"
                                            :content="$t('common.edit_name')"
                                            placement="top"
                                        >
                                            <div
                                                v-if="
                                                    [
                                                        'AD_STATUS_CAMPAIGN_DELETE',
                                                        'AD_STATUS_ADGROUP_DELETE',
                                                        'AD_STATUS_DELETE'
                                                    ].every(
                                                        (item) =>
                                                            item !==
                                                            scope.row.ad
                                                                .secondary_status
                                                    ) && has_manager_ads
                                                "
                                                class="ml-2 group-hover:block hidden cursor-pointer"
                                                @click="
                                                    handleSelectRowToEditName(
                                                        scope.row.ad
                                                    )
                                                "
                                            >
                                                <i
                                                    class="el-icon-edit text-xs font-semibold"
                                                ></i>
                                            </div>
                                        </el-tooltip>
                                    </div>

                                    <div
                                        v-if="
                                            [
                                                'AD_STATUS_CAMPAIGN_DELETE',
                                                'AD_STATUS_ADGROUP_DELETE',
                                                'AD_STATUS_DELETE'
                                            ].every(
                                                (item) =>
                                                    item !==
                                                    scope.row.ad
                                                        .secondary_status
                                            )
                                        "
                                        class="flex items-center mt-1 space-x-3"
                                    >
                                        <div
                                            v-if="has_report_ads"
                                            class="hover:underline flex items-center space-x-1 cursor-pointer"
                                            @click="
                                                handleOpenViewDataDrawer(
                                                    scope.row
                                                )
                                            "
                                        >
                                            <font-awesome-icon
                                                icon="fa-solid fa-chart-area"
                                            ></font-awesome-icon>
                                            <p>
                                                {{ $t('button.view_data') }}
                                            </p>
                                        </div>

                                        <div
                                            v-if="has_manager_ads"
                                            class="hover:underline flex items-center space-x-1 cursor-pointer"
                                            @click="
                                                handleOpenEditDrawer(scope.row)
                                            "
                                        >
                                            <font-awesome-icon
                                                icon="fa-regular fa-pen-to-square"
                                            ></font-awesome-icon>
                                            <p>
                                                {{ $t('common.edit') }}
                                            </p>
                                        </div>
                                        <!-- <el-tooltip
                                v-if="
                                    ['DIRECT_LIVE', 'SHORT_VIDEO_LIVE'].some(
                                        (item) =>
                                            item === scope.row.ad.creative_type
                                    )
                                "
                                class="item"
                                effect="dark"
                                :content="
                                    $t(
                                        'page.campaign.message_live_ads_cant_duplicated'
                                    )
                                "
                                placement="top"
                            >
                                <div
                                    class="flex items-center space-x-1 opacity-40"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-copy"
                                    />
                                    <p>
                                        {{ $t('common.copy') }}
                                    </p>
                                </div>
                            </el-tooltip>

                            <div
                                v-else
                                class="flex items-center space-x-1 cursor-pointer hover:underline"
                                @click="handleOpenConfirmCopy(scope.row)"
                            >
                                <font-awesome-icon icon="fa-regular fa-copy" />
                                <p>
                                    {{ $t('common.copy') }}
                                </p>
                            </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    label="ID"
                >
                    <template #default="scope">
                        <p>
                            {{ scope.row.id }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.status')" width="160px">
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <div class="flex items-center space-x-2">
                                <div
                                    class="h-2 w-2 rounded-full"
                                    :style="{
                                        background: [
                                            'AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
                                            'AD_STATUS_DELIVERY_OK',
                                            'AD_STATUS_DONE'
                                        ].some(
                                            (item) =>
                                                item ===
                                                scope.row.ad.secondary_status
                                        )
                                            ? '#26cd26'
                                            : 'red'
                                    }"
                                ></div>
                                <p class="font-medium">
                                    {{
                                        renderLabelBySecondaryStatus(
                                            scope.row.ad.secondary_status
                                        )
                                    }}
                                </p>
                            </div>
                            <el-tooltip
                                effect="dark"
                                :content="
                                    renderDescriptionBySecondaryStatus(
                                        scope.row.ad.secondary_status
                                    )
                                "
                                placement="top"
                            >
                                <p
                                    class="text-desc-text text-xs truncate w-[160px]"
                                >
                                    {{
                                        renderDescriptionBySecondaryStatus(
                                            scope.row.ad.secondary_status
                                        )
                                    }}
                                </p>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="200"
                    prop="ad.adgroup_name"
                    :label="$t('table.header.ad_group_name')"
                >
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p class="text-xs text-desc-text">
                                #{{ scope.row.ad.adgroup_id }}
                            </p>
                            <div
                                class="group cursor-pointer"
                                @click="
                                    handle_select_row(
                                        scope.row.ad.adgroup_id,
                                        true
                                    )
                                "
                            >
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    :content="scope.row.ad.adgroup_name"
                                    placement="top"
                                >
                                    <p
                                        class="group-hover:underline truncate font-semibold text-brand-atosa--light"
                                    >
                                        {{ scope.row.ad.adgroup_name }}
                                    </p>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in m__selected_columns"
                    :key="index"
                    :width="m__getContentWidthColumn(item)"
                    :show-overflow-tooltip="true"
                    :prop="item"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            class="item"
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>
                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>

                    <template #default="scope">
                        <p>
                            {{
                                m__formatterColumn(
                                    scope.row[item],
                                    item,
                                    scope.row.currency
                                )
                            }}
                        </p>
                        <p
                            v-if="scope.row._compare && switch_compare"
                            class="text-desc-text"
                        >
                            {{
                                m__formatterColumn(
                                    scope.row._compare[item],
                                    item,
                                    scope.row.currency
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>

            <div
                v-if="!m__data_for_pagination.length"
                class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
                <div class="util-flex-center flex-col">
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="mt-2 text-desc-text text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex space-x-2 mt-2">
            <div class="!ml-auto"></div>

            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <el-tooltip
                    effect="dark"
                    :content="$t('page.campaign.desc_auto_refresh')"
                    placement="top"
                >
                    <p
                        class="text-xs mx-1"
                        @click="m__show_config_auto_refresh_dialog = true"
                    >
                        {{ $t('page.campaign.auto_refresh') }}
                    </p>
                </el-tooltip>

                <i
                    class="el-icon-edit text-xs font-semibold"
                    @click="m__show_config_auto_refresh_dialog = true"
                ></i>
            </div>

            <el-select
                v-model="m__page_info.page_size"
                filterable
                class="w-[120px]"
                size="small"
                @change="
                    m__handleChangePagesize(m__getReportCampaignsByAccount)
                "
            >
                <el-option
                    v-for="item in m__list_pagesize"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-input-number
                v-model="m__page_info.page"
                class="w-[120px]"
                size="small"
                :min="1"
                :max="m__page_info.total_page"
                @change="m__getReportCampaignsByAccount"
            ></el-input-number>

            <el-button
                icon="el-icon-refresh"
                size="small"
                @click="m__getReportCampaignsByAccount"
            ></el-button>
        </div>

        <EditDrawer
            :visible.sync="is_edit_drawer_displayed"
            :selected_item="active_item_for_edit"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :bc_id="bc_id"
        ></EditDrawer>

        <CustomColumnDialog
            :column_values.sync="m__performance_column_values"
            :visible.sync="show_custom_column_dialog"
        ></CustomColumnDialog>

        <ViewDataDrawer
            :visible.sync="is_view_data_drawer_displayed"
            :selected_item="active_item_for_view_data"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :belong_to_atosa="belong_to_atosa"
            type="ad_id"
        ></ViewDataDrawer>

        <CreateAutomaticRuleDrawer
            :visible.sync="is_create_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
            :multiple_selection="multiple_selection"
            type="AD"
        ></CreateAutomaticRuleDrawer>

        <ManageAutomaticRuleDrawer
            :visible.sync="is_manage_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
        ></ManageAutomaticRuleDrawer>

        <ConfirmCopyDialog
            :visible.sync="is_confirm_copy_dialog_displayed"
            :handle_confirm_copy="handleConfirmCopy"
            :objective_type.sync="selected_copy_objective_type"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :active_item_for_copy="active_item_for_copy"
        ></ConfirmCopyDialog>

        <el-dialog
            :visible.sync="visible_edit_name_dialog"
            width="400px"
            class="el-dialog-center"
            :show-close="false"
            :append-to-body="true"
            :close-on-click-modal="true"
        >
            <template slot="title">
                <p class="text-left truncate w-4/5 text-base font-semibold">
                    {{ `${$t('common.edit_name')}` }}
                </p>
                <p
                    v-if="selected_row_to_edit_name"
                    class="text-desc-text truncate w-4/5 text-xs"
                >
                    #{{ selected_row_to_edit_name.campaign_id }}
                </p>
            </template>

            <div v-if="selected_row_to_edit_name">
                <el-input
                    v-model="selected_row_to_edit_name.ad_name"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                ></el-input>
            </div>

            <template slot="footer">
                <div
                    v-if="selected_row_to_edit_name"
                    class="flex items-center justify-end"
                >
                    <el-button
                        size="small"
                        @click="visible_edit_name_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>
                    <el-button
                        :loading="m__loading"
                        type="primary"
                        size="small"
                        :disabled="!selected_row_to_edit_name.ad_name"
                        @click="handleConfirmEditNameRow"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="m__show_config_auto_refresh_dialog"
            width="260px"
            class="el-dialog-center"
            :show-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <p class="text-left truncate w-4/5 text-base font-semibold">
                    {{ $t('common.config_auto_refresh') }}
                </p>
            </template>

            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <p
                    class="text-xs mx-1"
                    @click="m__show_config_auto_refresh_dialog = true"
                >
                    {{ $t('page.campaign.auto_refresh') }}
                </p>
            </div>

            <div class="flex flex-col mt-2">
                <p class="mb-1">{{ $t('common.refresh_second') }}</p>
                <div>
                    <el-input-number
                        v-model="m__auto_refresh_config.seconds"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="60"
                        @change="m__handleChangeSecondAutoRefresh"
                    ></el-input-number>
                    <span>
                        {{ $t('common.second') }}
                    </span>
                </div>

                <p class="mb-1 mt-2">{{ $t('common.config') }}</p>
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="true"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.never_stop') }}</el-radio
                >
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="false"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.stop_after') }}</el-radio
                >
                <div v-if="!m__auto_refresh_config.never_stop">
                    <el-input-number
                        v-model="m__auto_refresh_config.stop_after_minutes"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="1"
                        @change="m__handleChangeNeverstop"
                    ></el-input-number>
                    <span>{{ $t('common.minute') }}</span>
                </div>
            </div>
        </el-dialog>

        <ShowEditMultiNameDrawer
            :visible.sync="show_edit_multi_name_dialog"
            :multiple_selection="multiple_selection_inline"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></ShowEditMultiNameDrawer>

        <InfoVideoDialog
            :visible.sync="show_info_video_dialog"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :video_id="select_video_id"
        ></InfoVideoDialog>

        <ShowPreviewDialog
            :visible.sync="show_preview_dialog"
            :advertiser_id="advertiser_id"
            :ad_id="select_ad_id_to_preview"
        ></ShowPreviewDialog>
    </section>
</template>

<script>
import campaignMixin from '../_mixin'
import { updateAdStatus, updateAd } from '@/services/atosa-tiktok-ads/ad'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/custom-column-dialog'
import ConfirmCopyDialog from './confirm-copy-dialog'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import EditDrawer from './edit-drawer'
import ViewDataDrawer from '@/views/campaign/campaign-wrapper/_components/view-data-drawer'
import CreateAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer'
import ManageAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-manage-automatic-rule-drawer'
import ExcelSvg from '@/assets/svgs/excel.svg'
import ShowEditMultiNameDrawer from './show-edit-multi-name-drawer'
import ShowPreviewDialog from './show-preview-dialog'

export default {
    components: {
        ShowEditMultiNameDrawer,
        ShowPreviewDialog,
        ExcelSvg,
        ViewDataDrawer,
        EditDrawer,
        CustomColumnDialog,
        ManageAutomaticRuleDrawer,
        CreateAutomaticRuleDrawer,
        ConfirmCopyDialog
    },

    mixins: [campaignMixin],

    props: [
        'bc_id',
        'multiple_selection_adgroup',
        'multiple_selection_campaign'
    ],

    data() {
        return {
            filter_adgroup_ids: [],
            select_ad_id_to_preview: '',
            select_video_id: '',
            show_preview_dialog: false,
            show_info_video_dialog: false,
            show_edit_multi_name_dialog: false,
            is_manage_automatic_rule_drawer_displayed: false,
            is_create_automatic_rule_drawer_displayed: false,
            active_item_for_view_data: null,
            is_view_data_drawer_displayed: false,
            is_edit_drawer_displayed: false,
            active_item_for_edit: null,
            visible_edit_name_dialog: false,
            selected_row_to_edit_name: null,
            selected_copy_objective_type: '',
            list_adgroups: [],
            show_custom_column_dialog: false,
            is_confirm_copy_dialog_displayed: false,
            is_copy_drawer_displayed: false,
            active_item_for_copy: null
        }
    },

    computed: {
        multiple_selection_inline: {
            get() {
                return this.multiple_selection
            },
            set(value) {
                this.$emit('update:multiple_selection', value)
            }
        }
    },

    watch: {
        multiple_selection() {
            if (!this.multiple_selection_inline.length) {
                this.$refs.multipleTable.clearSelection([])
            }
        },

        multiple_selection_campaign() {
            const indexFilterById = this.m__filtering.findIndex(
                (item) => item.field_name === 'campaign_ids'
            )

            if (indexFilterById > -1) {
                this.m__filtering.splice(indexFilterById, 1)
            }

            if (this.multiple_selection_campaign.length) {
                const filter = {
                    filter_value: JSON.stringify(
                        this.multiple_selection_campaign.map((item) => item.id)
                    ),
                    field_name: 'campaign_ids',
                    filter_type: 'IN'
                }
                this.m__filtering.push(filter)
            }
            this.m__page_info.page = 1

            this.m__getReportCampaignsByAccount()
        },

        multiple_selection_adgroup() {
            const indexFilterById = this.m__filtering.findIndex(
                (item) => item.field_name === 'adgroup_ids'
            )

            if (indexFilterById > -1) {
                this.m__filtering.splice(indexFilterById, 1)
            }

            if (this.multiple_selection_adgroup.length) {
                const filter = {
                    filter_value: JSON.stringify(
                        this.multiple_selection_adgroup.map((item) => item.id)
                    ),
                    field_name: 'adgroup_ids',
                    filter_type: 'IN'
                }
                this.m__filtering.push(filter)
            }
            this.m__page_info.page = 1

            this.m__getReportCampaignsByAccount()
        }
    },

    mounted() {
        this.p__event_bus.$on(
            'eb_reload_ad',
            this.m__getReportCampaignsByAccount
        )

        this.p__event_bus.$on(
            'eb_get_filter_adgroup_ids',
            this.getFilterAdgroupIds
        )

        this.m__type = 'ad_id'
        this.m__getReportCampaignsByAccount()

        const temp = localStorage.getItem(
            `performance_column_values_${this.m__type}`
        )
        if (temp && temp.length) {
            this.m__performance_column_values = JSON.parse(temp)
        } else {
            this.m__performance_column_values = [
                {
                    value: 'BASIC_DATA',
                    child_values: this.p__basic_metrics
                }
            ]
        }
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_ad',
            this.m__getReportCampaignsByAccount
        )

        this.p__event_bus.$off(
            'eb_get_filter_adgroup_ids',
            this.getFilterAdgroupIds
        )
    },

    methods: {
        getFilterAdgroupIds(id_list) {
            this.filter_adgroup_ids = id_list

            this.m__getReportCampaignsByAccount()
        },

        handleChooseAdToPreview(ad_id) {
            this.select_ad_id_to_preview = ad_id
            this.show_preview_dialog = true
        },

        handleChooseVideo(video_id) {
            this.select_video_id = video_id
            this.show_info_video_dialog = true
        },

        handleRedirectToCreateCampaign() {
            if (this.multiple_selection_adgroup.length === 1) {
                this.$router.push({
                    path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?adgroup_id=${this.multiple_selection_adgroup[0].adgroup.adgroup_id}`
                })
                return
            }

            if (
                this.multiple_selection_adgroup.length === 0 &&
                this.multiple_selection_campaign.length === 1
            ) {
                this.$router.push({
                    path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?campaign_id=${this.multiple_selection_campaign[0].campaign.campaign_id}`
                })
                return
            }

            this.$router.push({
                path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}`
            })
        },

        handleOpenViewDataDrawer(row) {
            this.active_item_for_view_data = { ...row }
            this.is_view_data_drawer_displayed = true
        },

        renderLabelBySecondaryStatus(status) {
            if (!status) {
                return this.$t('common.not_delivering')
            }

            if (
                [
                    'AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
                    'AD_STATUS_DELIVERY_OK'
                ].some((item) => item === status)
            ) {
                return this.$t('common.active')
            }

            if (
                [
                    'AD_STATUS_CAMPAIGN_DISABLE',
                    'AD_STATUS_ADGROUP_DISABLE',
                    'AD_STATUS_DISABLE'
                ].some((item) => item === status)
            ) {
                return this.$t('common.inactive')
            }

            if (
                [
                    'AD_STATUS_CAMPAIGN_DELETE',
                    'AD_STATUS_ADGROUP_DELETE',
                    'AD_STATUS_DELETE'
                ].some((item) => item === status)
            ) {
                return this.$t('common.deleted')
            }

            if (status === 'AD_STATUS_DONE') {
                return this.$t('common.completed')
            }

            if (status === 'AD_STATUS_RF_ADGROUP_CLOSED') {
                return this.$t('common.closed')
            }

            if (status === 'ADGROUP_STATUS_FROZEN') {
                return this.$t('common.frozen')
            }

            if (
                [
                    'AD_STATUS_ADVERTISER_AUDIT_DENY',
                    'AD_STATUS_ADVERTISER_AUDIT',
                    'ADVERTISER_CONTRACT_PENDING',
                    'ADVERTISER_ACCOUNT_PUNISH',
                    'AD_STATUS_LIVE_OFFLINE',
                    'AD_STATUS_BALANCE_EXCEED',
                    'AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY',
                    'AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY',
                    'AD_STATUS_ADGROUP_AUDIT_DENY',
                    'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING',
                    'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED',
                    'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY',
                    'AD_STATUS_MUSIC_AUTHORIZATION_MISSING',
                    'AD_STATUS_TRANSCODING_FAIL',
                    'AD_STATUS_AUDIT',
                    'AD_STATUS_REAUDIT',
                    'AD_STATUS_AUDIT_DENY',
                    'AD_STATUS_PROCESS_AUDIO',
                    'AD_STATUS_CAMPAIGN_EXCEED',
                    'AD_STATUS_BUDGET_EXCEED',
                    'AD_STATUS_NOT_START',
                    'AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST',
                    'AD_STATUS_ASSET_AUTHORIZATION_LOST',
                    'AD_STAUS_PIXEL_UNBIND'
                ].some((item) => item === status)
            ) {
                return this.$t('common.not_delivering')
            }

            return status
        },

        renderDescriptionBySecondaryStatus(status) {
            if (status === 'AD_STATUS_CAMPAIGN_DELETE') {
                return this.$t('page.campaign.adgroup_status_campaign_deleted')
            }
            if (status === 'AD_STATUS_ADGROUP_DELETE') {
                return this.$t('page.campaign.adgroup_status_adgroup_deleted')
            }
            if (status === 'AD_STATUS_DELETE') {
                return this.$t('page.campaign.ad_status_ad_deleted')
            }
            if (status === 'AD_STATUS_ADVERTISER_AUDIT_DENY') {
                return this.$t(
                    'page.campaign.campaign_status_account_not_approved'
                )
            }
            if (status === 'AD_STATUS_ADVERTISER_AUDIT') {
                return this.$t(
                    'page.campaign.campaign_status_account_in_review'
                )
            }
            if (status === 'ADVERTISER_CONTRACT_PENDING') {
                return this.$t(
                    'page.campaign.adgroup_status_contract_has_not_taken_effect'
                )
            }
            if (status === 'ADVERTISER_ACCOUNT_PUNISH') {
                return this.$t('page.campaign.adgroup_status_account_penalized')
            }
            if (status === 'AD_STATUS_BALANCE_EXCEED') {
                return this.$t(
                    'page.campaign.adgroup_status_payment_unsuccessful_or_insufficient_balance'
                )
            }
            if (status === 'AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY') {
                return this.$t('page.campaign.adgroup_status_in_review')
            }
            if (status === 'AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY') {
                return this.$t('page.campaign.adgroup_status_in_review')
            }
            if (status === 'AD_STATUS_ADGROUP_AUDIT_DENY') {
                return this.$t('page.campaign.adgroup_status_not_approved')
            }
            if (status === 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING') {
                return this.$t(
                    'page.campaign.adgroup_status_qualification_needed'
                )
            }
            if (status === 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED') {
                return this.$t('page.campaign.adgroup_status_disapproved')
            }
            if (status === 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY') {
                return this.$t('page.campaign.adgroup_status_expired')
            }
            if (status === 'AD_STATUS_MUSIC_AUTHORIZATION_MISSING') {
                return this.$t(
                    'page.campaign.ad_status_music_copyright_missing'
                )
            }
            if (status === 'AD_STATUS_TRANSCODING_FAIL') {
                return this.$t(
                    'page.campaign.ad_status_replace_videos_with_errors_in_the_adgroup'
                )
            }
            if (status === 'AD_STATUS_AUDIT') {
                return this.$t('page.campaign.adgroup_status_in_review')
            }
            if (status === 'AD_STATUS_REAUDIT') {
                return this.$t('page.campaign.adgroup_status_edited_for_review')
            }
            if (status === 'AD_STATUS_AUDIT_DENY') {
                return this.$t('page.campaign.adgroup_status_not_approved')
            }
            if (status === 'AD_STATUS_PROCESS_AUDIO') {
                return this.$t(
                    'page.campaign.ad_status_processing_audio_reupload_if_it_gets_stuck'
                )
            }
            if (status === 'AD_STATUS_CAMPAIGN_EXCEED') {
                return this.$t(
                    'page.campaign.campaign_status_campaign_out_of_budget'
                )
            }
            if (status === 'AD_STATUS_BUDGET_EXCEED') {
                return this.$t(
                    'page.campaign.adgroup_status_ad_group_out_of_budget'
                )
            }
            if (status === 'AD_STATUS_NOT_START') {
                return this.$t(
                    'page.campaign.adgroup_status_outside_of_schedule'
                )
            }
            if (status === 'AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST') {
                return this.$t(
                    'page.campaign.adgroup_status_asset_unavailable_or_authorization_revoked'
                )
            }
            if (status === 'AD_STATUS_ASSET_AUTHORIZATION_LOST') {
                return this.$t(
                    'page.campaign.adgroup_status_asset_unavailable_or_authorization_revoked'
                )
            }
            if (status === 'AD_STAUS_PIXEL_UNBIND') {
                return this.$t(
                    'page.campaign.adgroup_status_pixel_unauthorized'
                )
            }
            if (status === 'AD_STATUS_CAMPAIGN_DISABLE') {
                return this.$t('page.campaign.adgroup_status_campaign_inactive')
            }
            if (status === 'AD_STATUS_ADGROUP_DISABLE') {
                return this.$t('page.campaign.adgroup_status_adgroup_inactive')
            }
            if (status === 'AD_STATUS_RF_ADGROUP_CLOSED') {
                return this.$t('page.campaign.ad_status_adgroup_is_closed')
            }

            if (status === 'AD_STATUS_DONE') {
                return ''
            }

            if (status === 'AD_STATUS_DELIVERY_OK') {
                return ''
            }

            if (status === 'ADGROUP_STATUS_FROZEN') {
                return this.$t('page.campaign.adgroup_status_frozen')
            }

            return status
        },

        handleOpenEditDrawer(row) {
            this.active_item_for_edit = { ...row }
            this.is_edit_drawer_displayed = true
        },

        handleSelectRowToEditName(row) {
            this.visible_edit_name_dialog = true
            this.selected_row_to_edit_name = { ...row }
        },

        async handleConfirmEditNameRow() {
            this.m__loading = true

            try {
                const creative = {
                    ad_id: this.selected_row_to_edit_name.ad_id,
                    ad_name: this.selected_row_to_edit_name.ad_name,
                    belong_to_atosa: this.belong_to_atosa
                }

                removeEmptyParams(creative)

                await updateAd(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    adgroup_id: this.selected_row_to_edit_name.adgroup_id,
                    creatives: [creative],
                    patch_update: true,
                    belong_to_atosa: this.belong_to_atosa
                })

                this.m__getReportCampaignsByAccount()
                this.visible_edit_name_dialog = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row)
                })
            } else {
                this.$refs.multipleTable.clearSelection()
            }
        },

        handleSelectionChange(val) {
            this.multiple_selection_inline = val
        },

        async handleChangeStatusAd(ad_id) {
            const ad = this.m__data_for_pagination.find(
                (item) => item.ad.ad_id === ad_id
            )

            if (!ad) {
                return
            }

            ad.ad.operation_status =
                ad.ad.operation_status === 'ENABLE' ? 'DISABLE' : 'ENABLE'
            try {
                await updateAdStatus(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    ad_ids: [ad_id],
                    belong_to_atosa: this.belong_to_atosa,
                    operation_status: ad.ad.operation_status
                })

                setTimeout(() => {
                    this.m__getReportCampaignsByAccount()
                }, 1500)

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_status_xxx_success', {
                        xxx: this.$t(`common.ad`)
                    })
                )
            } catch (error) {
                console.error(error)

                ad.ad.operation_status =
                    ad.ad.operation_status === 'ENABLE' ? 'DISABLE' : 'ENABLE'

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_update_status_xxx_failed', {
                        xxx: this.$t(`common.ad`)
                    })
                )
            }
        },

        handleOpenConfirmCopy(data) {
            this.active_item_for_copy = data.ad

            this.is_confirm_copy_dialog_displayed = true
        },

        handleConfirmCopy(list_adgroups) {
            this.list_adgroups = list_adgroups

            this.is_copy_drawer_displayed = true
        }
    }
}
</script>
