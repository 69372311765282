<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="50%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ this.$t('page.campaign.adgroup_pane.edit_ad') }}
                    </p>
                    <p v-if="selected_item" class="text-desc-text text-xs">
                        #{{ selected_item.ad.ad_id }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleConfirmEditNameRow()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div
            v-if="selected_adgroup && items.length && objective_type"
            class="px-5 py-8"
        >
            <EditAdForm
                :items.sync="items"
                :tiktok_account_id="tiktok_account_id"
                :belong_to_atosa="belong_to_atosa"
                :advertiser_id="advertiser_id"
                :objective_type="objective_type"
                :bc_id="bc_id"
                :catalog_id="selected_adgroup.catalog_id"
                type_form="edit"
            ></EditAdForm>
        </div>
    </custom-drawer>
</template>

<script>
import { updateAd } from '@/services/atosa-tiktok-ads/ad'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import { getCampaigns } from '@/services/atosa-tiktok-ads/campaign'
import { getAdGroups } from '@/services/atosa-tiktok-ads/adgroup'

export default {
    props: [
        'visible',
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'min_budget_campaign',
        'bc_id',
        'belong_to_atosa'
    ],

    data() {
        return {
            items: [],
            objective_type: '',
            selected_adgroup: null
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        selected_item() {
            this.getStateInfor()
            this.fetchDetailCampaign()
            this.fetchDetailAdgroup()
        }
    },

    mounted() {
        this.getStateInfor()
        this.fetchDetailCampaign()
        this.fetchDetailAdgroup()
    },

    methods: {
        getStateInfor() {
            if (!this.selected_item) {
                return
            }

            this.items = [
                {
                    ...this.selected_item.ad,
                    extension: this.p__default_state_infor_ad.extension
                }
            ]
        },

        async handleConfirmEditNameRow() {
            this.m__loading = true

            try {
                await updateAd(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    adgroup_id: this.selected_item.ad.adgroup_id,
                    belong_to_atosa: this.belong_to_atosa,
                    creatives: this.items.map((item) => {
                        removeEmptyParams(item)

                        if (item.creative_type === 'SHOPPING_SHOP_PAGE') {
                            item.creative_type = 'PSA'
                        }

                        return item
                    })
                })

                this.p__event_bus.$emit('eb_reload_ad')

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchDetailCampaign() {
            if (!this.selected_item) {
                return
            }

            const response = await getCampaigns(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                filtering: JSON.stringify({
                    campaign_ids: [this.selected_item.ad.campaign_id]
                })
            })
            if (
                response.code === 0 &&
                response.data.list.length &&
                response.data.list[0]
            ) {
                const first_campaign = response.data.list[0]

                this.objective_type = first_campaign.objective_type
            }
        },

        async fetchDetailAdgroup() {
            if (!this.selected_item) {
                return
            }

            const response = await getAdGroups(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                filtering: JSON.stringify({
                    adgroup_ids: [this.selected_item.ad.adgroup_id]
                })
            })
            if (
                response.code === 0 &&
                response.data.list.length &&
                response.data.list[0]
            ) {
                this.selected_adgroup = response.data.list[0]
            }
        }
    }
}
</script>
