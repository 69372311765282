<template>
    <el-dialog
        v-loading="m__loading"
        :visible.sync="visible_in_line"
        :append-to-body="true"
        :show-close="false"
        width="1000px"
    >
        <div class="w-[440px]">
            <p class="component-label">
                {{ $t('common.date_range') }}
            </p>
            <el-date-picker
                v-model="ranger_date"
                class="!w-full"
                size="small"
                type="datetimerange"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :format="$t('common.format_value_date_picker_with_time')"
                value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
        </div>

        <div class="grid grid-cols-3 gap-6 mt-6">
            <div
                v-for="item in config_list"
                :key="item.optimization_goal"
                class="flex-1 border-border border-[1px] rounded-lg p-4 relative"
            >
                <div class="absolute -top-4 left-2 bg-white px-2 py-1">
                    <p class="font-semibold text-sm">
                        {{ item.label }}
                    </p>
                </div>
                <div>
                    <p class="component-label">
                        {{ $t('page.campaign.budget_total_rate') }}
                    </p>
                    <el-input
                        v-model="item.budget_total_rate"
                        class="w-full"
                        size="small"
                        type="number"
                        :placeholder="$t('input.placeholder.please_input')"
                    >
                    </el-input>
                </div>
                <div class="mt-4">
                    <p class="component-label">
                        {{ $t('page.campaign.max_cpa') }}
                    </p>
                    <el-input
                        v-model="item.max_cpa"
                        class="w-full"
                        size="small"
                        type="number"
                        :placeholder="$t('input.placeholder.please_input')"
                    >
                        <template slot="append">
                            {{ currency }}
                        </template>
                    </el-input>
                </div>
                <div class="mt-4">
                    <p class="component-label">
                        {{ $t('page.campaign.max_cost') }}
                    </p>
                    <el-input
                        v-model="item.max_cost"
                        class="w-full"
                        size="small"
                        type="number"
                        :placeholder="$t('input.placeholder.please_input')"
                    >
                        <template slot="append">
                            {{ currency }}
                        </template>
                    </el-input>
                </div>
            </div>
        </div>

        <div v-if="is_edit_form" class="mt-6 flex items-center">
            <el-switch
                v-model="status"
                :active-text="
                    status ? $t('common.enable') : $t('common.disable')
                "
            >
            </el-switch>
        </div>

        <template slot="title">
            <div class="text-left truncate w-4/5 text-base font-semibold">
                {{
                    is_edit_form
                        ? `${$t('page.campaign.edit_live_ads')} - #${
                              edit_live_ads.campaign.campaign_id
                          }`
                        : $t('page.campaign.automatic_live_optimization')
                }}
            </div>
            <div></div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="danger"
                    class="ml-auto"
                    size="small"
                    @click="handleConfirmDelete()"
                >
                    {{ $t('button.delete') }}
                </el-button>

                <el-button
                    v-if="is_edit_form"
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    :disabled="!config_list.length"
                    @click="handleCreateLiveAds()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
import {
    setConfigAdsLive,
    deleteConfigAdsLive
} from '@/services/atosa-tiktok-ads/reporting'
import moment from 'moment'

export default {
    props: [
        'edit_live_ads',
        'visible',
        'campaign_ids',
        'advertiser_id',
        'currency',
        'tiktok_account_id'
    ],

    data() {
        const getRangeDateToday = () => {
            const start_date = moment().format('YYYY-MM-DD HH:mm')
            return [start_date, start_date]
        }

        return {
            is_edit_form: false,
            config_list: [
                {
                    label: this.$t(
                        'page.campaign.type_live_ads.product_click_in_live'
                    ),
                    optimization_goal: 'PRODUCT_CLICK_IN_LIVE',
                    budget_total_rate: 0,
                    max_cpa: 0,
                    max_cost: 0
                },
                {
                    label: this.$t('page.campaign.type_live_ads.mt_live_room'),
                    optimization_goal: 'MT_LIVE_ROOM',
                    budget_total_rate: 0,
                    max_cpa: 0,
                    max_cost: 0
                },
                {
                    label: this.$t('page.campaign.type_live_ads.convert'),
                    optimization_goal: 'CONVERT',
                    budget_total_rate: 0,
                    max_cpa: 0,
                    max_cost: 0
                },
                {
                    label: this.$t('page.campaign.type_live_ads.click'),
                    optimization_goal: 'CLICK',
                    budget_total_rate: 0,
                    max_cpa: 0,
                    max_cost: 0
                }
            ],
            ranger_date: getRangeDateToday(),
            status: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            this.is_edit_form = false
            this.refreshData()

            if (this.visible_in_line && this.edit_live_ads) {
                this.config_list = this.config_list.map((item) => {
                    const config = this.edit_live_ads.live_ads.config_list.find(
                        (i) => i.optimization_goal === item.optimization_goal
                    )
                    if (config) {
                        return {
                            ...item,
                            budget_total_rate: config.budget_total_rate,
                            max_cpa: config.max_cpa,
                            max_cost: config.max_cost
                        }
                    }

                    return item
                })

                this.is_edit_form = true
                this.status = this.edit_live_ads.live_ads.status === 'ENABLE'
                this.range_date = [
                    moment(this.edit_live_ads.start_date).format(
                        'YYYY-MM-DD HH:mm'
                    ),
                    moment(this.edit_live_ads.end_date).format(
                        'YYYY-MM-DD HH:mm'
                    )
                ]
            }
        }
    },

    methods: {
        refreshData() {
            this.config_list = this.config_list.map((config) => ({
                ...config,
                budget_total_rate: 0,
                max_cpa: 0,
                max_cost: 0
            }))
        },

        async handleCreateLiveAds() {
            if (!this.campaign_ids.length) {
                return
            }

            this.m__loading = true

            const config_list = this.config_list.map((config) => {
                Object.keys(config).forEach((k) => {
                    if (k === 'label') {
                        delete config[k]
                    }
                })

                return config
            })

            const status = this.is_edit_form
                ? this.status
                    ? 'ENABLE'
                    : 'DISABLE'
                : 'ENABLE'

            try {
                await setConfigAdsLive(this.tiktok_account_id, {
                    campaign_ids: this.campaign_ids,
                    advertiser_id: this.advertiser_id,
                    start_time: `${this.ranger_date[0]}:00`,
                    end_time: `${this.ranger_date[1]}:00`,
                    config_list,
                    status
                })

                this.p__showNotify(
                    'success',
                    this.is_edit_form
                        ? this.$t('page.campaign.message_edit_live_ads_success')
                        : this.$t(
                            'page.campaign.message_create_live_ads_success'
                        )
                )

                this.p__event_bus.$emit('eb_reload_campaign')

                this.m__loading = false

                this.visible_in_line = false
            } catch (error) {
                console.error(error)
                this.p__showNotify(
                    'error',
                    this.is_edit_form
                        ? this.$t('page.campaign.message_edit_live_ads_failed')
                        : this.$t(
                            'page.campaign.message_create_live_ads_failed'
                        )
                )
            }

            this.m__loading = false
        },

        handleConfirmDelete() {
            this.$confirm(
                this.$t('page.campaign.message_confirm_delete'),
                this.$t('common.warning'),
                {
                    confirmButtonText: this.$t('button.confirm'),
                    cancelButtonText: this.$t('button.close'),
                    type: 'warning'
                }
            ).then(() => {
                this.handleDeleteLiveAds()
            })
        },

        async handleDeleteLiveAds() {
            if (!this.edit_live_ads) {
                return
            }
            try {
                await deleteConfigAdsLive(this.tiktok_account_id, {
                    campaign_ids: this.campaign_ids,
                    advertiser_id: this.advertiser_id
                })

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_delete_config_auto_success')
                )

                this.p__event_bus.$emit('eb_reload_campaign')

                this.visible_in_line = false
            } catch (error) {
                console.error(error)

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_delete_config_auto_failed')
                )
            }
        }
    }
}
</script>
