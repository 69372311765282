<template>
    <div>
        <div v-loading="m__loading" class="relative">
            <DailyChart
                :selected_metrics="selected_metrics"
                :data_list="chart_data"
            ></DailyChart>

            <div
                v-if="show_no_data"
                class="w-full h-full bg-white absolute top-0 left-0"
            >
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <div class="util-flex-center flex-col">
                        <img
                            class="w-[140px] h-[140px]"
                            :src="require('@/assets/images/no_data.png')"
                        />
                        <p class="mt-2 text-desc-text text-sm">
                            {{ $t('common.no_data') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <p v-if="switch_compare" class="text-center mt-4 text-sm italic">
            {{ ranger_date[0] | f__format_moment($t('common.format_date')) }} -
            {{ ranger_date[1] | f__format_moment($t('common.format_date')) }}
        </p>

        <div v-if="switch_compare" class="mt-4">
            <div v-loading="m__loading" class="relative">
                <DailyChart
                    :selected_metrics="selected_metrics"
                    :data_list="compare_chart_data"
                ></DailyChart>

                <div
                    v-if="compare_show_no_data"
                    class="w-full h-full bg-white absolute top-0 left-0"
                >
                    <div
                        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    >
                        <div class="util-flex-center flex-col">
                            <img
                                class="w-[140px] h-[140px]"
                                :src="require('@/assets/images/no_data.png')"
                            />
                            <p class="mt-2 text-desc-text text-sm">
                                {{ $t('common.no_data') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="text-center mt-4 text-sm italic">
                {{
                    compare_ranger_date[0]
                        | f__format_moment($t('common.format_date'))
                }}
                -
                {{
                    compare_ranger_date[1]
                        | f__format_moment($t('common.format_date'))
                }}
            </p>
        </div>

        <div class="mt-8">
            <div class="mb-2 flex items-center space-x-2">
                <p class="text-sm font-semibold">
                    {{ $t('common.detailed_analysis') }}
                </p>

                <div class="!ml-auto"></div>

                <el-dropdown>
                    <el-button size="small" class="!ml-0">
                        {{ $t('button.column_display') }}
                    </el-button>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <p
                                @click="
                                    showColumn(
                                        p__trend_basic_chart_options.map(
                                            (item) => item.value
                                        )
                                    )
                                "
                            >
                                {{ $t('button.default_column') }}
                            </p>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <p
                                @click="
                                    showColumn([
                                        ...p__trend_basic_chart_options.map(
                                            (item) => item.value
                                        ),
                                        'onsite_shopping',
                                        'onsite_initiate_checkout_count',
                                        'onsite_on_web_detail',
                                        'total_onsite_shopping_value',
                                        'onsite_shopping_roas',
                                        'live_views'
                                    ])
                                "
                            >
                                {{ $t('common.shop_ads') }}
                            </p>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <p
                                @click="
                                    showColumn([
                                        'cost_per_1000_reached',
                                        'frequency',
                                        'clicks',
                                        'cpc',
                                        'ctr',
                                        'video_watched_2s',
                                        'video_watched_6s',
                                        'video_views_p25',
                                        'video_views_p50',
                                        'video_views_p75',
                                        'video_views_p100',
                                        'video_play_actions',
                                        'average_video_play_per_user',
                                        'average_video_play'
                                    ])
                                "
                            >
                                {{ $t('common.reach') }}
                            </p>
                        </el-dropdown-item>

                        <el-dropdown-item divided>
                            <p @click="show_custom_column_dialog = true">
                                {{ $t('button.custom_column') }}
                            </p></el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
                <div
                    class="cursor-pointer p-2 rounded-lg bg-bg"
                    @click="handleExportExcel(new_table_data, ranger_date)"
                >
                    <el-tooltip
                        class="item"
                        :content="$t(`common.export_excel`)"
                        effect="dark"
                        placement="top"
                    >
                        <ExcelSvg></ExcelSvg>
                    </el-tooltip>
                </div>
            </div>

            <el-table
                ref="multipleTable"
                height="320"
                :border="true"
                :data="new_table_data"
                @sort-change="handleSortChange"
            >
                <el-table-column
                    :show-overflow-tooltip="true"
                    width="160"
                    prop="start_time"
                    :label="$t('common.time')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <p>
                            {{
                                p__formatDate(
                                    scope.row.stat_time_day ||
                                        scope.row.stat_time_hour,
                                    scope.row.stat_time_day
                                        ? $t('common.format_date')
                                        : $t('common.format_fulldate'),
                                    0
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="item in selected_metrics_for_table"
                    :key="item"
                    width="160"
                    :show-overflow-tooltip="true"
                    :prop="item"
                    :resizable="true"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            class="item"
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>

                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>

                    <template #default="scope">
                        <p>
                            {{
                                m__formatterColumn(
                                    scope.row[item],
                                    item,
                                    currency
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>

            <p v-if="switch_compare" class="text-center mt-4 text-sm italic">
                {{
                    ranger_date[0] | f__format_moment($t('common.format_date'))
                }}
                -
                {{
                    ranger_date[1] | f__format_moment($t('common.format_date'))
                }}
            </p>

            <div v-if="switch_compare" class="mt-4">
                <div class="mb-2 flex items-center space-x-2">
                    <div
                        class="cursor-pointer p-2 rounded-lg bg-bg ml-auto"
                        @click="
                            handleExportExcel(
                                compare_new_table_data,
                                compare_ranger_date
                            )
                        "
                    >
                        <el-tooltip
                            class="item"
                            :content="$t(`common.export_excel`)"
                            effect="dark"
                            placement="top"
                        >
                            <ExcelSvg></ExcelSvg>
                        </el-tooltip>
                    </div>
                </div>
                <el-table
                    ref="multiTable"
                    height="320"
                    :border="true"
                    :data="compare_new_table_data"
                    @sort-change="handleSortChange_2"
                >
                    <el-table-column
                        :show-overflow-tooltip="true"
                        width="160"
                        prop="start_time"
                        :label="$t('common.time')"
                        :fixed="true"
                    >
                        <template #default="scope">
                            <p>
                                {{
                                    p__formatDate(
                                        scope.row.stat_time_day ||
                                            scope.row.stat_time_hour,
                                        scope.row.stat_time_day
                                            ? $t('common.format_date')
                                            : $t('common.format_fulldate'),
                                        0
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-for="(item, index) in selected_metrics_for_table"
                        :key="index"
                        width="160"
                        :show-overflow-tooltip="true"
                        :prop="item"
                        :resizable="true"
                        :label="$t(`page.campaign.column_${item}`)"
                        sortable="custom"
                        align="right"
                    >
                        <template v-slot:header>
                            <el-tooltip
                                class="item"
                                :content="$t(`page.campaign.column_${item}`)"
                                effect="dark"
                                placement="top"
                            >
                                <div slot="content" class="max-w-[360px]">
                                    <p
                                        class="text-sm"
                                        v-html="
                                            $t(`page.campaign.column_${item}`)
                                        "
                                    ></p>

                                    <p
                                        v-if="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        "
                                        class="mt-1"
                                        v-html="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        "
                                    ></p>
                                </div>

                                <p>
                                    {{ $t(`page.campaign.column_${item}`) }}
                                </p>
                            </el-tooltip>
                        </template>

                        <template #default="scope">
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row[item],
                                        item,
                                        currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>

                <p class="text-center mt-4 text-sm italic">
                    {{
                        compare_ranger_date[0]
                            | f__format_moment($t('common.format_date'))
                    }}
                    -
                    {{
                        compare_ranger_date[1]
                            | f__format_moment($t('common.format_date'))
                    }}
                </p>
            </div>
        </div>

        <CustomColumnDialog
            :visible.sync="show_custom_column_dialog"
            :column_values.sync="selected_metrics_for_table"
        ></CustomColumnDialog>
    </div>
</template>

<script>
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import moment from 'moment'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_components/custom-column-dialog'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import DailyChart from './daily-chart'

export default {
    components: {
        ExcelSvg,
        DailyChart,
        CustomColumnDialog
    },

    mixins: [campaignMixin],

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'switch_compare',
        'ranger_date',
        'compare_ranger_date',
        'currency',
        'stat_time',
        'selected_metrics',
        'type',
        'data_id'
    ],

    data() {
        return {
            selected_metrics_for_table: [],
            show_custom_column_dialog: false,
            chart_data: [],
            table_data: [],
            order_field: 'spend',
            order_type: 'DESC',
            compare_chart_data: [],
            compare_table_data: [],
            compare_order_field: 'spend',
            compare_order_type: 'DESC'
        }
    },

    computed: {
        metrics() {
            return this.p__trend_chart_options_2.map((item) => item.value)
        },

        show_no_data() {
            if (!this.chart_data.length) {
                return true
            }

            return false
        },

        compare_show_no_data() {
            if (!this.compare_chart_data.length) {
                return true
            }

            return false
        },

        new_table_data() {
            const temp = [...this.table_data]
            temp.reverse()
            return temp
        },

        compare_new_table_data() {
            const temp = [...this.compare_table_data]
            temp.reverse()
            return temp
        }
    },

    watch: {
        ranger_date() {
            this.fetchDailyReport(true)
        },

        compare_ranger_date() {
            this.fetchCompareDailyReport(true)
        },

        data_id() {
            this.fetchDailyReport(true)

            this.fetchCompareDailyReport(true)
        },

        async selected_metrics_for_table() {
            await this.fetchDailyReport(true)

            await this.fetchCompareDailyReport(true)

            if (this.$refs.multipleTable) {
                this.$refs.multipleTable.doLayout()
            }

            if (this.$refs.multiTable) {
                this.$refs.multiTable.doLayout()
            }
        },

        stat_time() {
            this.fetchDailyReport(true)

            this.fetchCompareDailyReport(true)
        },

        switch_compare() {
            this.fetchCompareDailyReport(true)
        }
    },

    mounted() {
        this.fetchDailyReport()

        this.fetchCompareDailyReport(true)

        this.selected_metrics_for_table = this.p__trend_basic_chart_options.map(
            (item) => item.value
        )
    },

    methods: {
        async fetchCompareDailyReport(reset_sort = false) {
            if (!this.switch_compare) {
                return
            }

            if (reset_sort) {
                this.compare_order_field = 'spend'
                this.compare_order_type = 'DESC'
            }

            const { table_data, chart_data } = await this.fetchOverviewReport(
                this.compare_ranger_date,
                this.compare_order_field,
                this.compare_order_type
            )

            this.compare_table_data = table_data
            this.compare_chart_data = chart_data
        },

        async fetchDailyReport(reset_sort = false) {
            if (reset_sort) {
                this.order_field = 'spend'
                this.order_type = 'DESC'
            }

            const { table_data, chart_data } = await this.fetchOverviewReport(
                this.ranger_date,
                this.order_field,
                this.order_type
            )

            this.table_data = table_data
            this.chart_data = chart_data
        },

        showColumn(metric_list) {
            this.selected_metrics_for_table = metric_list
        },

        getDaysInRange(startDate, endDate) {
            const days = []
            const currentDate = moment(startDate)

            while (currentDate.isSameOrBefore(endDate)) {
                days.push([
                    currentDate.clone().format('YYYY-MM-DD'),
                    currentDate.clone().format('YYYY-MM-DD')
                ])
                currentDate.add(1, 'day')
            }

            return days
        },

        async fetchOverviewReport(ranger_date, order_field, order_type) {
            if (!this.data_id) {
                return
            }

            let table_data = []
            let chart_data = []

            this.m__loading = true

            try {
                let data_level = ''
                let field_name = ''
                if (this.type === 'campaign_id') {
                    data_level = 'AUCTION_CAMPAIGN'
                    field_name = 'campaign_ids'
                } else if (this.type === 'adgroup_id') {
                    data_level = 'AUCTION_ADGROUP'
                    field_name = 'adgroup_ids'
                } else if (this.type === 'ad_id') {
                    data_level = 'AUCTION_AD'
                    field_name = 'ad_ids'
                }

                const filtering = [
                    {
                        filter_value: JSON.stringify([this.data_id]),
                        field_name,
                        filter_type: 'IN'
                    }
                ]

                let range_date_list = this.p__getArr30days(ranger_date)

                if (this.stat_time === 'stat_time_hour') {
                    range_date_list = this.getDaysInRange(
                        ranger_date[0],
                        ranger_date[1]
                    )
                }

                const temp_data_list = []

                await Promise.all(
                    range_date_list.map(async (new_ranger_date) => {
                        const start_date = new_ranger_date[0]
                        const end_date = new_ranger_date[1]

                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 40,
                                report_type: 'BASIC',
                                data_level,
                                metrics: JSON.stringify(this.metrics),
                                start_date,
                                end_date,
                                dimensions: [this.stat_time],
                                order_field,
                                order_type,
                                advertiser_id: this.advertiser_id,
                                type: this.type,
                                filtering: JSON.stringify(filtering)
                            }
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            temp_data_list.push(
                                ...response.data.list.map((item) => ({
                                    ...item.dimensions,
                                    ...item.metrics
                                }))
                            )
                        }
                    })
                )

                const temp_table_data = [...temp_data_list]

                // temp_table_data.sort((a, b) =>
                //     moment(a.stat_time_day || a.stat_time_hour).diff(
                //         moment(b.stat_time_day || b.stat_time_hour)
                //     )
                // )

                table_data = temp_table_data
                temp_data_list.sort((a, b) =>
                    moment(a.stat_time_day || a.stat_time_hour).diff(
                        moment(b.stat_time_day || b.stat_time_hour)
                    )
                )

                chart_data = temp_data_list
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return {
                table_data,
                chart_data
            }
        },

        async handleSortChange({ column, prop, order }) {
            this.order_field = prop
            this.order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchDailyReport()
        },

        async handleSortChange_2({ column, prop, order }) {
            this.compare_order_field = prop
            this.compare_order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchCompareDailyReport()
        },

        async handleExportExcel(table_data, ranger_date) {
            const headers = [
                this.$t('common.daily'),
                ...this.metrics.map((item) =>
                    this.$t(`page.campaign.column_${item}`)
                )
            ]

            const body = table_data.map((item) => {
                return [
                    moment(item.stat_time_day || item.stat_time_hour).format(
                        this.$t('common.format_fulldate')
                    ),
                    ...this.metrics.map((me) =>
                        this.m__formatterColumn(item[me], item, this.currency)
                    )
                ]
            })

            const start_time = moment(ranger_date[0]).format(
                this.$t('common.format_fulldate')
            )
            const end_time = moment(ranger_date[1]).format(
                this.$t('common.format_fulldate')
            )

            const name = `overview_${this.data_id}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        }
    }
}
</script>
