<template>
    <div>
        <div v-loading="m__loading" class="relative">
            <Chart
                :getNameByAdId="getNameByAdId"
                :selected_metrics="selected_metrics"
                :data_list="table_data"
            ></Chart>

            <div
                v-if="show_no_data"
                class="w-full h-full bg-white absolute top-0 left-0"
            >
                <div
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                >
                    <div class="util-flex-center flex-col">
                        <img
                            class="w-[140px] h-[140px]"
                            :src="require('@/assets/images/no_data.png')"
                        />
                        <p class="mt-2 text-desc-text text-sm">
                            {{ $t('common.no_data') }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <p v-if="switch_compare" class="text-center mt-4 text-sm italic">
            {{ ranger_date[0] | f__format_moment($t('common.format_date')) }} -
            {{ ranger_date[1] | f__format_moment($t('common.format_date')) }}
        </p>

        <div v-if="switch_compare" class="mt-4">
            <div v-loading="m__loading" class="relative">
                <Chart
                    :getNameByAdId="getNameByAdId"
                    :selected_metrics="selected_metrics"
                    :data_list="compare_table_data"
                ></Chart>

                <div
                    v-if="compare_show_no_data"
                    class="w-full h-full bg-white absolute top-0 left-0"
                >
                    <div
                        class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    >
                        <div class="util-flex-center flex-col">
                            <img
                                class="w-[140px] h-[140px]"
                                :src="require('@/assets/images/no_data.png')"
                            />
                            <p class="mt-2 text-desc-text text-sm">
                                {{ $t('common.no_data') }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <p class="text-center mt-4 text-sm italic">
                {{
                    compare_ranger_date[0]
                        | f__format_moment($t('common.format_date'))
                }}
                -
                {{
                    compare_ranger_date[1]
                        | f__format_moment($t('common.format_date'))
                }}
            </p>
        </div>

        <div class="mt-8">
            <div class="mb-2 flex items-center space-x-2">
                <p class="text-sm font-semibold">
                    {{ $t('common.detailed_analysis') }}
                </p>

                <div class="!ml-auto"></div>

                <el-button
                    size="small"
                    @click="show_custom_column_dialog = true"
                >
                    {{ $t('button.custom_column') }}
                </el-button>
                <div
                    class="cursor-pointer p-2 rounded-lg bg-bg"
                    @click="handleExportExcel(table_data, ranger_date)"
                >
                    <el-tooltip
                        class="item"
                        :content="$t(`common.export_excel`)"
                        effect="dark"
                        placement="top"
                    >
                        <ExcelSvg></ExcelSvg>
                    </el-tooltip>
                </div>
            </div>

            <el-table
                ref="multipleTable"
                height="320"
                :border="true"
                :data="table_data"
                @sort-change="handleSortChange"
            >
                <el-table-column
                    width="210"
                    :show-overflow-tooltip="true"
                    :label="$t('common.ad')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <p class="truncate w-[200px]">
                            {{ getNameByAdId(scope.row.ad_id) }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in selected_metrics_for_table"
                    :key="index"
                    width="160"
                    :show-overflow-tooltip="true"
                    :prop="item"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            class="item"
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>

                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>
                    <template #default="scope">
                        <p>
                            {{
                                m__formatterColumn(
                                    scope.row[item],
                                    item,
                                    currency
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>

            <p v-if="switch_compare" class="text-center mt-4 text-sm italic">
                {{
                    ranger_date[0] | f__format_moment($t('common.format_date'))
                }}
                -
                {{
                    ranger_date[1] | f__format_moment($t('common.format_date'))
                }}
            </p>

            <div v-if="switch_compare" class="mt-4">
                <div class="mb-2 flex items-center space-x-2">
                    <div
                        class="cursor-pointer p-2 rounded-lg bg-bg ml-auto"
                        @click="
                            handleExportExcel(
                                compare_table_data,
                                compare_ranger_date
                            )
                        "
                    >
                        <el-tooltip
                            class="item"
                            :content="$t(`common.export_excel`)"
                            effect="dark"
                            placement="top"
                        >
                            <ExcelSvg></ExcelSvg>
                        </el-tooltip>
                    </div>
                </div>

                <el-table
                    ref="multiTable"
                    height="320"
                    :border="true"
                    :data="compare_table_data"
                    @sort-change="handleSortChange_2"
                >
                    <el-table-column
                        width="210"
                        :show-overflow-tooltip="true"
                        :label="$t('common.ad')"
                        :fixed="true"
                    >
                        <template #default="scope">
                            <p class="truncate w-[200px]">
                                {{ getNameByAdId(scope.row.ad_id) }}
                            </p>
                        </template>
                    </el-table-column>

                    <el-table-column
                        v-for="(item, index) in selected_metrics_for_table"
                        :key="index"
                        width="160"
                        :show-overflow-tooltip="true"
                        :prop="item"
                        :label="$t(`page.campaign.column_${item}`)"
                        sortable="custom"
                        align="right"
                    >
                        <template v-slot:header>
                            <el-tooltip
                                class="item"
                                :content="$t(`page.campaign.column_${item}`)"
                                effect="dark"
                                placement="top"
                            >
                                <div slot="content" class="max-w-[360px]">
                                    <p
                                        class="text-sm"
                                        v-html="
                                            $t(`page.campaign.column_${item}`)
                                        "
                                    ></p>

                                    <p
                                        v-if="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            ) !==
                                            `page.campaign.description_column_${item}`
                                        "
                                        class="mt-1"
                                        v-html="
                                            $t(
                                                `page.campaign.description_column_${item}`
                                            )
                                        "
                                    ></p>
                                </div>

                                <p>
                                    {{ $t(`page.campaign.column_${item}`) }}
                                </p>
                            </el-tooltip>
                        </template>
                        <template #default="scope">
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row[item],
                                        item,
                                        currency
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                </el-table>

                <p class="text-center mt-4 text-sm italic">
                    {{
                        compare_ranger_date[0]
                            | f__format_moment($t('common.format_date'))
                    }}
                    -
                    {{
                        compare_ranger_date[1]
                            | f__format_moment($t('common.format_date'))
                    }}
                </p>
            </div>
        </div>

        <CustomColumnDialog
            :visible.sync="show_custom_column_dialog"
            :column_values.sync="selected_metrics_for_table"
        ></CustomColumnDialog>
    </div>
</template>

<script>
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'
import moment from 'moment'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_components/custom-column-dialog'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'
import { getSyncReport } from '@/services/atosa-tiktok-ads/reporting'
import Chart from './chart'

export default {
    components: {
        ExcelSvg,
        Chart,
        CustomColumnDialog
    },

    mixins: [campaignMixin],

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'switch_compare',
        'ranger_date',
        'compare_ranger_date',
        'currency',
        'selected_metrics',
        'data_report_ads',
        'data_id'
    ],

    data() {
        return {
            selected_metrics_for_table: [],
            show_custom_column_dialog: false,
            table_data: [],
            order_field: 'spend',
            order_type: 'DESC',
            compare_table_data: [],
            compare_order_field: 'spend',
            compare_order_type: 'DESC'
        }
    },

    computed: {
        metrics() {
            return this.p__trend_chart_options_2.map((item) => item.value)
        },

        show_no_data() {
            if (!this.table_data.length) {
                return true
            }

            return false
        },

        compare_show_no_data() {
            if (!this.compare_table_data.length) {
                return true
            }

            return false
        }
    },

    watch: {
        data_id() {
            this.fetchReportData(true)
        },

        ranger_date() {
            this.fetchReportData(true)
        },

        switch_compare() {
            this.fetchCompareReportData(true)
        },

        compare_ranger_date() {
            this.fetchCompareReportData(true)
        },

        async selected_metrics_for_table() {
            await this.fetchReportData(true)

            await this.fetchCompareReportData(true)

            if (this.$refs.multipleTable) {
                this.$refs.multipleTable.doLayout()
            }

            if (this.$refs.multiTable) {
                this.$refs.multiTable.doLayout()
            }
        }
    },

    mounted() {
        this.fetchReportData()

        this.selected_metrics_for_table = this.p__trend_basic_chart_options.map(
            (item) => item.value
        )
    },

    methods: {
        async fetchCompareReportData(reset_sort = false) {
            if (!this.switch_compare) {
                return
            }

            if (reset_sort) {
                this.compare_order_field = 'spend'
                this.compare_order_type = 'DESC'
            }

            const { table_data } = await this.fetchOverviewReport(
                this.compare_ranger_date,
                this.compare_order_field,
                this.compare_order_type
            )

            this.compare_table_data = table_data
        },

        async fetchReportData(reset_sort = false) {
            if (reset_sort) {
                this.order_field = 'spend'
                this.order_type = 'DESC'
            }

            const { table_data } = await this.fetchOverviewReport(
                this.ranger_date,
                this.order_field,
                this.order_type
            )

            this.table_data = table_data
        },

        async fetchOverviewReport(ranger_date, order_field, order_type) {
            let table_data = []

            if (!this.data_id) {
                return {
                    table_data
                }
            }
            this.m__loading = true

            try {
                const filtering = [
                    {
                        filter_value: JSON.stringify([this.data_id]),
                        field_name: 'adgroup_ids',
                        filter_type: 'IN'
                    }
                ]

                const start_date = ranger_date[0]
                const end_date = ranger_date[1]

                const response = await getSyncReport(this.tiktok_account_id, {
                    page: 1,
                    page_size: 100,
                    report_type: 'BASIC',
                    data_level: 'AUCTION_AD',
                    metrics: JSON.stringify(this.metrics),
                    start_date,
                    end_date,
                    dimensions: JSON.stringify(['ad_id']),
                    order_field,
                    order_type,
                    advertiser_id: this.advertiser_id,
                    type: this.type,
                    filtering: JSON.stringify(filtering)
                })

                if (response.code === 0) {
                    const temp = response.data.list.map((item) => ({
                        ...item.dimensions,
                        ...item.metrics
                    }))

                    table_data = temp
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false

            return {
                table_data
            }
        },

        async handleSortChange({ column, prop, order }) {
            this.order_field = prop
            this.order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchReportData()
        },

        async handleSortChange_2({ column, prop, order }) {
            this.compare_order_field = prop
            this.compare_order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchCompareReportData()
        },

        getNameByAdId(ad_id) {
            if (this.data_report_ads.length > 0) {
                const item = this.data_report_ads.find(
                    (item) => item.ad.ad_id === ad_id
                )

                if (item) {
                    return item.ad.ad_name
                }
            }

            return ad_id
        },

        async handleExportExcel(table_data, ranger_date) {
            const headers = [
                this.$t('common.ad'),
                ...this.metrics.map((item) =>
                    this.$t(`page.campaign.column_${item}`)
                )
            ]

            const body = table_data.map((item) => {
                return [
                    this.getNameByAdId(item.ad_id),
                    ...this.metrics.map((me) =>
                        this.m__formatterColumn(item[me], item, this.currency)
                    )
                ]
            })

            const start_time = moment(ranger_date[0]).format(
                this.$t('common.format_fulldate')
            )
            const end_time = moment(ranger_date[1]).format(
                this.$t('common.format_fulldate')
            )

            const name = `ad_${this.data_id}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        }
    }
}
</script>
