<template>
    <div v-loading="m__loading">
        <div class="flex space-x-2 items-center mb-2">
            <p class="text-sm font-semibold">
                {{ $t('common.detailed_analysis') }}
            </p>

            <div class="!ml-auto"></div>

            <el-select
                v-model="selected_dimension"
                class="w-[200px]"
                size="small"
            >
                <el-option
                    v-for="item in dimension_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-button size="small" @click="show_custom_column_dialog = true">
                {{ $t('button.custom_column') }}
            </el-button>

            <div
                class="cursor-pointer p-2 rounded-lg bg-bg"
                @click="handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <div>
            <el-table
                ref="multipleTable"
                :max-height="400"
                :min-height="100"
                :data="detail_data"
                @sort-change="handleSortChange"
            >
                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    :label="formatBySelectedDimension('label')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <p>
                            {{ formatBySelectedDimension('name', scope.row) }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in selected_metrics_for_table"
                    :key="index"
                    width="160"
                    :show-overflow-tooltip="true"
                    :prop="item"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            class="item"
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content" class="max-w-[360px]">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>

                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>

                    <template #default="scope">
                        <p>
                            {{
                                m__formatterColumn(
                                    scope.row[item],
                                    item,
                                    currency
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <CustomColumnDialog
            :visible.sync="show_custom_column_dialog"
            :column_values.sync="selected_metrics_for_table"
        ></CustomColumnDialog>
    </div>
</template>

<script>
import {
    getReportFromTTA,
    exportReportingExcel
} from '@/services/atosa-tiktok-ads/reporting'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

import { formatAge } from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_utils'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/view-data-drawer/_components/custom-column-dialog'
import moment from 'moment'
import ExcelSvg from '@/assets/svgs/excel.svg'

export default {
    components: {
        ExcelSvg,
        CustomColumnDialog
    },

    mixins: [campaignMixin],

    props: [
        'tiktok_account_id',
        'advertiser_id',
        'ranger_date',
        'currency',
        'objective_type',
        'region_options',
        'device_model_options',
        'interest_category_options',
        'type',
        'data_id'
    ],

    data() {
        return {
            selected_metrics_for_table: [],
            show_custom_column_dialog: false,
            dimension_options: [
                {
                    value: 'age',
                    label: this.$t('common.age')
                },
                {
                    value: 'gender',
                    label: this.$t('common.gender')
                },
                {
                    value: 'age,gender',
                    label: this.$t('common.age_and_gender')
                },
                {
                    value: 'country_code',
                    label: this.$t('common.country')
                },
                {
                    value: 'province_id',
                    label: this.$t('common.province')
                },
                {
                    value: 'ac',
                    label: this.$t('common.network')
                },
                {
                    value: 'interest_category',
                    label: this.$t('page.campaign.interests')
                },
                // {
                //     value: 'behavior_id',
                //     label: this.$t('common.behavior')
                // },
                {
                    value: 'platform',
                    label: this.$t('common.operating_system')
                },
                {
                    value: 'device_brand_id',
                    label: this.$t('common.device')
                }
            ],
            selected_dimension: null,
            detail_data: [],
            order_field: 'spend',
            order_type: 'DESC'
        }
    },

    computed: {
        s__language() {
            return this.$store.getters.app.s__language
        },

        metrics() {
            return this.p__trend_chart_options_for_audience.map(
                (item) => item.value
            )
        }
    },

    watch: {
        ranger_date() {
            this.fetchOverviewReport(true)
        },

        data_id() {
            this.fetchOverviewReport(true)
        },

        selected_dimension() {
            this.fetchOverviewReport(true)
        },

        async selected_metrics_for_table() {
            this.fetchOverviewReport(true)

            if (this.$refs.multipleTable) {
                this.$refs.multipleTable.doLayout()
            }
        }
    },

    mounted() {
        this.selected_dimension = this.dimension_options[0].value
        this.fetchOverviewReport()

        this.selected_metrics_for_table = this.p__trend_basic_chart_options.map(
            (item) => item.value
        )
    },

    methods: {
        async fetchOverviewReport(reset_sort = false) {
            if (!this.data_id) {
                return
            }

            this.m__loading = true

            try {
                if (reset_sort) {
                    this.order_field = 'spend'
                    this.order_type = 'DESC'
                }

                let data_level = ''
                let field_name = ''
                if (this.type === 'campaign_id') {
                    data_level = 'AUCTION_CAMPAIGN'
                    field_name = 'campaign_ids'
                } else if (this.type === 'adgroup_id') {
                    data_level = 'AUCTION_ADGROUP'
                    field_name = 'adgroup_ids'
                } else if (this.type === 'ad_id') {
                    data_level = 'AUCTION_AD'
                    field_name = 'ad_ids'
                }

                const filtering = [
                    {
                        filter_value: JSON.stringify([this.data_id]),
                        field_name,
                        filter_type: 'IN'
                    }
                ]

                const response = await getReportFromTTA(
                    this.tiktok_account_id,
                    {
                        page_size: 50,
                        page: 1,
                        filtering: JSON.stringify(filtering),
                        advertiser_id: this.advertiser_id,
                        start_date: this.ranger_date[0],
                        end_date: this.ranger_date[1],
                        type: this.type,
                        report_type: 'AUDIENCE',
                        order_field: this.order_field,
                        order_type: this.order_type,
                        metrics: JSON.stringify(this.metrics),
                        dimensions: JSON.stringify(
                            this.selected_dimension.split(',')
                        ),
                        data_level
                    }
                )
                this.detail_data = response.data.list.map((item) => ({
                    ...item.dimensions,
                    ...item.metrics
                }))
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async handleSortChange({ column, prop, order }) {
            this.order_field = prop
            this.order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.fetchOverviewReport()
        },

        formatBySelectedDimension(type, row = null) {
            if (this.selected_dimension === 'age') {
                if (type === 'label') {
                    return this.$t('common.age')
                }
                return formatAge(row['age'])
            }
            if (this.selected_dimension === 'gender') {
                if (type === 'label') {
                    return this.$t('common.gender')
                }
                return row['gender'] === 'MALE'
                    ? this.$t('common.male')
                    : this.$t('common.female')
            }
            if (this.selected_dimension === 'age,gender') {
                if (type === 'label') {
                    return this.$t('common.age_and_gender')
                }
                return `${formatAge(row['age'])} + ${
                    row['gender'] === 'MALE'
                        ? this.$t('common.male')
                        : this.$t('common.female')
                }`
            }
            if (this.selected_dimension === 'country_code') {
                if (type === 'label') {
                    return this.$t('common.country')
                }
                return row['country_code']
            }
            if (this.selected_dimension === 'province_id') {
                if (type === 'label') {
                    return this.$t('common.province')
                }

                if (this.region_options.length) {
                    const item = this.region_options.find(
                        (item) => item.region_id === row['province_id']
                    )
                    if (item) {
                        return item.region_name
                    }
                }
                return row['province_id']
            }
            if (this.selected_dimension === 'ac') {
                if (type === 'label') {
                    return this.$t('common.network')
                }
                return row['ac']
            }
            if (this.selected_dimension === 'interest_category') {
                if (type === 'label') {
                    return this.$t('page.campaign.interests')
                }
                if (this.interest_category_options.length) {
                    const item = this.interest_category_options.find(
                        (item) =>
                            item.interest_category_id ===
                            row['interest_category_v2']
                    )
                    if (item) {
                        return item.interest_category_name
                    }
                }
                return row['interest_category_v2']
            }
            if (this.selected_dimension === 'platform') {
                if (type === 'label') {
                    return this.$t('common.operating_system')
                }
                return row['platform']
            }
            if (this.selected_dimension === 'device_brand_id') {
                if (type === 'label') {
                    return this.$t('common.device')
                }
                if (this.device_model_options.length) {
                    const item = this.device_model_options.find(
                        (item) =>
                            item.device_model_id === row['device_brand_id']
                    )
                    if (item) {
                        return item.device_model_name
                    }
                }
                return row['device_brand_id']
            }
            // if (this.selected_dimension === 'behavior_id') {
            //     if (type === 'label') {
            //         return this.$t('common.behavior')
            //     }
            //     return row['behavior_id']
            // }

            return ''
        },

        async handleExportExcel() {
            if (!this.data_id) {
                return
            }

            try {
                let data_level = ''
                let field_name = ''
                if (this.type === 'campaign_id') {
                    data_level = 'AUCTION_CAMPAIGN'
                    field_name = 'campaign_ids'
                } else if (this.type === 'adgroup_id') {
                    data_level = 'AUCTION_ADGROUP'
                    field_name = 'adgroup_ids'
                } else if (this.type === 'ad_id') {
                    data_level = 'AUCTION_AD'
                    field_name = 'ad_ids'
                }

                const filtering = [
                    {
                        filter_value: JSON.stringify([this.data_id]),
                        field_name,
                        filter_type: 'IN'
                    }
                ]
                const response = await exportReportingExcel(
                    this.tiktok_account_id,
                    {
                        page_size: 50,
                        page: 1,
                        filtering: JSON.stringify(filtering),
                        advertiser_id: this.advertiser_id,
                        start_date: this.ranger_date[0],
                        end_date: this.ranger_date[1],
                        type: this.type,
                        report_type: 'AUDIENCE',
                        order_field: this.order_field,
                        order_type: this.order_type,
                        metrics: JSON.stringify(this.metrics),
                        dimensions: JSON.stringify(
                            this.selected_dimension.split(',')
                        ),
                        data_level
                    }
                )

                const date = moment().format('HHmmDDMMyyyy')

                const filename = `excel-audience-${date}`

                this.p__exportExcelByBlob(response, filename)
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
