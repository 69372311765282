<template>
    <el-dialog
        title=""
        :visible.sync="visible_inline"
        :append-to-body="true"
        :close-on-click-modal="true"
        width="520px"
    >
        <img
            :src="require('@/assets/images/create_campaign_success.gif')"
            alt=""
            class="mx-auto w-[140px]"
        />

        <p
            class="text-lg leading-[1.7rem] text-center font-semibold mt-6 break-word text-center"
        >
            {{
                $t('message.xxx_success', {
                    xxx: `${$t(`common.copy`)} ${$t(`common.adgroup`)}`
                })
            }}
        </p>

        <div class="mt-4">
            <p class="text-xs">
                {{ $t('common.ad_account_balance') }}
            </p>
            <p class="text-sm font-semibold">
                {{ current_balance | f__format_currency_vnd }}
            </p>

            <p class="text-red font-medium mt-1 break-keep">
                {{ $t('page.campaign.desc_warning_add_balance') }}
            </p>

            <div class="flex justify-center mt-5">
                <el-button
                    size="small"
                    class="uppercase w-[80px]"
                    @click="handleSkip"
                >
                    {{ $t('button.skip') }}
                </el-button>

                <el-button
                    type="success"
                    plain
                    icon="el-icon-money"
                    size="small"
                    @click="
                        $router.push({
                            path: `${p__group_route.ads_manager}/${p__route.payment}?advertiser_id=${advertiser_id}`
                        })
                    "
                >
                    {{ $t('page.payment.add_balance') }}
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible', 'advertiser_id', 'current_balance', 'currency'],

    computed: {
        visible_inline: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    methods: {
        handleSkip() {
            this.visible_inline = false

            this.$emit('skip')
        }
    }
}
</script>
