<template>
    <Chart :options="chart_options"></Chart>
</template>

<script>
import { Chart } from 'highcharts-vue'
import campaignMixin from '@/views/campaign/campaign-wrapper/_mixin'

export default {
    components: {
        Chart
    },

    mixins: [campaignMixin],

    props: ['data_list', 'selected_metrics', 'getNameByAdId'],

    data() {
        return {
            chart_options: {
                chart: {
                    type: 'spline',
                    height: 280
                },
                title: {
                    text: ''
                },
                legend: {
                    enabled: false,
                    floating: true
                },
                xAxis: {
                    categories: [],
                    crosshair: true,
                    minTickInterval: 6,
                    tickInterval: 1,
                    endOnTick: true,
                    startOnTick: true,
                    labels: {
                        step: 1
                    }
                },
                tooltip: {
                    shared: true
                },
                yAxis: [],
                series: []
            }
        }
    },

    watch: {
        data_list() {
            const categories = this.data_list.map((item) =>
                this.getNameByAdId(item.ad_id)
            )

            this.chart_options.xAxis.categories = categories

            let step = 1

            if (categories.length <= 20) {
                step = 1
            } else if (categories.length > 20 && categories.length < 30) {
                step = 3
            } else if (categories.length >= 30 && categories.length < 50) {
                step = 5
            } else {
                step = (categories.length / 6).toFixed(0)
            }
            this.chart_options.xAxis.labels.step = step

            this.changeSeriesChart()
        },

        selected_metrics() {
            this.changeSeriesChart()
        }
    },

    mounted() {
        const m__formatterColumn = (x, y) =>
            this.m__formatterColumn(x, y, this.currency)

        const p__getNameByTrend = this.p__getNameByTrend

        this.chart_options.tooltip.pointFormatter = function () {
            const point = this
            const series = point.series
            const y = m__formatterColumn(point.y || 0, series.name)
            return `${p__getNameByTrend(series.name)}: <b>${y}</b><br/>`
        }
    },

    methods: {
        changeSeriesChart() {
            const series = []
            const yAxis = []
            let index = 0

            for (const item of this.selected_metrics) {
                const data = [...this.data_list].map((it) => +it[item.value])

                series.push({
                    type: 'spline',
                    name: item.value,
                    color: item.color,
                    data,
                    yAxis: index,
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    }
                })

                yAxis.push({
                    title: {
                        text: ''
                    },
                    opposite: true,
                    labels: {
                        enabled: false
                    }
                })

                index++
            }

            this.chart_options.series = series
            this.chart_options.yAxis = yAxis
        }
    }
}
</script>
