var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-drawer',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.m__loading),expression:"m__loading",modifiers:{"fullscreen":true,"lock":true}}],attrs:{"size":"1000px","wrapper_closable":false,"visible":_vm.visible_in_line},on:{"update:visible":function($event){_vm.visible_in_line=$event}}},[_c('template',{slot:"title"},[_c('div',{staticClass:"flex items-center justify-between flex-1 h-full pr-5"},[_c('div',[_c('p',{staticClass:"font-semibold text-sm"},[_vm._v(" "+_vm._s(this.$t('page.campaign.adgroup_pane.edit_adgroup'))+" ")]),(_vm.objective_type)?_c('p',{staticClass:"component-text-desc"},[_vm._v(" "+_vm._s(_vm.$t('common.objective_type'))+" : "+_vm._s(_vm.p__formatObjectiveType(_vm.objective_type))+" ")]):_vm._e()]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.handleCopyAdgroups}},[_vm._v(" "+_vm._s(_vm.$t('common.copy'))+" "),_c('i',{staticClass:"el-icon-copy-document"})])],1)]),(_vm.list_campaigns.length && _vm.selected_campaign)?_c('div',{staticClass:"fixed top-[64px] right-[1012px]"},[_c('div',{staticClass:"component-box w-[280px]"},[_c('p',{staticClass:"font-semibold text-sm"},[_vm._v(" "+_vm._s(this.$t('page.campaign.target_campaigns'))+" ")]),_c('ul',{staticClass:"mt-2"},_vm._l((_vm.list_campaigns),function(item){return _c('li',{key:item.campaign_id,staticClass:"text-sm mt-1 cursor-pointer rounded-lg py-2 px-3",class:[
                        _vm.selected_campaign.campaign_id === item.campaign_id
                            ? 'bg-bg'
                            : 'hover:bg-bg'
                    ],on:{"click":function($event){return _vm.handleSelectCampaign(item)}}},[_c('el-tooltip',{attrs:{"effect":"dark","content":item.campaign_name,"placement":"left"}},[_c('p',{staticClass:"truncate w-[200px]"},[_vm._v(" "+_vm._s(item.campaign_name)+" ")])])],1)}),0)])]):_vm._e(),_c('div',{staticClass:"flex h-full"},[_c('div',{staticClass:"w-[68px] bg-white border-r-[1px] border-border"},[(
                    _vm.selected_campaign && _vm.selected_campaign.adgroups.length
                )?_c('div',{staticClass:"flex flex-col h-full overflow-auto"},[_c('ul',{staticClass:"pt-4"},_vm._l((_vm.selected_campaign.adgroups),function(item,index){return _c('li',{key:index,staticClass:"pb-3 util-flex-center",on:{"click":function($event){_vm.selected_adgroup = item}}},[_c('div',{staticClass:"w-10 h-10 util-flex-center rounded-lg",class:[
                                _vm.selected_adgroup &&
                                _vm.selected_adgroup.id === item.id
                                    ? 'text-white bg-brand-atosa--light'
                                    : 'bg-bg cursor-pointer'
                            ]},[_c('p',{staticClass:"text-sm"},[_vm._v(" "+_vm._s(index + 1)+" ")])])])}),0)]):_vm._e()]),(_vm.selected_adgroup)?_c('div',{staticClass:"flex-1 h-full pt-4 flex space-x-4 px-4 overflow-auto"},[_c('div',{staticClass:"flex space-x-4 w-full h-fit"},[_c('div',{staticClass:"w-2/3"},[(_vm.list_errors.length > 0)?_c('el-alert',{staticClass:"mb-4",attrs:{"title":_vm.$t('common.warning'),"type":"warning","closable":false}},_vm._l((_vm.list_errors),function(item,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(item)+" ")])}),0):_vm._e(),_c('EditAdGroupForm',{staticClass:"w-full",attrs:{"selected_item":_vm.selected_adgroup,"currency":_vm.currency,"timezone":_vm.timezone,"tiktok_account_id":_vm.tiktok_account_id,"belong_to_atosa":_vm.belong_to_atosa,"advertiser_id":_vm.advertiser_id,"objective_type":_vm.objective_type,"min_budget_adgroup":_vm.min_budget_adgroup,"type_form":"copy"},on:{"update:selected_item":function($event){_vm.selected_adgroup=$event}}})],1),_c('div',{staticClass:"flex-1 flex flex-col space-y-4 sticky top-0 left-0 overflow-auto",style:({ height: `${_vm.height_select_box}px` })},[_c('div',{staticClass:"component-box"},[_c('PreviewAdgroup',{attrs:{"selected_item":_vm.selected_adgroup,"currency":_vm.currency}})],1),_c('AvailableAudienceAdgroup',{staticClass:"w-full",attrs:{"objective_type":_vm.objective_type,"selected_item":_vm.selected_adgroup,"advertiser_id":_vm.advertiser_id,"tiktok_account_id":_vm.tiktok_account_id}}),_c('CustomTargetAdgroupBox',{staticClass:"w-full",attrs:{"tiktok_account_id":_vm.tiktok_account_id,"objective_type":_vm.objective_type,"selected_item":_vm.selected_adgroup},on:{"update:selected_item":function($event){_vm.selected_adgroup=$event}}})],1)]),(
                    _vm.selected_adgroup &&
                    ['PRODUCT_SALES'].some(
                        (item) => item !== _vm.objective_type
                    )
                )?_c('CustomTargetAdgroupBox',{staticClass:"min-w-[320px] w-[320px]",attrs:{"tiktok_account_id":_vm.tiktok_account_id,"objective_type":_vm.objective_type,"selected_item":_vm.selected_adgroup},on:{"update:selected_item":function($event){_vm.selected_adgroup=$event}}}):_vm._e()],1):_vm._e()]),_c('CreateSuccessDialog',{attrs:{"visible":_vm.show_create_success_dialog,"advertiser_id":_vm.advertiser_id,"current_balance":_vm.current_balance,"currency":_vm.currency},on:{"update:visible":function($event){_vm.show_create_success_dialog=$event},"skip":function($event){_vm.visible_in_line = false}}}),_c('ProcessDialog',{attrs:{"visible":_vm.m__loading &&
            _vm.infor_loading.index > 0 &&
            _vm.infor_loading.amount > 0,"index":_vm.infor_loading.index,"amount":_vm.infor_loading.amount}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }