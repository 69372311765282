<template>
    <custom-drawer
        v-loading.fullscreen.lock="m__loading"
        size="1100px"
        :wrapper_closable="true"
        :visible.sync="visible_in_line"
        :confirm_to_close="false"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ $t('common.batch_name_adjustment') }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    :disabled="m__loading || !item_to_edit_name_list.length"
                    :loading="m__loading"
                    @click="handleConfirm()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div class="px-5 py-4">
            <el-table
                ref="multipleTable"
                class="new-campaign-table"
                max-height="560"
                :data="item_to_edit_name_list"
            >
                <el-table-column
                    prop="adgroup.adgroup_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div class="group hover:text-brand-atosa--light">
                            <p class="text-desc-text text-xs">
                                #{{ scope.row.id }}
                            </p>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.campaign.campaign_name"
                                placement="top"
                            >
                                <p>
                                    {{ $t('common.old_name') }} :
                                    {{ scope.row.campaign.campaign_name }}
                                </p>
                            </el-tooltip>

                            <el-tooltip
                                class="item"
                                effect="dark"
                                :content="scope.row.campaign.new_name"
                                placement="top"
                            >
                                <p>
                                    {{ $t('common.new_name') }} :
                                    {{ scope.row.campaign.new_name }}
                                </p>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="120"
                    :show-overflow-tooltip="true"
                    prop="campaign.objective_type"
                    :label="$t('page.campaign.objective_type')"
                >
                    <template #default="scope">
                        <p>
                            {{
                                p__formatObjectiveType(
                                    scope.row.campaign.objective_type
                                )
                            }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column
                    width="120"
                    :show-overflow-tooltip="true"
                    prop="campaign.create_time"
                    :label="$t('common.created_at')"
                >
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    :label="$t('common.name_template')"
                >
                    <template #default="scope">
                        <NameRow
                            :selected_item.sync="scope.row.campaign"
                            :objective_type="scope.row.campaign.objective_type"
                            :ads_account_name="ads_account_name"
                            :template_name_list="template_name_list"
                            :getTemplateNames="getTemplateNames"
                            :tiktok_business_user_name="
                                tiktok_business_user_name
                            "
                        ></NameRow>
                    </template>
                </el-table-column>

                <el-table-column prop="new_name" :label="$t('common.new_name')">
                    <template #default="scope">
                        <div>
                            <el-input
                                v-model="scope.row.campaign.new_name"
                                size="small"
                            >
                            </el-input>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div class="component-box mt-4">
                <div class="flex items-center mb-4">
                    <p class="font-semibold text-sm">
                        {{
                            $t(
                                'page.campaign.adjust_batch_names_according_to_name_template'
                            )
                        }}
                    </p>

                    <el-button
                        size="small"
                        icon="el-icon-plus"
                        class="ml-auto"
                        @click="show_create_dialog = true"
                        >{{ $t('button.create') }}</el-button
                    >
                </div>

                <el-table
                    ref="multipleTable"
                    class="new-campaign-table"
                    max-height="560"
                    :data="template_name_list"
                >
                    <el-table-column :label="$t('common.name')" :fixed="true">
                        <template #default="scope">
                            <p>
                                {{ scope.row.config_name }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.preview')">
                        <template #default="scope">
                            <p>
                                {{
                                    genDescriptionTemplateName(
                                        scope.row.fields,
                                        scope.row.field_separator
                                    )
                                }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('common.option')" width="160">
                        <template #default="scope">
                            <el-button
                                size="small"
                                @click="handleChooseItem(scope.row)"
                            >
                                {{ $t('button.choose') }}
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <CreateTemplateNameDialog
            :visible.sync="show_create_dialog"
            :field_options="field_options"
            :genDescriptionTemplateName="genDescriptionTemplateName"
            :getTemplateNames="getTemplateNames"
        ></CreateTemplateNameDialog>
    </custom-drawer>
</template>

<script>
import { updateCampaign } from '@/services/atosa-tiktok-ads/campaign'
import NameRow from './name-row'
import { getTemplateNames } from '@/services/atosa-tiktok-ads/tool'
import CreateTemplateNameDialog from './name-row/create-template-name-dialog'
import moment from 'moment'

export default {
    components: {
        NameRow,
        CreateTemplateNameDialog
    },

    props: [
        'visible',
        'multiple_selection',
        'advertiser_id',
        'ads_account_name',
        'belong_to_atosa',
        'tiktok_business_user_name',
        'tiktok_account_id'
    ],

    data() {
        return {
            show_create_dialog: false,
            template_name_list: [],
            item_to_edit_name_list: [],
            field_options: [
                {
                    label: this.$t('common.objective_type'),
                    key: 'objective_type'
                },
                {
                    label: this.$t('common.advertiser_account'),
                    key: 'advertiser_account'
                },
                {
                    label: this.$t('common.tiktok_business'),
                    key: 'tiktok_business'
                },
                {
                    label: this.$t('common.budget'),
                    key: 'budget'
                },
                {
                    label: this.$t('common.time'),
                    key: 'time'
                },
                {
                    label: this.$t('common.text'),
                    key: 'text'
                }
            ]
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        }
    },

    watch: {
        visible_in_line() {
            if (this.visible_in_line) {
                this.item_to_edit_name_list = this.multiple_selection.map(
                    (item) => ({
                        ...item,
                        campaign: {
                            ...item.campaign,
                            new_name: item.campaign.campaign_name
                        }
                    })
                )
            }
        }
    },

    mounted() {
        this.getTemplateNames()
    },

    methods: {
        async handleConfirm() {
            if (this.item_to_edit_name_list.length === 0) {
                return
            }

            this.m__loading = true
            try {
                await Promise.all(
                    this.item_to_edit_name_list.map(async (item) => {
                        await updateCampaign(this.tiktok_account_id, {
                            advertiser_id: this.advertiser_id,
                            campaign_name: item.campaign.new_name,
                            campaign_id: item.campaign.campaign_id,
                            belong_to_atosa: this.belong_to_atosa
                        })
                    })
                )

                this.m__loading = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )

                this.p__event_bus.$emit('eb_reload_campaign')

                this.visible_in_line = false
            } catch (error) {
                this.m__loading = false
                console.error(error)
            }
        },

        handleChooseItem(template) {
            this.item_to_edit_name_list = this.item_to_edit_name_list.map(
                (item) => {
                    const new_name = this.genPreviewName(
                        template.fields,
                        template.field_separator,
                        item
                    )

                    return {
                        ...item,
                        campaign: {
                            ...item.campaign,
                            new_name
                        }
                    }
                }
            )
        },

        genDescriptionTemplateName(fields, field_separator) {
            const temp = fields.map((item) => {
                const temp = this.field_options.find(
                    (it) => it.key === item.key
                )

                if (temp) {
                    return temp.label
                }

                return item.key
            })
            return temp.join(` ${field_separator} `)
        },

        genPreviewName(fields, field_separator, campaign) {
            const temp = fields.map((item) => {
                const { key, value } = item

                if (key === 'time') {
                    const date = moment().format(value)
                    return date
                }

                if (key === 'objective_type') {
                    return campaign.campaign.objective_type
                }

                if (key === 'budget') {
                    return this.p__formatBudgetMode(
                        campaign.campaign.budget_mode
                    )
                }

                if (key === 'advertiser_account') {
                    return this.ads_account_name
                }

                if (key === 'tiktok_business') {
                    return this.tiktok_business_user_name
                }

                if (key === 'text') {
                    return value
                }

                return item.key
            })

            return temp.join(`${field_separator}`)
        },

        async getTemplateNames() {
            try {
                const response = await getTemplateNames()
                if (response.code === 0) {
                    this.template_name_list = response.data.filter(
                        (item) => item.type === 'campaign'
                    )
                }
            } catch (error) {
                console.error(error)
            }
        }
    }
}
</script>
