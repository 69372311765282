<template>
    <custom-drawer
        size="70%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="items-center h-full flex pl-4 pr-8 justify-between">
                <div>
                    <p class="font-semibold">
                        {{ title_edit_adgroup }}
                    </p>
                    <p v-if="objective_type" class="component-text-desc">
                        {{ $t('common.objective_type') }} :
                        {{ p__formatObjectiveType(objective_type) }}
                    </p>
                </div>

                <el-button
                    icon="el-icon-edit-outline"
                    type="primary"
                    size="small"
                    @click="handleEditAdgroup()"
                >
                    {{ $t('common.edit') }}
                </el-button>
            </div>
        </template>
        <section class="pt-8 pl-[84px] pr-8">
            <EditAdGroupForm
                :selected_item.sync="selected_item"
                :currency="currency"
                :timezone="timezone"
                :tiktok_account_id="tiktok_account_id"
                :advertiser_id="advertiser_id"
                :objective_type="objective_type"
                :min_budget_adgroup="min_budget_adgroup"
                :belong_to_atosa="belong_to_atosa"
                type_form="copy"
            ></EditAdGroupForm>
        </section>
    </custom-drawer>
</template>

<script>
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'

export default {
    mixins: [EditAdgroupFormMixin],

    props: [
        'visible',
        'item',
        'currency',
        'timezone',
        'objective_type',
        'advertiser_id',
        'tiktok_account_id',
        'min_budget_adgroup',
        'belong_to_atosa',
        'handle_change_adgroup'
    ],

    data() {
        return {
            selected_item: null
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        title_edit_adgroup() {
            if (!this.item) {
                return ''
            }

            return `${this.$t('common.edit')} ${this.$t(
                'common.adgroup'
            ).toLowerCase()} #${this.item.adgroup_id}`
        }
    },

    watch: {
        visible() {
            if (this.visible) {
                this.selected_item = this.item
            }
        }
    },

    methods: {
        handleEditAdgroup() {
            if (
                !this.m__validAdgroup(
                    this.selected_item,
                    this.min_budget_adgroup,
                    this.objective_type
                )
            ) {
                return
            }

            this.handle_change_adgroup(this.selected_item)
            this.visible_in_line = false
        }
    }
}
</script>
