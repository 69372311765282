<template>
    <custom-drawer
        v-loading="m__loading"
        size="55%"
        :wrapper_closable="false"
        :visible.sync="visible_in_line"
    >
        <template slot="title">
            <div class="flex items-center justify-between flex-1 h-full pr-5">
                <div>
                    <p class="font-semibold text-sm">
                        {{ this.$t('page.campaign.adgroup_pane.edit_adgroup') }}
                    </p>
                    <p
                        v-if="selected_item"
                        class="text-desc-text text-xs"
                    >
                        {{ $t('table.header.ad_group_id') }} :
                        {{ selected_item.adgroup.adgroup_id }}
                    </p>
                </div>
                <el-button
                    icon="el-icon-check"
                    type="primary"
                    size="small"
                    @click="handleEditAdgroup()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>

        <div v-if="selected_adgroup" class="px-5 py-8">
            <EditAdGroupForm
                :selected_item.sync="selected_adgroup"
                :currency="selected_item.currency"
                :timezone="selected_item.timezone"
                :tiktok_account_id="tiktok_account_id"
                :belong_to_atosa="belong_to_atosa"
                :advertiser_id="advertiser_id"
                :objective_type="objective_type"
                :min_budget_adgroup="min_budget_adgroup"
                type_form="edit"
            ></EditAdGroupForm>
        </div>
    </custom-drawer>
</template>

<script>
import EditAdgroupFormMixin from '@/components/orgranisms/edit-adgroup-form/_mixin'
import { getCampaigns } from '@/services/atosa-tiktok-ads/campaign'
import { updateAdgroup } from '@/services/atosa-tiktok-ads/adgroup'
import { removeEmptyParams } from '@/views/campaign/campaign-wrapper/_utils'
import {
    deleteConfigScheduleBudget,
    updateConfigScheduleBudget,
    createConfigScheduleBudget
} from '@/services/atosa-tiktok-ads/auto-ads'
import moment from 'moment'

export default {
    mixins: [EditAdgroupFormMixin],

    props: [
        'visible',
        'atosa_bc_list',
        'selected_item',
        'tiktok_account_id',
        'advertiser_id',
        'min_budget_adgroup',
        'belong_to_atosa'
    ],

    data() {
        return {
            objective_type: '',
            selected_adgroup: null
        }
    },

    computed: {
        visible_in_line: {
            get: function () {
                return this.visible
            },
            set: function (val) {
                this.$emit('update:visible', val)
            }
        }
    },

    watch: {
        selected_item() {
            this.selected_adgroup = {
                ...this.selected_item,
                ...this.selected_item.adgroup,
                extension: this.p__default_state_infor_adgroup.extension
            }
            this.fetchDetailCampaign()
        }
    },

    mounted() {
        this.fetchDetailCampaign()
    },

    methods: {
        async handleEditAdgroup() {
            if (
                !this.m__validAdgroup(
                    this.selected_adgroup,
                    this.min_budget_adgroup,
                    this.objective_type,
                    'edit'
                )
            ) {
                return
            }

            this.m__loading = true

            try {
                const temp = { ...this.selected_adgroup }
                const { adgroup_id } = temp
                const config_list = temp.extension.config_list.map((item) => ({
                    ...item,
                    time: moment(item.time).unix()
                }))

                removeEmptyParams(temp)

                this.m__changeFormatScheduleTime(temp)

                if (temp?.actions?.length > 0) {
                    this.m__changeActionCategories(temp)
                } else {
                    temp.actions = []
                }

                if (!temp?.interest_keyword_ids) {
                    temp.interest_keyword_ids = []
                }

                if (!temp?.interest_category_ids) {
                    temp.interest_category_ids = []
                }

                await updateAdgroup(this.tiktok_account_id, {
                    ...temp,
                    belong_to_atosa: this.belong_to_atosa,
                    advertiser_id: this.advertiser_id,
                    adgroup_id
                })

                const new_config_list = []

                await Promise.all(
                    config_list.map(async (item) => {
                        if (item.type === 'delete') {
                            await deleteConfigScheduleBudget(
                                this.tiktok_account_id,
                                item.id
                            )
                            return
                        }
                        if (item.type === 'old') {
                            await updateConfigScheduleBudget(
                                this.tiktok_account_id,
                                {
                                    config_id: item.id,
                                    adgroup_id,
                                    advertiser_id: this.advertiser_id,
                                    time: item.time,
                                    value: item.value
                                }
                            )
                            return
                        }
                        if (item.type === 'new') {
                            new_config_list.push(item)
                            return
                        }
                    })
                )

                if (new_config_list.length > 0) {
                    await createConfigScheduleBudget(this.tiktok_account_id, {
                        adgroup_id,
                        advertiser_id: this.advertiser_id,
                        config_list: new_config_list
                    })
                }

                this.p__event_bus.$emit('eb_reload_adgroup')

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
                this.visible_in_line = false
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async fetchDetailCampaign() {
            if (!this.selected_adgroup) {
                return
            }

            const response = await getCampaigns(this.tiktok_account_id, {
                advertiser_id: this.advertiser_id,
                filtering: JSON.stringify({
                    campaign_ids: [this.selected_adgroup.campaign_id]
                })
            })
            if (
                response.code === 0 &&
                response.data.list.length &&
                response.data.list[0]
            ) {
                const first_campaign = response.data.list[0]

                this.objective_type = first_campaign.objective_type
            }
        }
    }
}
</script>
