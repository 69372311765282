<template>
    <el-dialog
        :visible.sync="visible_in_line"
        width="380px"
        class="el-dialog-center"
        :append-to-body="true"
        :close-on-click-modal="true"
    >
        <div class="h-[400px] border-[1px] border-border">
            <el-checkbox
                v-model="check_all"
                class="py-3 pl-3 hover:bg-bg"
                :indeterminate="is_indeterminate"
                @change="handleCheckAllChange"
            >
                <p class="text-sm">
                    {{ $t('common.all') }}
                </p>
            </el-checkbox>

            <div
                class="h-[calc(100%-44px)] overflow-auto border-t-[1px] border-border"
            >
                <el-checkbox-group
                    v-model="checked_values"
                    @change="handleCheckedChange"
                >
                    <el-checkbox
                        v-for="item in p__trend_chart_options_2"
                        :key="item.value"
                        class="py-3 pl-3 hover:bg-bg"
                        :label="`${item.value}`"
                    >
                        <div>
                            <p class="text-sm">
                                {{ item.label }}
                            </p>
                        </div>
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </div>

        <template slot="title">
            <div class="flex">
                <div class="text-left truncate w-4/6 text-base font-semibold">
                    {{ $t('button.custom_column') }}
                </div>
            </div>
        </template>

        <template slot="footer">
            <div class="flex items-center justify-end">
                <el-button size="small" @click="visible_in_line = false">
                    {{ $t('button.close') }}
                </el-button>

                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-check"
                    :disabled="!checked_values.length"
                    @click="handleCustomColumn()"
                >
                    {{ $t('button.confirm') }}
                </el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
export default {
    props: ['visible', 'column_values'],

    data() {
        return {
            check_all: false,
            checked_values: [],
            is_indeterminate: false
        }
    },

    computed: {
        visible_in_line: {
            get() {
                return this.visible
            },
            set(value) {
                this.$emit('update:visible', value)
            }
        },

        column_values_in_line: {
            get() {
                return this.column_values
            },
            set(value) {
                this.$emit('update:column_values', value)
            }
        },

        list_value() {
            return this.p__trend_chart_options_2.map((item) => item.value)
        }
    },

    watch: {
        visible_in_line() {
            this.checked_values = this.column_values_in_line
            this.is_indeterminate = true
        }
    },

    mounted() {
        this.checked_values = this.column_values_in_line
        this.is_indeterminate = true
    },

    methods: {
        handleCheckAllChange(value) {
            this.checked_values = value
                ? [...this.checked_values, ...this.list_value]
                : []
            this.is_indeterminate = false
        },

        handleCheckedChange(value) {
            const checkedCount = value.length
            this.check_all = checkedCount === this.list_value.length
            this.is_indeterminate =
                checkedCount > 0 && checkedCount < this.list_value.length
        },

        handleCustomColumn() {
            this.column_values_in_line = this.checked_values

            this.visible_in_line = false
        }
    }
}
</script>
