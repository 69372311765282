<template>
    <section>
        <div class="flex flex-wrap items-center gap-2">
            <el-button
                v-if="has_manager_ads"
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="handleRedirectToCreateCampaign()"
            >
                {{ $t('button.create') }}
            </el-button>

            <el-dropdown
                trigger="click"
                :disabled="!multiple_selection_inline.length"
            >
                <el-button size="small">
                    {{ $t('common.batch_editing') }}
                    <i class="el-icon-arrow-down"></i>
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOn">
                            <span>
                                <i class="el-icon-video-play"></i>
                                {{ $t('common.turn_on') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="m__handleMassTurnOff">
                            <span>
                                <i class="el-icon-video-pause"></i>
                                {{ $t('common.turn_off') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="show_edit_multi_name_dialog = true">
                            <span>
                                {{ $t('common.batch_name_adjustment') }}</span
                            >
                        </div>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <div @click="show_edit_multi_budget_dialog = true">
                            <span>
                                {{
                                    $t('common.batch_budget_adjustments')
                                }}</span
                            >
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <div v-if="has_auto_ads">
                <el-dropdown trigger="click">
                    <el-button size="small">
                        {{ $t('common.automatic_rule') }}
                        <i class="el-icon-arrow-down"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <div
                                @click="
                                    is_create_automatic_rule_drawer_displayed = true
                                "
                            >
                                <p>
                                    {{ $t('page.campaign.create_rule') }}
                                </p>
                            </div>
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <div
                                @click="
                                    is_manage_automatic_rule_drawer_displayed = true
                                "
                            >
                                <p>
                                    {{ $t('page.campaign.manage_rule') }}
                                </p>
                            </div>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>

            <!-- <el-button
                :disabled="!multiple_selection_inline.length"
                size="small"
                class="!ml-0"
                @click="show_edit_multi_budget_dialog = true"
            >
                {{ $t('common.batch_editing') }}
            </el-button> -->

            <div class="!ml-auto"></div>

            <el-select
                v-model="m__type_statistical_detail"
                size="small"
                filterable
                class="w-[190px]"
                :placeholder="$t('input.placeholder.please_select')"
            >
                <el-option
                    :label="$t('common.detailed_analysis_none')"
                    :value="'none'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_day')"
                    :value="'day'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_week')"
                    :value="'week'"
                >
                </el-option>
                <el-option
                    :label="$t('common.detailed_analysis_month')"
                    :value="'month'"
                >
                </el-option>
            </el-select>

            <el-dropdown>
                <el-button size="small" class="!ml-0">
                    {{ $t('button.column_display') }}
                </el-button>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    }
                                ])
                            "
                        >
                            {{ $t('button.default_column') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: p__basic_metrics
                                    },
                                    {
                                        value: 'ON_SITE_EVENT',
                                        child_values: [
                                            'onsite_shopping',
                                            'onsite_initiate_checkout_count',
                                            'onsite_on_web_detail',
                                            'total_onsite_shopping_value',
                                            'onsite_shopping_roas',
                                            'live_views',
                                            'video_play_actions'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.shop_ads') }}
                        </p>
                    </el-dropdown-item>
                    <el-dropdown-item>
                        <p
                            @click="
                                m__showColumn([
                                    {
                                        value: 'BASIC_DATA',
                                        child_values: [
                                            'cost_per_1000_reached',
                                            'frequency',
                                            'clicks',
                                            'cpc',
                                            'ctr'
                                        ]
                                    },

                                    {
                                        value: 'ENGAGEMENT',
                                        child_values: [
                                            'engagements',
                                            'engagement_rate',
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    },
                                    {
                                        value: 'VIDEO_PLAY',
                                        child_values: [
                                            'video_watched_2s',
                                            'video_watched_6s',
                                            'video_views_p25',
                                            'video_views_p50',
                                            'video_views_p75',
                                            'video_views_p100',
                                            'average_video_play_per_user',
                                            'average_video_play'
                                        ]
                                    },
                                    {
                                        value: 'INTERACTIVE_ADD_ON',
                                        child_values: [
                                            'interactive_add_on_impressions',
                                            'interactive_add_on_destination_clicks'
                                        ]
                                    }
                                ])
                            "
                        >
                            {{ $t('common.reach') }}
                        </p>
                    </el-dropdown-item>

                    <el-dropdown-item divided>
                        <p @click="show_custom_column_dialog = true">
                            {{ $t('button.custom_column') }}
                        </p></el-dropdown-item
                    >
                </el-dropdown-menu>
            </el-dropdown>

            <div
                class="bg-bg p-2 rounded-lg cursor-pointer"
                :class="[
                    m__data_for_pagination.length ? '' : 'cursor-not-allowed'
                ]"
                @click="m__handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <div class="relative mt-2">
            <el-table
                ref="multipleTable"
                v-loading="m__loading"
                class="campaign-table"
                :show-summary="!name_filtering"
                max-height="560"
                row-key="id"
                lazy
                :tree-props="{
                    children: 'children',
                    hasChildren: '_has_children'
                }"
                :load="m__handleLoadExpand"
                :data="m__data_for_pagination"
                :summary-method="m__getSummaries"
                @selection-change="handleSelectionChange"
                @sort-change="m__handleSortChange"
            >
                <el-table-column
                    type="selection"
                    width="40"
                    :fixed="true"
                    :reserve-selection="true"
                >
                </el-table-column>

                <el-table-column
                    v-if="m__type_statistical_detail !== 'none'"
                    :label="''"
                    :fixed="true"
                    width="60"
                >
                </el-table-column>

                <el-table-column
                    :label="$t('table.header.on_off')"
                    :fixed="true"
                    width="60"
                >
                    <template #default="scope">
                        <el-switch
                            v-if="!scope.row.is_child"
                            :disabled="
                                scope.row.adgroup.secondary_status ===
                                'CAMPAIGN_STATUS_DELETE'
                            "
                            :value="
                                scope.row.adgroup.operation_status === 'ENABLE'
                            "
                            @change="
                                handleChangeStatusAdgroup(
                                    scope.row.adgroup.adgroup_id
                                )
                            "
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    min-width="360"
                    prop="adgroup.adgroup_name"
                    :label="$t('common.name')"
                    :fixed="true"
                >
                    <template #default="scope">
                        <div v-if="scope.row.is_child">
                            <p class="truncate max-w-[240px]">
                                {{ scope.row.child_name }}
                            </p>
                        </div>
                        <div v-else class="group hover:text-brand-atosa--light">
                            <div class="group flex items-center">
                                <div
                                    v-if="
                                        [
                                            'ADGROUP_STATUS_CAMPAIGN_DELETE',
                                            'ADGROUP_STATUS_DELETE'
                                        ].some(
                                            (item) =>
                                                item ===
                                                scope.row.adgroup
                                                    .secondary_status
                                        )
                                    "
                                    class="cursor-pointer"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.adgroup.adgroup_name
                                        "
                                        placement="top"
                                    >
                                        <p class="truncate max-w-[240px]">
                                            {{ scope.row.adgroup.adgroup_name }}
                                        </p>
                                    </el-tooltip>
                                </div>
                                <div
                                    v-else
                                    class="cursor-pointer"
                                    @click="handleSelectRow(scope.row)"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            scope.row.adgroup.adgroup_name
                                        "
                                        placement="top"
                                    >
                                        <p
                                            class="hover:underline truncate max-w-[240px]"
                                        >
                                            {{ scope.row.adgroup.adgroup_name }}
                                        </p>
                                    </el-tooltip>
                                </div>

                                <el-tooltip
                                    effect="dark"
                                    :content="$t('common.edit_name')"
                                    placement="top"
                                >
                                    <div
                                        v-if="
                                            [
                                                'ADGROUP_STATUS_CAMPAIGN_DELETE',
                                                'ADGROUP_STATUS_DELETE'
                                            ].every(
                                                (item) =>
                                                    item !==
                                                    scope.row.adgroup
                                                        .secondary_status
                                            ) && has_manager_ads
                                        "
                                        class="group-hover:block hidden ml-2 cursor-pointer"
                                        @click="
                                            handleSelectRowToEditName(
                                                scope.row.adgroup
                                            )
                                        "
                                    >
                                        <i
                                            class="el-icon-edit text-xs font-semibold"
                                        ></i>
                                    </div>
                                </el-tooltip>
                            </div>

                            <div
                                v-if="
                                    [
                                        'ADGROUP_STATUS_CAMPAIGN_DELETE',
                                        'ADGROUP_STATUS_DELETE'
                                    ].every(
                                        (item) =>
                                            item !==
                                            scope.row.adgroup.secondary_status
                                    )
                                "
                                class="flex items-center mt-1 space-x-3"
                            >
                                <div
                                    v-if="has_report_ads"
                                    class="hover:underline flex items-center space-x-1 cursor-pointer"
                                    @click="handleOpenViewDataDrawer(scope.row)"
                                >
                                    <font-awesome-icon
                                        icon="fa-solid fa-chart-area"
                                    ></font-awesome-icon>
                                    <p>
                                        {{ $t('button.view_data') }}
                                    </p>
                                </div>

                                <!-- atosa_bc_list.includes(
                                            scope.row.adgroup
                                                .identity_authorized_bc_id
                                        ) &&
                                        atosa_bc_list.includes(
                                            scope.row.adgroup
                                                .store_authorized_bc_id
                                        ) -->

                                <div
                                    v-if="has_manager_ads"
                                    class="hover:underline flex items-center space-x-1 cursor-pointer"
                                    @click="handleOpenEditDrawer(scope.row)"
                                >
                                    <font-awesome-icon
                                        icon="fa-regular fa-pen-to-square"
                                    ></font-awesome-icon>
                                    <p>
                                        {{ $t('common.edit') }}
                                    </p>
                                </div>

                                <div>
                                    <div
                                        v-if="
                                            current_package &&
                                            current_package.extra_data
                                                .clone_campaign
                                        "
                                        class="hover:underline flex items-center space-x-1 cursor-pointer"
                                        @click="
                                            handleOpenConfirmCopy(scope.row)
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-copy"
                                        />
                                        <p>
                                            {{ $t('common.copy') }}
                                        </p>
                                    </div>

                                    <div v-else>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                $t('common.feature_must_buy')
                                            "
                                            placement="top"
                                        >
                                            <div
                                                class="opacity-60 flex items-center space-x-1 cursor-not-allowed"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-copy"
                                                />
                                                <p>
                                                    {{ $t('common.copy') }}
                                                </p>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>

                                <div v-if="has_manager_ads">
                                    <div
                                        v-if="
                                            current_package &&
                                            current_package.extra_data
                                                .delete_campaign
                                        "
                                        class="hover:underline text-red flex items-center space-x-1 cursor-pointer"
                                        @click="
                                            handleDeleteAdgroup(
                                                scope.row.adgroup.adgroup_id
                                            )
                                        "
                                    >
                                        <font-awesome-icon
                                            icon="fa-regular fa-trash-alt"
                                        />
                                        <p>
                                            {{ $t('button.delete') }}
                                        </p>
                                    </div>

                                    <div v-else>
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="
                                                $t('common.feature_must_buy')
                                            "
                                            placement="top"
                                        >
                                            <div
                                                class="opacity-60 flex items-center space-x-1 cursor-not-allowed"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-trash-alt"
                                                />
                                                <p>
                                                    {{ $t('button.delete') }}
                                                </p>
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    width="160"
                    :show-overflow-tooltip="true"
                    label="ID"
                >
                    <template #default="scope">
                        <p>
                            {{ scope.row.id }}
                        </p>
                    </template>
                </el-table-column>

                <el-table-column :label="$t('common.status')" width="200px">
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <div class="flex items-center space-x-2">
                                <div
                                    class="w-2 h-2 rounded-full"
                                    :style="{
                                        background: [
                                            'ADGROUP_STATUS_TIME_DONE',
                                            'ADGROUP_STATUS_DELIVERY_OK',
                                            'ADGROUP_STATUS_SHADOW_ADGROUP_REAUDIT',
                                            'ADGROUP_STATUS_PARTIAL_AUDIT_DELIVERY_OK'
                                        ].some(
                                            (item) =>
                                                item ===
                                                scope.row.adgroup
                                                    .secondary_status
                                        )
                                            ? '#26cd26'
                                            : 'red'
                                    }"
                                ></div>
                                <p class="font-medium">
                                    {{
                                        renderLabelBySecondaryStatus(
                                            scope.row.adgroup.secondary_status
                                        )
                                    }}
                                </p>
                            </div>
                            <el-tooltip
                                effect="dark"
                                :content="
                                    renderDescriptionBySecondaryStatus(
                                        scope.row.adgroup.secondary_status
                                    )
                                "
                                placement="top"
                            >
                                <p
                                    class="text-desc-text text-xs truncate w-[160px]"
                                >
                                    {{
                                        renderDescriptionBySecondaryStatus(
                                            scope.row.adgroup.secondary_status
                                        )
                                    }}
                                </p>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="adgroup.budget"
                    :label="$t('common.budget')"
                    width="160px"
                >
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p
                                v-if="
                                    scope.row.adgroup.budget_mode ===
                                    'BUDGET_MODE_INFINITE'
                                "
                                class="font-semibold"
                            >
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.adgroup.budget,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p
                                v-else
                                class="font-semibold cursor-pointer"
                                @click="
                                    handleSelectRowToEditBudget(
                                        scope.row.adgroup
                                    )
                                "
                            >
                                {{
                                    p__renderMoneyByCurrency(
                                        scope.row.adgroup.budget,
                                        scope.row.currency
                                    )
                                }}

                                <i
                                    class="el-icon-edit text-xs font-semibold"
                                ></i>
                            </p>

                            <p class="component-text-desc">
                                {{
                                    p__formatBudgetMode(
                                        scope.row.adgroup.budget_mode
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    :label="$t('common.ad_scheduling')"
                    width="160px"
                >
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p>
                                {{
                                    p__formatDate(
                                        scope.row.adgroup.schedule_start_time
                                    )
                                }}
                            </p>
                            <p
                                v-if="
                                    scope.row.adgroup.schedule_type ===
                                    'SCHEDULE_START_END'
                                "
                            >
                                {{
                                    p__formatDate(
                                        scope.row.adgroup.schedule_end_time
                                    )
                                }}
                            </p>
                            <p v-else>
                                {{ $t('page.campaign.on_going') }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="BID" width="160px" align="right">
                    <template #default="scope">
                        <div v-if="!scope.row.is_child">
                            <p>
                                {{
                                    renderBid(
                                        scope.row.adgroup,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p class="text-desc-text">
                                {{
                                    p__formatLabelOptimizationGoal(
                                        scope.row.adgroup
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    v-for="(item, index) in m__selected_columns"
                    :key="index"
                    :min-width="160"
                    :width="m__getContentWidthColumn(item)"
                    :show-overflow-tooltip="true"
                    :prop="item"
                    :label="$t(`page.campaign.column_${item}`)"
                    sortable="custom"
                    align="right"
                >
                    <template v-slot:header>
                        <el-tooltip
                            class="item"
                            :content="$t(`page.campaign.column_${item}`)"
                            effect="dark"
                            placement="top"
                        >
                            <div slot="content">
                                <p
                                    class="text-sm"
                                    v-html="$t(`page.campaign.column_${item}`)"
                                ></p>

                                <p
                                    v-if="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        ) !==
                                        `page.campaign.description_column_${item}`
                                    "
                                    class="mt-1"
                                    v-html="
                                        $t(
                                            `page.campaign.description_column_${item}`
                                        )
                                    "
                                ></p>
                            </div>
                            <p>
                                {{ $t(`page.campaign.column_${item}`) }}
                            </p>
                        </el-tooltip>
                    </template>

                    <template #default="scope">
                        <div>
                            <p>
                                {{
                                    m__formatterColumn(
                                        scope.row[item],
                                        item,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p
                                v-if="scope.row._compare && switch_compare"
                                class="text-desc-text"
                            >
                                {{
                                    m__formatterColumn(
                                        scope.row._compare[item],
                                        item,
                                        scope.row.currency
                                    )
                                }}
                            </p>
                            <p
                                v-if="
                                    m__getContentWidthColumn(item) === '260px'
                                "
                                class="text-desc-text text-xs"
                            >
                                {{
                                    p__formatLabelOptimizationGoal(
                                        scope.row.adgroup
                                    )
                                }}
                            </p>
                        </div>
                    </template>
                </el-table-column>
            </el-table>

            <div
                v-if="!m__data_for_pagination.length"
                class="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2"
            >
                <div class="util-flex-center flex-col">
                    <img
                        class="w-[140px] h-[140px]"
                        :src="require('@/assets/images/no_data.png')"
                    />
                    <p class="text-desc-text mt-2 text-sm">
                        {{ $t('common.no_data') }}
                    </p>
                </div>
            </div>
        </div>

        <div class="flex mt-2 space-x-2">
            <div class="!ml-auto"></div>

            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <el-tooltip
                    effect="dark"
                    :content="$t('page.campaign.desc_auto_refresh')"
                    placement="top"
                >
                    <p
                        class="mx-1 text-xs"
                        @click="m__show_config_auto_refresh_dialog = true"
                    >
                        {{ $t('page.campaign.auto_refresh') }}
                    </p>
                </el-tooltip>

                <i
                    class="el-icon-edit text-xs font-semibold"
                    @click="m__show_config_auto_refresh_dialog = true"
                ></i>
            </div>

            <el-select
                v-model="m__page_info.page_size"
                filterable
                class="w-[120px]"
                size="small"
                @change="
                    m__handleChangePagesize(m__getReportCampaignsByAccount)
                "
            >
                <el-option
                    v-for="item in m__list_pagesize"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>

            <el-input-number
                v-model="m__page_info.page"
                class="w-[120px]"
                size="small"
                :min="1"
                :max="m__page_info.total_page"
                @change="m__getReportCampaignsByAccount"
            ></el-input-number>

            <el-button
                icon="el-icon-refresh"
                size="small"
                @click="m__getReportCampaignsByAccount"
            ></el-button>
        </div>

        <CreateAutomaticRuleDrawer
            :visible.sync="is_create_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
            :multiple_selection="multiple_selection"
            type="ADGROUP"
        ></CreateAutomaticRuleDrawer>

        <ManageAutomaticRuleDrawer
            :visible.sync="is_manage_automatic_rule_drawer_displayed"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :currency="currency"
        ></ManageAutomaticRuleDrawer>

        <CopyDrawer
            :visible.sync="is_copy_drawer_displayed"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :min_budget_adgroup="min_budget_adgroup"
            :currency="active_currency"
            :timezone="active_timezone"
            :objective_type="selected_copy_objective_type"
            :list_campaigns="list_campaigns"
            :current_balance="current_balance"
            :selected_ads_account="selected_ads_account"
        ></CopyDrawer>

        <ConfirmCopyDialog
            :visible.sync="is_confirm_copy_dialog_displayed"
            :handle_confirm_copy="handleConfirmCopy"
            :objective_type.sync="selected_copy_objective_type"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :active_item_for_copy="active_item_for_copy"
            :ads_accounts="ads_accounts"
            @update:selected-ads-account="updateAdsAccount"
        ></ConfirmCopyDialog>

        <CustomColumnDialog
            :column_values.sync="m__performance_column_values"
            :visible.sync="show_custom_column_dialog"
        ></CustomColumnDialog>

        <EditDrawer
            :visible.sync="is_edit_drawer_displayed"
            :selected_item="active_item_for_edit"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :belong_to_atosa="belong_to_atosa"
            :atosa_bc_list="atosa_bc_list"
            :min_budget_adgroup="min_budget_adgroup"
        ></EditDrawer>

        <ViewDataDrawer
            :visible.sync="is_view_data_drawer_displayed"
            :selected_item="active_item_for_view_data"
            :tiktok_account_id="tiktok_account_id"
            :advertiser_id="advertiser_id"
            :ranger_date="ranger_date"
            :belong_to_atosa="belong_to_atosa"
            type="adgroup_id"
        ></ViewDataDrawer>

        <el-dialog
            :visible.sync="visible_edit_name_dialog"
            width="400px"
            class="el-dialog-center"
            :show-close="false"
            :append-to-body="true"
            :close-on-click-modal="true"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ `${$t('common.edit_name')}` }}
                </p>
                <p
                    v-if="selected_row_to_edit_name"
                    class="text-desc-text w-4/5 text-xs truncate"
                >
                    #{{ selected_row_to_edit_name.campaign_id }}
                </p>
            </template>

            <div v-if="selected_row_to_edit_name">
                <el-input
                    v-model="selected_row_to_edit_name.adgroup_name"
                    size="small"
                    :placeholder="$t('input.placeholder.please_input')"
                ></el-input>
            </div>

            <template slot="footer">
                <div
                    v-if="selected_row_to_edit_name"
                    class="flex items-center justify-end"
                >
                    <el-button
                        size="small"
                        @click="visible_edit_name_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        :disabled="!selected_row_to_edit_name.adgroup_name"
                        @click="handleConfirmEditNameRow"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="visible_edit_budget_dialog"
            width="280px"
            class="el-dialog-center"
            :show-close="false"
            :append-to-body="true"
            :close-on-click-modal="true"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ `${$t('common.edit_budget')}` }}
                </p>
                <p
                    v-if="selected_row_to_edit_name"
                    class="text-desc-text w-4/5 text-xs truncate"
                >
                    #{{ selected_row_to_edit_name.campaign_id }}
                </p>
            </template>

            <div v-if="selected_row_to_edit_name">
                <CustomCommasNumber
                    :num.sync="selected_row_to_edit_name.budget"
                ></CustomCommasNumber>
            </div>

            <template slot="footer">
                <div
                    v-if="selected_row_to_edit_name"
                    class="flex items-center justify-end"
                >
                    <el-button
                        size="small"
                        @click="visible_edit_budget_dialog = false"
                    >
                        {{ $t('button.close') }}
                    </el-button>
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-check"
                        :loading="m__loading"
                        @click="handleConfirmEditBudget"
                    >
                        {{ $t('button.confirm') }}
                    </el-button>
                </div>
            </template>
        </el-dialog>

        <el-dialog
            :visible.sync="m__show_config_auto_refresh_dialog"
            width="260px"
            class="el-dialog-center"
            :show-close="true"
            :append-to-body="true"
            :close-on-click-modal="false"
        >
            <template slot="title">
                <p class="w-4/5 text-base font-semibold text-left truncate">
                    {{ $t('common.config_auto_refresh') }}
                </p>
            </template>

            <div class="flex items-center cursor-pointer">
                <el-switch
                    v-model="m__auto_refresh_config.auto_refresh"
                    @change="m__handleToggleAutoRefresh"
                ></el-switch>
                <p
                    class="mx-1 text-xs"
                    @click="m__show_config_auto_refresh_dialog = true"
                >
                    {{ $t('page.campaign.auto_refresh') }}
                </p>
            </div>

            <div class="flex flex-col mt-2">
                <p class="mb-1">{{ $t('common.refresh_second') }}</p>
                <div>
                    <el-input-number
                        v-model="m__auto_refresh_config.seconds"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="60"
                        @change="m__handleChangeSecondAutoRefresh"
                    ></el-input-number>
                    <span>
                        {{ $t('common.second') }}
                    </span>
                </div>

                <p class="mt-2 mb-1">{{ $t('common.config') }}</p>
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="true"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.never_stop') }}</el-radio
                >
                <el-radio
                    v-model="m__auto_refresh_config.never_stop"
                    :disabled="!m__auto_refresh_config.auto_refresh"
                    :label="false"
                    @change="m__handleChangeNeverstop"
                    >{{ $t('common.stop_after') }}</el-radio
                >
                <div v-if="!m__auto_refresh_config.never_stop">
                    <el-input-number
                        v-model="m__auto_refresh_config.stop_after_minutes"
                        :disabled="!m__auto_refresh_config.auto_refresh"
                        controls-position="right"
                        size="mini"
                        :min="1"
                        @change="m__handleChangeNeverstop"
                    ></el-input-number>
                    <span>{{ $t('common.minute') }}</span>
                </div>
            </div>
        </el-dialog>

        <ShowEditMultiNameDrawer
            :visible.sync="show_edit_multi_name_dialog"
            :multiple_selection="multiple_selection_inline"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></ShowEditMultiNameDrawer>

        <ShowEditMultiBudgetDrawer
            :visible.sync="show_edit_multi_budget_dialog"
            :multiple_selection="multiple_selection_inline"
            :atosa_bc_list="atosa_bc_list"
            :advertiser_id="advertiser_id"
            :tiktok_account_id="tiktok_account_id"
            :belong_to_atosa="belong_to_atosa"
        ></ShowEditMultiBudgetDrawer>
    </section>
</template>

<script>
import campaignMixin from '../_mixin'
import CopyDrawer from './copy-drawer'
import EditDrawer from './edit-drawer'
import ConfirmCopyDialog from './confirm-copy-dialog'
import {
    updateAdgroupStatus,
    updateAdgroup
} from '@/services/atosa-tiktok-ads/adgroup'
import CustomColumnDialog from '@/views/campaign/campaign-wrapper/_components/custom-column-dialog'
import ViewDataDrawer from '@/views/campaign/campaign-wrapper/_components/view-data-drawer'
import CreateAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-create-automatic-rule-drawer'
import ManageAutomaticRuleDrawer from '@/views/campaign/campaign-wrapper/_components/atosa-manage-automatic-rule-drawer'
import ExcelSvg from '@/assets/svgs/excel.svg'
import ShowEditMultiBudgetDrawer from './show-edit-multi-budget-drawer'
import ShowEditMultiNameDrawer from './show-edit-multi-name-drawer'

export default {
    components: {
        ExcelSvg,
        ViewDataDrawer,
        EditDrawer,
        CopyDrawer,
        CreateAutomaticRuleDrawer,
        ManageAutomaticRuleDrawer,
        ConfirmCopyDialog,
        CustomColumnDialog,
        ShowEditMultiBudgetDrawer,
        ShowEditMultiNameDrawer
    },

    mixins: [campaignMixin],

    props: [
        'multiple_selection_campaign',
        'min_budget_adgroup',
        'atosa_bc_list',
        'ads_accounts'
    ],

    data() {
        return {
            filter_campaign_ids: [],
            show_edit_multi_name_dialog: false,
            show_edit_multi_budget_dialog: false,
            visible_edit_budget_dialog: false,
            is_manage_automatic_rule_drawer_displayed: false,
            is_create_automatic_rule_drawer_displayed: false,
            active_item_for_view_data: null,
            is_view_data_drawer_displayed: false,
            visible_edit_name_dialog: false,
            selected_row_to_edit_name: null,
            selected_copy_objective_type: '',
            show_custom_column_dialog: false,
            active_currency: null,
            active_timezone: null,
            active_item_for_copy: null,
            active_item_for_edit: null,
            list_copy: [],
            list_campaigns: [],
            is_confirm_copy_dialog_displayed: false,
            is_edit_drawer_displayed: false,
            is_copy_drawer_displayed: false,
            selected_ads_account: null
        }
    },

    computed: {
        multiple_selection_inline: {
            get() {
                return this.multiple_selection
            },
            set(value) {
                this.$emit('update:multiple_selection', value)
            }
        }
    },

    watch: {
        multiple_selection() {
            if (!this.multiple_selection_inline.length) {
                this.$refs.multipleTable.clearSelection([])
            }
        },

        multiple_selection_campaign() {
            const indexFilterById = this.m__filtering.findIndex(
                (item) => item.field_name === 'campaign_ids'
            )

            if (indexFilterById > -1) {
                this.m__filtering.splice(indexFilterById, 1)
            }

            if (this.multiple_selection_campaign.length) {
                const filter = {
                    filter_value: JSON.stringify(
                        this.multiple_selection_campaign.map((item) => item.id)
                    ),
                    field_name: 'campaign_ids',
                    filter_type: 'IN'
                }
                this.m__filtering.push(filter)
            }
            this.m__page_info.page = 1

            this.m__getReportCampaignsByAccount()
        }
    },

    mounted() {
        this.m__type = 'adgroup_id'
        this.m__getReportCampaignsByAccount()

        this.p__event_bus.$on(
            'eb_reload_adgroup',
            this.m__getReportCampaignsByAccount
        )

        this.p__event_bus.$on(
            'eb_get_filter_campaign_ids',
            this.getFilterCampaignIds
        )

        const temp = localStorage.getItem(
            `performance_column_values_${this.m__type}`
        )
        if (temp && temp.length) {
            this.m__performance_column_values = JSON.parse(temp)
        } else {
            this.m__performance_column_values = [
                {
                    value: 'BASIC_DATA',
                    child_values: this.p__basic_metrics
                }
            ]
        }
    },

    destroyed() {
        this.p__event_bus.$off(
            'eb_reload_adgroup',
            this.m__getReportCampaignsByAccount
        )

        this.p__event_bus.$off(
            'eb_get_filter_campaign_ids',
            this.getFilterCampaignIds
        )
    },

    methods: {
        updateAdsAccount(newValue) {
            this.selected_ads_account = newValue
        },
        getFilterCampaignIds(id_list) {
            this.filter_campaign_ids = id_list

            this.m__getReportCampaignsByAccount()
        },

        renderBid(adgroup, currency) {
            if (
                adgroup.optimization_event === 'SHOPPING' &&
                adgroup.optimization_goal === 'VALUE' &&
                adgroup?.deep_bid_type === 'VO_MIN_ROAS'
            ) {
                return adgroup.roas_bid
            }

            if (adgroup.optimization_goal === 'CLICK') {
                return this.p__renderMoneyByCurrency(
                    adgroup.bid_price,
                    currency
                )
            }

            return '-'
        },

        async handleConfirmEditBudget() {
            this.m__loading = true

            try {
                await updateAdgroup(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    budget: this.selected_row_to_edit_name.budget,
                    adgroup_id: this.selected_row_to_edit_name.adgroup_id,
                    store_authorized_bc_id:
                        this.selected_row_to_edit_name.store_authorized_bc_id,

                    belong_to_atosa: this.belong_to_atosa
                })

                this.m__getReportCampaignsByAccount()

                this.visible_edit_budget_dialog = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        handleRedirectToCreateCampaign() {
            if (this.multiple_selection_campaign.length === 0) {
                this.$router.push({
                    path: `${this.p__group_route.ads_manager}/${this.p__route.create_campaign}?advertiser_id=${this.advertiser_id}`
                })
                return
            }

            this.$router.push({
                path: `${this.p__group_route.ads_manager}/${
                    this.p__route.create_campaign
                }?campaign_id=${this.multiple_selection_campaign
                    .map((item) => item.id)
                    .join(',')}&advertiser_id=${this.advertiser_id}`
            })
        },

        renderLabelBySecondaryStatus(status) {
            if (!status) {
                return this.$t('common.not_delivering')
            }

            if (
                [
                    'ADGROUP_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
                    'ADGROUP_STATUS_SHADOW_ADGROUP_REAUDIT',
                    'ADGROUP_STATUS_DELIVERY_OK'
                ].some((item) => item === status)
            ) {
                return this.$t('common.active')
            }

            if (
                [
                    'ADGROUP_STATUS_CAMPAIGN_DISABLE',
                    'ADGROUP_STATUS_DISABLE'
                ].some((item) => item === status)
            ) {
                return this.$t('common.inactive')
            }

            if (
                [
                    'ADGROUP_STATUS_CAMPAIGN_DELETE',
                    'ADGROUP_STATUS_DELETE'
                ].some((item) => item === status)
            ) {
                return this.$t('common.deleted')
            }

            if (status === 'ADGROUP_STATUS_TIME_DONE') {
                return this.$t('common.completed')
            }

            if (
                [
                    'ADGROUP_STATUS_ADVERTISER_AUDIT_DENY',
                    'ADGROUP_STATUS_ADVERTISER_AUDIT',
                    'ADVERTISER_CONTRACT_PENDING',
                    'ADVERTISER_ACCOUNT_PUNISH',
                    'ADGROUP_STATUS_BALANCE_EXCEED',
                    'ADGROUP_STATUS_CREATE',
                    'ADGROUP_STATUS_TRANSCODING_FAIL',
                    'ADGROUP_STATUS_AUDIT',
                    'ADGROUP_STATUS_REAUDIT',
                    'ADGROUP_STATUS_AUDIT_DENY',
                    'ADGROUP_STATUS_PARTIAL_AUDIT_NO_DELIVERY',
                    'ADGROUP_STATUS_LIVE_NOT_START',
                    'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_MISSING',
                    'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_EXPIRED',
                    'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_DENY',
                    'ADGROUP_STATUS_CAMPAIGN_EXCEED',
                    'ADGROUP_STATUS_BUDGET_EXCEED',
                    'ADGROUP_STATUS_FROZEN',
                    'ADGROUP_STATUS_NOT_START',
                    'ADGROUP_STATUS_ASSET_AUTHORIZATION_LOST',
                    'ADGROUP_STATUS_PIXEL_UNBIND'
                ].some((item) => item === status)
            ) {
                return this.$t('common.not_delivering')
            }

            return status
        },

        renderDescriptionBySecondaryStatus(status) {
            if (status === 'ADGROUP_STATUS_CAMPAIGN_DELETE') {
                return this.$t('page.campaign.adgroup_status_campaign_deleted')
            }
            if (status === 'ADGROUP_STATUS_DELETE') {
                return this.$t('page.campaign.adgroup_status_adgroup_deleted')
            }
            if (status === 'ADGROUP_STATUS_CAMPAIGN_DISABLE') {
                return this.$t('page.campaign.adgroup_status_campaign_inactive')
            }
            if (status === 'ADGROUP_STATUS_DISABLE') {
                return this.$t('page.campaign.adgroup_status_adgroup_inactive')
            }
            if (status === 'ADGROUP_STATUS_ADVERTISER_AUDIT_DENY') {
                return this.$t(
                    'page.campaign.campaign_status_account_not_approved'
                )
            }
            if (status === 'ADGROUP_STATUS_ADVERTISER_AUDIT') {
                return this.$t(
                    'page.campaign.campaign_status_account_in_review'
                )
            }
            if (status === 'ADVERTISER_CONTRACT_PENDING') {
                return this.$t(
                    'page.campaign.adgroup_status_contract_has_not_taken_effect'
                )
            }
            if (status === 'ADVERTISER_ACCOUNT_PUNISH') {
                return this.$t('page.campaign.adgroup_status_account_penalized')
            }
            if (status === 'ADGROUP_STATUS_BALANCE_EXCEED') {
                return this.$t(
                    'page.campaign.adgroup_status_payment_unsuccessful_or_insufficient_balance'
                )
            }
            if (status === 'ADGROUP_STATUS_CREATE') {
                return this.$t('page.campaign.adgroup_status_no_creatives')
            }
            if (status === 'ADGROUP_STATUS_TRANSCODING_FAIL') {
                return this.$t(
                    'page.campaign.adgroup_status_unable_to_convert_video_upload_again'
                )
            }
            if (status === 'ADGROUP_STATUS_AUDIT') {
                return this.$t('page.campaign.adgroup_status_in_review')
            }
            if (status === 'ADGROUP_STATUS_REAUDIT') {
                return this.$t('page.campaign.adgroup_status_edited_for_review')
            }
            if (status === 'ADGROUP_STATUS_AUDIT_DENY') {
                return this.$t('page.campaign.adgroup_status_not_approved')
            }
            if (status === 'ADGROUP_STATUS_PARTIAL_AUDIT_NO_DELIVERY') {
                return this.$t('page.campaign.adgroup_status_in_review')
            }
            if (status === 'ADGROUP_STATUS_LIVE_NOT_START') {
                return this.$t('page.campaign.adgroup_status_LIVE_not_started')
            }
            if (status === 'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_MISSING') {
                return this.$t(
                    'page.campaign.adgroup_status_qualification_needed'
                )
            }
            if (status === 'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_EXPIRED') {
                return this.$t('page.campaign.adgroup_status_expired')
            }
            if (status === 'ADGROUP_STATUS_INDUSTRY_QUALIFICATION_DENY') {
                return this.$t('page.campaign.adgroup_status_disapproved')
            }
            if (status === 'ADGROUP_STATUS_CAMPAIGN_EXCEED') {
                return this.$t(
                    'page.campaign.campaign_status_campaign_out_of_budget'
                )
            }
            if (status === 'ADGROUP_STATUS_BUDGET_EXCEED') {
                return this.$t(
                    'page.campaign.adgroup_status_ad_group_out_of_budget'
                )
            }
            if (status === 'ADGROUP_STATUS_NOT_START') {
                return this.$t(
                    'page.campaign.adgroup_status_outside_of_schedule'
                )
            }
            if (status === 'ADGROUP_STATUS_ASSET_AUTHORIZATION_LOST') {
                return this.$t(
                    'page.campaign.adgroup_status_asset_unavailable_or_authorization_revoked'
                )
            }
            if (status === 'ADGROUP_STATUS_PIXEL_UNBIND') {
                return this.$t(
                    'page.campaign.adgroup_status_pixel_unauthorized'
                )
            }
            if (status === 'ADGROUP_STATUS_SHADOW_ADGROUP_REAUDIT') {
                return this.$t('page.campaign.adgroup_status_edited_for_review')
            }
            if (status === 'ADGROUP_STATUS_TIME_DONE') {
                return ''
            }
            if (status === 'ADGROUP_STATUS_DELIVERY_OK') {
                return ''
            }

            return status
        },

        handleOpenViewDataDrawer(row) {
            this.active_item_for_view_data = { ...row }
            this.is_view_data_drawer_displayed = true
        },

        handleSelectRowToEditName(row) {
            this.visible_edit_name_dialog = true
            this.selected_row_to_edit_name = { ...row }
        },

        handleSelectRowToEditBudget(row) {
            this.visible_edit_budget_dialog = true
            this.selected_row_to_edit_name = { ...row }
        },

        async handleConfirmEditNameRow() {
            try {
                await updateAdgroup(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    adgroup_name: this.selected_row_to_edit_name.adgroup_name,
                    adgroup_id: this.selected_row_to_edit_name.adgroup_id,
                    store_authorized_bc_id:
                        this.selected_row_to_edit_name.store_authorized_bc_id,

                    belong_to_atosa: this.belong_to_atosa
                })

                this.m__getReportCampaignsByAccount()
                this.visible_edit_name_dialog = false

                this.p__showNotify(
                    'success',
                    this.$t('common.update_data_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        handleSelectionChange(val) {
            this.multiple_selection_inline = val
        },

        async handleChangeStatusAdgroup(adgroup_id) {
            const adgroup = this.m__data_for_pagination.find(
                (item) => item.adgroup.adgroup_id === adgroup_id
            )

            if (!adgroup) {
                return
            }

            adgroup.adgroup.operation_status =
                adgroup.adgroup.operation_status === 'ENABLE'
                    ? 'DISABLE'
                    : 'ENABLE'
            try {
                await updateAdgroupStatus(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    adgroup_ids: [adgroup_id],
                    belong_to_atosa: this.belong_to_atosa,
                    operation_status: adgroup.adgroup.operation_status
                })

                setTimeout(() => {
                    this.m__getReportCampaignsByAccount()
                }, 1500)

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_status_xxx_success', {
                        xxx: this.$t(`common.adgroup`)
                    })
                )
            } catch (error) {
                console.error(error)

                adgroup.adgroup.operation_status =
                    adgroup.adgroup.operation_status === 'ENABLE'
                        ? 'DISABLE'
                        : 'ENABLE'

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_update_status_xxx_failed', {
                        xxx: this.$t(`common.adgroup`)
                    })
                )
            }
        },

        async handleDeleteAdgroup(adgroup_id) {
            try {
                await this.$confirm(
                    this.$t('common.delete_data_confirm'),
                    this.$t('common.warning'),
                    {
                        confirmButtonText: this.$t('button.confirm'),
                        cancelButtonText: this.$t('button.close'),
                        type: 'warning'
                    }
                )
            } catch (e) {
                return
            }

            const adgroup = this.m__data_for_pagination.find(
                (item) => item.adgroup.adgroup_id === adgroup_id
            )

            if (!adgroup) {
                return
            }

            try {
                await updateAdgroupStatus(this.tiktok_account_id, {
                    advertiser_id: this.advertiser_id,
                    adgroup_ids: [adgroup_id],
                    operation_status: 'DELETE',
                    type: 'button',
                    belong_to_atosa: this.belong_to_atosa
                })
                adgroup.adgroup.secondary_status = 'ADGROUP_STATUS_DELETE'

                this.p__event_bus.$emit('eb_reload_adgroup')
                this.p__event_bus.$emit('eb_reload_ad')

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_delete_xxx_success', {
                        xxx: this.$t(`common.adgroup`)
                    })
                )
            } catch (error) {
                console.error(error)

                adgroup.adgroup.secondary_status = 'ADGROUP_STATUS_DISABLE'

                this.p__showNotify(
                    'error',
                    this.$t('page.campaign.message_delete_xxx_failed', {
                        xxx: this.$t(`common.adgroup`)
                    })
                )
            }
        },

        handleOpenEditDrawer(adgroup) {
            this.active_item_for_edit = {
                ...adgroup,
                adgroup: {
                    ...adgroup.adgroup,
                    schedule_start_time: this.p__formatDate(
                        adgroup.adgroup.schedule_start_time
                    ),
                    schedule_end_time: this.p__formatDate(
                        adgroup.adgroup.schedule_end_time
                    )
                }
            }

            this.is_edit_drawer_displayed = true
        },

        handleSelectRow(row) {
            this.handle_select_row(row.adgroup.adgroup_id)

            this.multiple_selection_inline = [row]

            this.toggleSelection([row])
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                    this.$refs.multipleTable.toggleRowSelection(row)
                })
            } else {
                this.$refs.multipleTable.clearSelection()
            }
        },

        handleOpenConfirmCopy(data) {
            this.active_item_for_copy = data.adgroup
            this.active_currency = data.currency
            this.active_timezone = data.timezone

            this.is_confirm_copy_dialog_displayed = true
        },

        handleConfirmCopy(list_campaigns) {
            this.list_campaigns = list_campaigns

            this.is_copy_drawer_displayed = true
        }
    }
}
</script>
